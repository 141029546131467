import React, { useContext, useEffect, useState } from 'react';
import TabsListing from './LeftSection/TabsListing';
import Dashboard from './RightSection.js/Dashboard';
import styled from 'styled-components';
import { ChangeMembershipContext, ViewContext } from '../App';

const RootWrapper = styled.div`
  display: flex;
`;

const Root = () => {
  const { isView } = useContext(ViewContext);

  const [selectedTab, setSelectedTab] = useState(
    !isView ? 'listing' : 'Profile'
  );

  useEffect(() => {
    setSelectedTab(!isView ? 'listing' : 'Profile');
  }, [isView]);

  return (
    <RootWrapper>
      <TabsListing onTabChange={setSelectedTab} />
      <Dashboard selectedTab={selectedTab}  setSelectedTab={setSelectedTab}/>
    </RootWrapper>
  );
};

export default Root;
