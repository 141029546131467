import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Card from '../listings/Card';
import axios from 'axios';
import { API_HOST } from '../../Api/url';
import { Box, CircularProgress, Alert, Snackbar, Pagination } from '@mui/material';
import { BoxStyles } from '../listings/Listings';
import { CategoryContext, SearchQueryContext, TypeContext } from '../../../App';
import LinearProgress from '@mui/material/LinearProgress';
import CancelIcon from '@mui/icons-material/Cancel';
import ShowSavePlanModel from './showSavePlanModel';
export const Wrapper = styled.div`
  margin: 20px 0px 20px 0px;
`;


const PendingList = ({ isSearchClicked }) => {
  const [listings, setListings] = useState([]);
  const [isFetched, setIsFetched] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const { isVendor } = useContext(TypeContext);
  const { searchQuery } = useContext(SearchQueryContext);
  const { category } = useContext(CategoryContext);
  const [totalPage, setTotalPage] = useState(1);
  const [tableLoader, setTableLoader] = useState(false)
  const [showSaveAndPlanDecide, setShowSaveAndDecidePlanModel] = useState(false)
  const [venueVendorSlug,setVenueVendorSlug]=useState("")
  const [approvedDone,setApprovedDone]=useState(false)
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const fetchListings = async (searchPage = 1) => {
    try {
      let response;
      if (isVendor) {
        if (isSearchClicked) {
          response = await axios.post(
            `${API_HOST}/api/v1/admin/listings/vendors?searchQuery=${searchQuery}&searchPage=${searchPage}`,
            { vendorListingStatus: 'PENDING', vendorCategory: category.value }
          );
        } else {
          response = await axios.post(
            `${API_HOST}/api/v1/admin/listings/vendors?searchPage=${searchPage}`,
            { vendorListingStatus: 'PENDING', vendorCategory: category.value }
          );
        }
      } else {
        if (isSearchClicked) {
          console.log(searchQuery, "FROM PENDING")
          response = await axios.post(
            `${API_HOST}/api/v1/admin/listings/venues?searchQuery=${searchQuery}&searchPage=${searchPage}`,
            { venueListingStatus: 'PENDING' }
          );
          console.log(response.data.totalRecords, "total records")
        } else {
          response = await axios.post(
            `${API_HOST}/api/v1/admin/listings/venues?searchPage=${searchPage}`,
            { venueListingStatus: 'PENDING' }
          );
        }
      }
      setTotalPage(response.data.totalRecords)
      setListings(response.data.searchResults);

    } catch (error) {
      console.log(error);
    } finally {
      setIsFetched(true);
      setTableLoader(false)
    }
  };

  const handleReject = async (slug, rejectedMessage) => {
    if (isVendor) {
      try {
        setIsRejecting(true);
        await axios.post(
          `${API_HOST}/api/v1/admin/listings/vendors/${slug}/listing-status`,
          {
            vendorStatus: 'REJECTED',
            statusComment: rejectedMessage,
          }
        );
        setIsRejecting(false);
        fetchListings();
        setSnackbarMessage('Vendor rejected successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage('An error occurred while rejecting vendor');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } else {
      try {
        setIsRejecting(true);
        await axios.post(
          `${API_HOST}/api/v1/admin/listings/venues/${slug}/listing-status`,
          {
            venueStatus: 'REJECTED',
            statusComment: rejectedMessage,
          }
        );
        setIsRejecting(false);
        fetchListings();
        setSnackbarMessage('Venue rejected successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage('An error occurred while rejecting venue');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };

  const handleApprove = async (slug, premiumStartingDate) => {
    if (!premiumStartingDate) {
      setVenueVendorSlug(slug)
      setShowSaveAndDecidePlanModel(true)
    } else {
      if (isVendor) {
        try {
          setIsApproving(true);
          await axios.post(
            `${API_HOST}/api/v1/admin/listings/vendors/${slug}/listing-status`,
            {
              vendorStatus: 'APPROVED',
              statusComment: 'All details approved by administrator',
            }
          );
          setIsApproving(false);
          fetchListings();
          setSnackbarMessage('Vendor approved successfully');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        } catch (error) {
          setSnackbarMessage('An error occurred while approving vendor');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      } else {
        try {
          setIsApproving(true);
          await axios.post(
            `${API_HOST}/api/v1/admin/listings/venues/${slug}/listing-status`,
            {
              venueStatus: 'APPROVED',
              statusComment: 'All details approved by administrator',
            }
          );
          setIsApproving(false);
          fetchListings();
          setSnackbarMessage('Venue approved successfully');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        } catch (error) {
          setSnackbarMessage('An error occurred while approving venue');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      }
    }
  };

  useEffect(() => {
    setIsFetched(false);
    fetchListings();
  }, [isVendor, isSearchClicked, category,approvedDone]);

  return (
    <Wrapper >
      {
        showSaveAndPlanDecide && <ShowSavePlanModel showSaveAndPlanDecide={showSaveAndPlanDecide} setShowSaveAndDecidePlanModel={setShowSaveAndDecidePlanModel} isVendor={isVendor} handleApprove={handleApprove} venueVendorSlug={venueVendorSlug} setVenueVendorSlug={setVenueVendorSlug} approvedDone={approvedDone} setApprovedDone={setApprovedDone}/>
      }
      {!isFetched || isApproving ? (
        <Box sx={BoxStyles}>
          <CircularProgress />
        </Box>
      ) : listings.length === 0 ? (
        <p>No Pending {isVendor ? 'Vendors' : 'Venues'}</p>
      ) : (
        <div>
          <section style={{ marginBottom: "15px", display: "flex", flexDirction: "row-reverse", justifyContent: "space-between", alignItems: "center" }}>
            <Pagination siblingCount={1} onChange={(_, pageNumber) => {
              setTableLoader(true)
              fetchListings(pageNumber)

            }} variant="outlined" shape="rounded" count={Math.ceil(totalPage / 10)} color="primary" />
            <p>Total: {totalPage}</p>

          </section>
          <Box sx={{ height: "5px", borderRadius: "20px", width: '100%' }}>
            {
              tableLoader ?
                <LinearProgress />
                : null
            }
          </Box>


          {
            listings.map((item, index) => {
              return (
                <Card
                  key={`${item}-${index}`}
                  data={item}
                  isPendingPage={true}
                  fetchListings={fetchListings}
                  handleReject={handleReject}
                  handleApprove={handleApprove}
                />
              );
            })
          }

        </div>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

export default PendingList;
