import {
  selectMetadata,
  zipMetadata,
} from '../../../../utility/schemaMetadata';
import { Grid, TextField, Typography } from '@mui/material';
import { useValueSelector } from './hook';

export function TextInputQuestion(props) {
  const {
    questionText,
    vendorMetadataType,
    vendorMetadataDependencies,
    vendorMetadataDefaultValue,
    vendorMetadataKey,
  } = props;

  const [vendorMetadata, setVendorMetadata] = useValueSelector({
    defaultValue: () => {
      return [];
    },
    vendorValue: (vendorData) => {
      return vendorData.vendorMetadata;
    },
    venueValue: () => {
      return [];
    },
    updateVendorValue: (prevValue, partialValue) => {
      return {
        ...prevValue,
        vendorMetadata: zipMetadata(prevValue.vendorMetadata, partialValue),
      };
    },
    updateVenueValue: (prevValue, partialValue) => {
      return prevValue;
    },
  });

  const currentMetadataValue = selectMetadata(
    vendorMetadata,
    vendorMetadataKey,
    vendorMetadataDefaultValue
  );

  return (
    <>
      <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography>
          <b>{questionText}</b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          sx={{ fontFamily: 'Poppins' }}
          fullWidth
          placeholder={currentMetadataValue}
          defaultValue={currentMetadataValue}
          onChange={(e) => {
            setVendorMetadata([
              // @ts-ignore
              ...vendorMetadata,
              {
                propertyName: vendorMetadataKey,
                propertyType: 'STRING',
                // @ts-ignore
                propertyValue: e.target.value,
              },
            ]);
          }}
        ></TextField>
      </Grid>
    </>
  );
}
