import React, { useEffect, useState } from 'react';
import ReviewCard from './ReviewCard';
import { Alert, Grid, Snackbar, Box } from '@mui/material';
import { styled } from 'styled-components';
import axios from 'axios';
import { API_HOST } from '../../Api/url';
import { Button } from '@mui/material';
import { makeAPIRequest, manageMedia } from '../../../utility/mediaHandler';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
const Wrapper = styled.div`
  margin: 20px 0px 20px 0px;
`;

const StyledNo = styled.p`
  margin: 12px 0px;
  font-size: 18px;
  font-weight: 500;
  font-family: Poppins;
`;

const Reviews = () => {

  const [reviewsData, setreviewsData] = useState([]);
  const [backDropLoader, setBackDropLoader] = useState(true)
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_HOST}/api/v1/meta/admin/platform-reviews`
      );
      setreviewsData(response.data.platformReviews);
    } catch (error) {
      console.log(error);
    } finally {
      setBackDropLoader(false)
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClick = async (
    selectedFile,
    id,
    reviewTitle,
    reviewDescription,
    authorName,
    mainId
  ) => {
    if (mainId) {
      setBackDropLoader(true)
      if(selectedFile){
      await manageMedia({
        mediaFiles: [selectedFile],
        fileMethod: 'PUT',
        objectKeyGenFn: (fileHandle, fileIdx, fileMethod) => {
          return `media/platform-reviews/${mainId}`;
        },
      });
    }
      const result = await makeAPIRequest({
        requestEndpoint: '/meta/platform-reviews',
        requestMethod: 'POST',
        bodyParams: {
          reviewTitle: reviewTitle,
          reviewDescription: reviewDescription,
          reviewAuthorDisplayName: authorName,
          reviewAuthorPhotoObjectKey: `media/platform-reviews/${mainId}`,
          reviewIndex: id,
          _id:mainId
        },
      });
      if (result.isSuccess) {
        fetchData();
        setSnackbarMessage('Top ratings updated successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } else {
        fetchData();
        setSnackbarMessage('Unable To Updated, Please Try Later');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      setBackDropLoader(false)
    }
  };

  const deleteReview = async (
    id
  ) => {
    try {
      setBackDropLoader(true)
      await axios.post(
        `${API_HOST}/api/v1/meta/delete-review/platform`, {
        reviewIndex: id,
      },
      );

      fetchData();
      setSnackbarMessage('Review Deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Unable To Deleted, Please Try Later');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }

    setBackDropLoader(false)
  };

  const updateMainReviewAndStatusReview = async (
    id,
    object
  ) => {
    try {
      setBackDropLoader(true)
      await axios.post(
        `${API_HOST}/api/v1/meta/update-mainreview-status-review/platform-review`, {
        id,
        object
      },
      );

      fetchData();
      setSnackbarMessage('Review updated successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Unable To Update, Please Try Later');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }

    setBackDropLoader(false)
  };

  const addReviewData = async () => {
    try {
      setBackDropLoader(true)
      await axios.post(
        `${API_HOST}/api/v1/meta/add-review/platform-reviews`
      );
      await fetchData();
    } catch (error) {
      console.log(error);
    } finally {
      setBackDropLoader(false)
    }
  };

  return (
    <Wrapper>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backDropLoader}
      >
        <CircularProgress />
      </Backdrop>
      <section style={{ display: "flex", justifyContent: "end", alignItems: "center", marginBottom: "5px" }}>
        <Button
          onClick={() => addReviewData()}
          variant='contained'>Add Review</Button>
      </section>
      <Grid container spacing={2}>
        {reviewsData.map((data, index) => {
          const item = reviewsData[index];
          return (
            <Grid item xs={6} key={data._id?data._id:index}>
              <StyledNo>Review {index + 1}</StyledNo>
              <ReviewCard
                data={data}
                onUploadSuccess={fetchData}
                id={data.reviewIndex}
                handleClick={handleClick}
                deleteReview={deleteReview}
                updateMainReviewAndStatusReview={updateMainReviewAndStatusReview}
              />
            </Grid>
          );
        })}
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

export default Reviews;
