import {
  selectMetadata,
  zipMetadata,
} from '../../../../utility/schemaMetadata';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { useValueSelector } from './hook';

export function BitFieldQuestion(props) {
  const {
    questionText,
    vendorMetadataType,
    vendorMetadataDependencies,
    vendorMetadataDefaultValue,
    vendorMetadataKey,
    questionOptions,
  } = props;

  const [vendorMetadata, setVendorMetadata] = useValueSelector({
    defaultValue: () => {
      return [];
    },
    vendorValue: (vendorData) => {
      return vendorData.vendorMetadata;
    },
    venueValue: () => {
      return [];
    },
    updateVendorValue: (prevValue, partialValue) => {
      return {
        ...prevValue,
        vendorMetadata: zipMetadata(prevValue.vendorMetadata, partialValue),
      };
    },
    updateVenueValue: (prevValue, partialValue) => {
      return prevValue;
    },
  });

  const currentMetadataValue = selectMetadata(
    vendorMetadata,
    vendorMetadataKey,
    vendorMetadataDefaultValue
  );

  const isBitSet = (bitVal) => {
    return (currentMetadataValue & bitVal) > 0;
  };

  return (
    <>
      <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography>
          <b>{questionText}</b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container spacing={1}>
          {questionOptions.map((questionData, questionIdx) => {
            const { optionText, optionValue } = questionData;
            return (
              <Grid item xs={12} sm={6} md={4} key={optionValue.toString()}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isBitSet(optionValue)}
                      onChange={(e) => {
                        setVendorMetadata([
                          ...vendorMetadata,
                          {
                            propertyName: vendorMetadataKey,
                            propertyType: 'NUMBER',
                            propertyValue: currentMetadataValue ^ optionValue,
                          },
                        ]);
                      }}
                    />
                  }
                  label={optionText}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
}
