import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Card from '../listings/Card';
import { CategoryContext, SearchQueryContext, TypeContext } from '../../../App';
import axios from 'axios';
import { API_HOST } from '../../Api/url';
import { BoxStyles } from '../listings/Listings';
import { Alert, Box,Pagination , CircularProgress, Snackbar } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
const Wrapper = styled.div`
  margin: 20px 0px 20px 0px;
`;

const ApprovedList = ({ isSearchClicked }) => {
  const [listings, setListings] = useState([]);
  const [isFetched, setIsFetched] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isUnlisting, setisUnlisting] = useState(false);
  const { isVendor } = useContext(TypeContext);
  const { searchQuery } = useContext(SearchQueryContext);
  const { category } = useContext(CategoryContext);
  const [totalPage, setTotalPage] = useState(1);
  const [tableLoader, setTableLoader] = useState(false)

  const fetchListings = async (searchPage = 1) => {
    try {
      let response;
      if (isVendor) {
        if (isSearchClicked) {
          response = await axios.post(
            `${API_HOST}/api/v1/admin/listings/vendors?searchQuery=${searchQuery}&searchPage=${searchPage}`,
            { vendorListingStatus: 'APPROVED', vendorCategory: category.value }
          );
        } else {
          response = await axios.post(
            `${API_HOST}/api/v1/admin/listings/vendors?searchPage=${searchPage}`,
            { vendorListingStatus: 'APPROVED', vendorCategory: category.value }
          );
        }
      } else {
        if (isSearchClicked) {
          response = await axios.post(
            `${API_HOST}/api/v1/admin/listings/venues?searchQuery=${searchQuery}&searchPage=${searchPage}`,
            { venueListingStatus: 'APPROVED' }
          );
        } else {
          response = await axios.post(
            `${API_HOST}/api/v1/admin/listings/venues?searchPage=${searchPage}`,
            { venueListingStatus: 'APPROVED' }
          );
        }
      }
      setTotalPage(response.data.totalRecords)
      setListings(response.data.searchResults);
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetched(true);
      setTableLoader(false)
    }
  };

  const handleUnlist = async (slug) => {
    if (isVendor) {
      try {
        setisUnlisting(true);
        await axios.post(
          `${API_HOST}/api/v1/admin/listings/vendors/${slug}/listing-status`,
          {
            vendorStatus: 'UNLISTED',
            statusComment: 'Unlisting vendor',
          }
        );
        setisUnlisting(false);
        fetchListings();
        setSnackbarMessage('Vendor unlisted successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage('An error occurred while un-listing vendor');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } else {
      try {
        setisUnlisting(true);
        await axios.post(
          `${API_HOST}/api/v1/admin/listings/venues/${slug}/listing-status`,
          {
            venueStatus: 'UNLISTED',
            statusComment: 'Unlisting venue',
          }
        );
        setisUnlisting(false);
        fetchListings();
        setSnackbarMessage('Venue unlisted successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage('An error occurred while un-listing venue');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };

  useEffect(() => {
    setIsFetched(false);
    fetchListings();
  }, [isVendor, isSearchClicked, category]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Wrapper Wrapper>
      {!isFetched ? (
        <Box sx={BoxStyles}>
          <CircularProgress />
        </Box>
      ) : listings.length === 0 ? (
        <p>No Approved {isVendor ? 'Vendors' : 'Venues'}</p>
      ) : (
        <div>
          <section style={{ marginBottom: "15px", display: "flex", flexDirction: "row-reverse", justifyContent: "space-between", alignItems: "center" }}>
            <Pagination siblingCount={1} onChange={(_, pageNumber) => {
              setTableLoader(true)
              fetchListings(pageNumber)

            }} variant="outlined" shape="rounded" count={Math.ceil(totalPage / 10)} color="primary" />
            <p>Total: {totalPage}</p>

          </section>
          <Box sx={{height:"5px", borderRadius: "20px", width: '100%' }}>
            {
              tableLoader ?
                <LinearProgress  />
                : null
            }
          </Box>
          {
            listings.map((item, index) => {
              return (
                <Card
                  key={`${item}-${index}`}
                  data={item}
                  isApproved={true}
                  handleUnlist={handleUnlist}
                />
              );
            })
          }
        </div>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

export default ApprovedList;
