import { Cancel } from '@mui/icons-material';
import CropIcon from '@mui/icons-material/Crop';
import Backdrop from '@mui/material/Backdrop';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Slider,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';
import port from "../assets/phone/portrait.png";
import land from "../assets/phone/landscape.png";
import reset from "../assets/phone/reset.png";
import styled from 'styled-components';

const DivContainer = styled.div`
display: flex;
width:100%;
justify-content: center;
align-items: center; 
gap:5px;
height: auto;
margin-bottom: 25px;
`;

const ImagePhone = styled.img`
cursor: pointer;
width:26px;
height: 26px;
&:hover {
  transform: scale(1.1);
  }
`;
const ButtonPlus = styled.button`
background-color: blue;
border-radius: 44px;
font-size: 15px;
font-weight: 800;
padding:0px 6px;
display: flex;
justify-content: center;
align-items: center;
color: white;
&:hover {
  transform: scale(0.95);
  }
`;
const ButtonMinus = styled.button`
background-color: red;
border-radius: 44px;
font-size: 15px;
font-weight: 800;
padding:0px 7px;
display: flex;
justify-content: center;
align-items: center;
color: white;
margin-left: 5px;
&:hover {
  transform: scale(0.95);
  }
`;

const Htext = styled.p`
color:black;
font-size: 16px;
font-weight: 800;

`;


const CropEasy = ({ photoURL, setOpenCrop, setPhotoURL, setFile, file, uploadCoverImage, photoAlbum }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [ratio, setRatio] = useState(650 / 350)
    const [height, setHeight] = useState(350)
    const [width, setWidth] = useState(650)
    const cropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const cropImage = async () => {
        try {
            const { file, url } = await getCroppedImg(
                photoURL,
                croppedAreaPixels,
                rotation
            );
            if (photoAlbum) {
                setFile([file])
                // uploadCoverImage()
            } else {
                setFile(file);
                uploadCoverImage(file)
            }
            setPhotoURL(url);
            setOpenCrop(false);

        } catch (error) {
            console.log(error);
        }

    };

    const ClosecropImage = async () => {
        try {
            const { file, url } = await getCroppedImg(
                photoURL,
                croppedAreaPixels,
                rotation
            );
            if (photoAlbum) {
                setFile([file])
                // uploadCoverImage()
            } else {
                setFile(file);
                uploadCoverImage(file)
            }
            setPhotoURL(url);
            setOpenCrop(false);

        } catch (error) {
            console.log(error);
        }

    };

    useEffect(() => {
        const getImageDimensions = (imageSrc) => {
            const img = new Image();
            img.src = imageSrc;
            return new Promise((resolve) => {
                img.onload = () => {
                    resolve({ width: img.width, height: img.height });
                };
            });
        };
        const getAspectRatio = async () => {
            const { width, height } = await getImageDimensions(file);
            console.log(width,height,"yr h an w ha")
            setWidth(width)
            setHeight(height+50)
            setRatio(width / height+65)
        };
        getAspectRatio()
    }, [])


    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: "99999" }}
            open={true}
        >
            <div style={{ position: 'absolute', background: "white", zIndex: "9900" }}>
                <DialogContent
                    dividers
                    sx={{
                        background: '#333',
                        top: "0",
                        position: 'relative',
                        height: 450,
                        width: 400,
                        minWidth: { sm: 390 },
                    }}
                >
                    <Cropper
                        image={photoURL}
                        crop={crop}
                        zoom={zoom}
                        rotation={rotation}
                        aspect={ratio}
                        onZoomChange={setZoom}
                        onRotationChange={setRotation}
                        onCropChange={setCrop}
                        onCropComplete={cropComplete}
                    />
                </DialogContent>
                <DialogActions sx={{ flexDirection: 'column', mx: 3, my: 2 }}>
                    <DivContainer>
                        <div style={{ display: "flex", gap: "5px" }}>
                            <ImagePhone onClick={() => { setRotation(90); setRatio(height / width) }} src={port} alt="M" />
                            <ImagePhone onClick={() => { setRotation(0); setRatio(width / height) }} src={land} alt="M" />
                        </div>
                        {/* <div style={{ color: "black" }}>
                            <Htext>Height</Htext>
                            <section style={{ display: "flex" }}>
                                <ButtonPlus onClick={()=>setHeight}>+</ButtonPlus>
                                <ButtonMinus>-</ButtonMinus>
                            </section>

                        </div>
                        <div style={{ color: "black" }}>
                            <Htext>width</Htext>
                            <section style={{ display: "flex" }}>
                                <ButtonPlus>+</ButtonPlus>
                                <ButtonMinus>-</ButtonMinus>
                            </section>

                        </div> */}
                        {/* <ImagePhone onClick={() => { setRotation(0); setRatio(width / height) }} src={reset} alt="M" /> */}
                    </DivContainer>
                    <Box sx={{ width: '100%' }}>
                        <Box>
                            <Typography sx={{ fontSize: "13px", fontWeight: "700", color: "black" }}>Zoom: {zoomPercent(zoom)}</Typography>
                            <Slider
                                sx={{ height: "2px" }}
                                valueLabelDisplay="auto"
                                valueLabelFormat={zoomPercent}
                                min={1}
                                max={3}
                                step={0.1}
                                value={zoom}
                                onChange={(e, zoom) => setZoom(zoom)}
                            />
                        </Box>
                        <Box >

                            <Typography sx={{ fontSize: "13px", fontWeight: "700", color: "black" }} >Rotation: {rotation + '°'}</Typography>
                            <Slider
                                sx={{ height: "2px" }}
                                valueLabelDisplay="auto"
                                min={0}
                                max={360}
                                value={rotation}
                                onChange={(e, rotation) => setRotation(rotation)}
                            />
                        </Box>


                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            flexWrap: 'wrap',
                        }}
                    >
                        <Button
                            sx={{ fontSize: "12px", width: "90px", height: "35px" }}
                            variant="outlined"
                            startIcon={<Cancel />}
                            onClick={ClosecropImage}
                        >
                            Cancel
                        </Button>
                        <Button
                            sx={{ fontSize: "12px", width: "90px", height: "35px" }}
                            variant="contained"
                            startIcon={<CropIcon />}
                            onClick={cropImage}
                        >
                            Crop
                        </Button>
                    </Box>
                </DialogActions>
            </div>
        </Backdrop>
    );
};

export default CropEasy;

const zoomPercent = (value) => {
    return `${Math.round(value * 100)}%`;
};