import { useCallback, useEffect, useState } from 'react';
import imageCompression from 'browser-image-compression';
export const API_HOST = "https://api.maangalbazaar.com";
// export const API_HOST = 'https://t-api.maangal.offiql.com';
// export const API_HOST="http://localhost:8051";
// export const API_HOST = 'https://bazarapi.ctoninja.tech';
export const API_VERSION = 'v1';
export async function makeAPIRequest(args) {
  try {
    let { requestEndpoint, requestMethod, urlParams, queryParams, bodyParams } =
      args;
    const urlParamKeys = Object.keys(urlParams || {});
    urlParamKeys.forEach((paramKey) => {
      // @ts-ignore
      const paramValue = urlParams[paramKey];
      requestEndpoint = requestEndpoint.replace(
        `:${paramKey}`,
        encodeURIComponent(paramValue)
      );
    });
    const apiUrl = new URL(
      `/api/${API_VERSION}/${requestEndpoint.startsWith('/')
        ? requestEndpoint.slice(1)
        : requestEndpoint
      }`,
      API_HOST
    );
    for (const [queryKey, queryValue] of Object.entries(queryParams || {})) {
      // @ts-ignore
      apiUrl.searchParams.set(queryKey, queryValue.toString());
    }
    const apiResponse = await fetch(apiUrl, {
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      cache: 'no-cache',
      method: requestMethod,
      body: requestMethod !== 'GET' ? JSON.stringify(bodyParams) : undefined,
    });
    const responseData = await apiResponse.json();
    return {
      isSuccess: true,
      isError: false,
      responseCode: apiResponse.status,
      responseData: responseData,
      requestError: undefined,
    };
  } catch (err) {
    console.error(err);
    return {
      isSuccess: false,
      isError: true,
      responseCode: 0,
      responseData: undefined,
      requestError: err,
    };
  }
}
export function useAPIRequest(args) {
  const {
    requestEndpoint,
    requestMethod = 'GET',
    urlParams = {},
    queryParams = {},
    bodyParams = {},
    requestDeps = [],
  } = args;
  const [isLoading, setIsLoading] = useState(true);
  const [responseData, setResponseData] = useState({
    isSuccess: false,
    isError: false,
    responseCode: -1,
    requestError: undefined,
    responseData: undefined,
  });
  const requestFn = useCallback(async () => {
    const responseObject = await makeAPIRequest(args);
    setResponseData(responseObject);
    setIsLoading(false);
  }, [
    requestEndpoint,
    requestMethod,
    ...Object.entries(urlParams),
    ...Object.entries(queryParams),
    ...Object.entries(bodyParams),
  ]);
  
  useEffect(() => {
    requestFn();
  }, [...requestDeps]);
  return {
    isLoading: isLoading,
    ...responseData,
  };
}
export function usePreparedRequest(args) {
  const {
    requestEndpoint,
    requestMethod,
    urlParams = {},
    queryParams = {},
    bodyParams = {},
  } = args;
  const callableFn = useCallback(async () => {
    const responseData = await makeAPIRequest(args);
    return responseData;
  }, [
    requestEndpoint,
    requestMethod,
    ...Object.entries(urlParams),
    ...Object.entries(queryParams),
    ...Object.entries(bodyParams),
  ]);
  return callableFn;
}
export async function manageMedia(args) {
  try {
    let { mediaFiles, objectKeyGenFn, fileMethod } = args;
    // console.log(mediaFiles)
    fileMethod = Array.isArray(fileMethod)
      ? fileMethod
      : Array(mediaFiles.length).fill(fileMethod);
    const fileRequests = [];
    
    await Promise.all(
      mediaFiles.map(async (fileObj, fileIdx) => {
        // console.log(fileObj,"check what is this")
        const reqMethod = fileMethod[fileIdx];
        const objectKey = objectKeyGenFn(fileObj, fileIdx, reqMethod);
        const {
          isSuccess: isPresignedSuccess,
          isError: isPresignedError,
          responseCode: presignedResponseCode,
          responseData: presignedResponseData,
          requestError: presignedRequestError,
        } = await makeAPIRequest({
          requestMethod: 'POST',
          requestEndpoint: '/media/presigned-url',
          bodyParams: {
            requestMethod: reqMethod,
            objectKey: objectKey,
            objectSizeBytes: fileObj.size,
            objectFileName: fileObj.name,
            objectContentType: fileObj.type,
          },
        });
        if (isPresignedSuccess) {
          const { responseStatus: presignedResponseStatus } =
            presignedResponseData;
          if (presignedResponseStatus === 'SUCCESS') {
            const { presignedUrl, requestId } = presignedResponseData;
            fileRequests.push({
              fileHandle: fileObj,
              objectKey: objectKey,
              presignedURL: presignedUrl,
              requestId: requestId,
              requestMethod: reqMethod,
              requestStatus: 'WAIT',
              requestAcknowledged: false,
            });
          }
        }
      })
    );
    if (fileRequests.length !== mediaFiles.length) {
      return fileRequests;
    }
    const mappedRequests = await Promise.all(
      fileRequests.map(async (fileRequest, fileIdx) => {
        const {
          fileHandle,
          requestMethod,
          requestAcknowledged,
          requestId,
          requestStatus,
          presignedURL,
        } = fileRequest;
        if (requestMethod === 'GET') {
          return {
            ...fileRequest,
            requestAcknowledged: true,
            requestStatus: 'ACK',
          };
        }
        const { size, name, type } = fileHandle;





        try {
          const compressedFile = await imageCompression(fileHandle, { maxSizeMB: 0.6 });
          const s3Response = await fetch(presignedURL, {
            body: compressedFile,
            method: requestMethod,
          });
          if (s3Response.ok) {
            const {
              isSuccess,
              requestError,
              isError,
              responseCode,
              responseData,
            } = await makeAPIRequest({
              requestEndpoint: `/media/requests/:requestId`,
              requestMethod: requestMethod,
              urlParams: {
                requestId: requestId,
              },
              bodyParams: {
                mediaContentType: type,
                mediaSizeBytes: size,
                mediaFileName: name,
              },
            });
            if (isSuccess) {
              const { responseStatus } = responseData;
              if (responseStatus === 'SUCCESS') {
                return {
                  ...fileRequest,
                  requestStatus: 'ACK',
                  requestAcknowledged: true,
                };
              }
            }
            return {
              ...fileRequest,
              requestStatus: 'ERR',
              requestAcknowledged: false,
            };
          } else {
            throw new Error('Error uploading file to URL');
          }
        } catch (err) {
          console.error(err);
          const {
            isSuccess,
            requestError,
            isError,
            responseCode,
            responseData,
          } = await makeAPIRequest({
            requestEndpoint: `/media/requests/:requestId`,
            requestMethod: 'POST',
            urlParams: {
              requestId: requestId,
            },
          });
          if (isSuccess) {
            const { responseStatus } = responseData;
            if (responseStatus === 'SUCCESS') {
              return {
                ...fileRequest,
                requestStatus: 'ERR',
                requestAcknowledged: true,
              };
            }
          }
          return {
            ...fileRequest,
            requestStatus: 'ERR',
            requestAcknowledged: false,
          };
        }
      })
    );
    return mappedRequests;
  } catch (err) {
    return [];
  }
}

export const IMAGE_DIMENSIONS = {
  PORTFOLIO_PHOTO: {
    imgHeight: 1080,
    imgWidth: 1560,
  },
  COVER_IMAGE: {
    imgHeight: 600,
    imgWidth: 1560,
  },
};

export const IMAGE_FLEX_PX = 50;

export function checkImageFileDimensions(fileObj, imageDimensions) {
  return new Promise((resolve, reject) => {
    try {
      const tempImage = new Image();
      tempImage.style.display = 'none';
      tempImage.src = window.URL.createObjectURL(fileObj);

      tempImage.onload = () => {
        const { imgHeight, imgWidth } = imageDimensions;
        const heightValid =
          tempImage.naturalHeight >= imgHeight - IMAGE_FLEX_PX &&
          tempImage.naturalHeight <= imgHeight + IMAGE_FLEX_PX;

        const widthValid =
          tempImage.naturalWidth >= imgWidth - IMAGE_FLEX_PX &&
          tempImage.naturalWidth <= imgWidth + IMAGE_FLEX_PX;

        resolve([
          heightValid && widthValid,
          {
            imgHeight: tempImage.naturalHeight,
            imgWidth: tempImage.naturalWidth,
          },
        ]);
      };
    } catch (e) {
      reject(e);
    }
  });
}

export async function checkMultipleImageFileDimensions(
  fileCheck,
  imageDimensions
) {
  let finalImgDimensions = [];
  if (
    Array.isArray(imageDimensions) &&
    imageDimensions.length === fileCheck.length
  ) {
    finalImgDimensions = [...imageDimensions];
  } else {
    if (Array.isArray(imageDimensions)) {
      finalImgDimensions = Array(fileCheck.length).fill(imageDimensions[0]);
    } else {
      finalImgDimensions = Array(fileCheck.length).fill(imageDimensions);
    }
  }

  const mappedResults = await Promise.all(
    fileCheck.map(async (fileObj, fileIdx) => {
      const [dimensionsPassed, imgDim] = await checkImageFileDimensions(
        fileObj,
        finalImgDimensions[fileIdx]
      );
      return [fileIdx, dimensionsPassed, imgDim];
    })
  );

  const everyImagePassed = mappedResults.every((imgResult) => {
    const [_1, imgPassed, _2] = imgResult;
    return imgPassed === true;
  });

  return [everyImagePassed, mappedResults];
}
