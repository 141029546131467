import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Divider,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Typography,
    CircularProgress,
    Alert, Snackbar
} from '@mui/material';
import Switch from '@mui/material/Switch';
import {
    ChangeMembershipContext,
    DataContext,
    TypeContext,
} from '../../../App';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { API_HOST } from '../../Api/url';
import axios from 'axios';
import { Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment-timezone';
import AddBoxIcon from '@mui/icons-material/AddBox';
import zIndex from '@mui/material/styles/zIndex';
const boxStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100vw",
    bgcolor: "background.paper",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    boxShadow: 24,
    p: 4,
    minHeight: "100vh",
    maxHeight: "100vh",
    overflow: "scroll",
};
const BoxStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "17vh",
};
const BoxStylesLoader = {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    width: "100%",
    height: "100%",
    zIndex: "999",
    paddingTop: "40vh",
    background: "#ffffff77"
};

const SwitvhBox = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
gap: 2px;
flex-direction: row-reverse;
background: skyblue;
border-radius: 6px;
padding: 2px 9px ;
margin-top: 5px;
`;


const ShowTextArea = styled.div`
text-align: center;
margin: 5px 0px;
padding: 5px;
justify-content: space-between;
align-items: center;

`
const TextAreaArea = styled.textarea`
width: 100%;
min-height: 90px;
max-height: 90px;
resize: none;
border:1px solid gray;
outline: none;
border-radius: 5px;
font-size: 14px;
color: gray;
padding: 8px;

`
const SwitvhBoxText = styled.p`
font-size: 15px;
font-weight: 700;
`;

const MembershipSection = styled.div`
border:1px solid gray;
border-radius: 10px;
padding: 20px 20px 20px 20px;
`;

const MembershipSectionBox1 = styled.div`
display: flex;
justify-content: space-between;
align-items: start;
gap:15px;
@media (max-width: 854px) {
    flex-direction: column;
    gap:0px;
}
`;

const MembershipSectionBox2 = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap:10px;
@media (max-width: 480px) {
    flex-direction: column;
    gap:5px;
}
`;
const InnerMembershipBox2 = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
padding: 50px 0px 0px 0px;


`;
const InnerMembershipBox = styled.div`
width: 100%;

`;

const ShowPrice = styled.div`
text-align: center;
margin: 5px 0px;
padding: 5px;
display: flex;
border:1px dashed gray;
color: green;
height: 57px;
border-radius: 5px;
justify-content: space-between;
align-items: center;
width: 100%;

`
const InputEp = styled.input`
outline: none;
border:1px solid gray;
padding: 8px 8px;
font-size: 16px;
height: 57px;
border-radius: 5px;
width:100%
`

const NewUpdateMembershipModal = ({ data, showUpdateMembershipModel, setShowUpdateMembershipModel }) => {
    const [changeStartToggle, setChangeStartToggle] = useState(false)
    const [membership, setMembership] = useState('');
    const { ChangeMemberShipClicked, setChangeMemberShipClicked } = useContext(
        ChangeMembershipContext
    );
    const [effectiveAmount, setEffectiveAmount] = useState('')
    const [amountReceive, SetAmountReceive] = useState('0')
    const [plan, setPlans] = useState([]);
    const [mainLoader, setMainLoader] = useState(true)
    const { isVendor } = useContext(TypeContext);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [submitiionLoader, setSubmittionLoader] = useState(false)
    const [comments, setComments] = useState("")
    const [membershipDate, setMembershipDate] = useState("");
    const [value, setValue] = React.useState('1');
    const [planObjectIdAndName, setPlanObjectIdAndName] = useState({})
    const [planHistoryData, setPlanHistoryData] = useState([])
    const [payments, setPayments] = useState([])
    const [sectionLoader, setSectionLoader] = useState(false)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [paymentAmount, setPaymentAmount] = useState("");
    const [paymentComments, setPaymentComments] = useState("")
    const [finalRemainingAmount, setFinalRemainingAmount] = useState(0)
    const [updateOnlyExpiry, setUpdateOnlyExpiry] = useState(false)
    const [updateOnlyFinalPricing, setUpdateOnlyExpiryFinalPricing] = useState(false)
    const [someUpdateMade, setSomeUpdateMade] = useState(false)

    const handleMembershipDateChange = (event) => {
        const originalDate = new Date(event.target.value);
        const currentDate = new Date();
        const formattedDate = new Date(originalDate);
        if (formattedDate < currentDate) {

        } else {

            setMembershipDate(event.target.value);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleMembershipChange = (event) => {
        const value = plan.find((data, index) => data._id == event.target.value).amount
        if (value) {
            setEffectiveAmount(value)
            setMembership(event.target.value);
        }
    };

    const handleClose = () => setShowUpdateMembershipModel(false);

    async function FetchData() {
        try {
            if (!sectionLoader)
                setMainLoader(true)

            const [response, response2, response3] = await Promise.all([axios.get(`${API_HOST}/api/v1/admin/getplanpricing`), axios.get(`${API_HOST}/api/v1/admin/listings/vendorsvenue/${isVendor ? data.vendorSlug : data.venueSlug}/plan-history`), axios.get(`${API_HOST}/api/v1/admin/listings/vendorsvenue/${isVendor ? data.vendorSlug : data.venueSlug}/payments`)])
            if (Array.isArray(response2.data.planHistory)) {
                setPlanHistoryData(response2.data.planHistory)
            }
            if (Array.isArray(response3.data.payments)) {
                const final = response3.data.payments.find((data, index) => data.paymentType == "REMAINING")
                if (final && final?.amount >= 0) {
                    setFinalRemainingAmount(final.amount)
                }
                setPayments(response3.data.payments)
            }
            if (Array.isArray(response?.data?.response)) {
                setPlans(response.data.response)
                response.data.response.map((data) => planObjectIdAndName[data._id] = data.label)

            }
            setMainLoader(false)
            setSectionLoader(false)
            setPaymentAmount("");
            setPaymentComments("")
        } catch (error) {
            setSnackbarMessage('An error occurred , Please try later');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    }

    async function updateExpiryOnlyFunction() {

        if (!membershipDate) {
            setSnackbarMessage('Membership Expiry Date Missing');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return
        }
        if (!planHistoryData || planHistoryData.length == 0 || !planHistoryData?.[0]?.planId) {
            setSnackbarMessage('No Membership Plan Allotted Yet');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return
        }
        const currentDate = planHistoryData[0]?.planStart
        const premiumDate = new Date(membershipDate).toISOString()
        const currentDateNew = new Date(currentDate);
        const premiumDateNew = new Date(premiumDate);
        const differnce = Math.floor((premiumDateNew - currentDateNew) / (1000 * 60 * 60 * 24))
        if (differnce < 0) {
            setSnackbarMessage('Selected Membership Date Not Allowed');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return
        }
        const planId = planHistoryData[0].planId
        if (!planHistoryData[0]?.slug || !planHistoryData[0]?.profileType) {
            setSnackbarMessage('Invalid , Please Try Later');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return
        }
        try {
            setSectionLoader(true)
            const currentDate = new Date(new Date().toISOString())
            const body = {
                membershipEndDate: new Date(membershipDate).toISOString(),
                Comments: comments ? comments : "No Comments",
                planId,
                slug: planHistoryData[0].slug,
                planStart: planHistoryData[0]?.planStart,
                profileType: planHistoryData[0]?.profileType,
                finalAmount: planHistoryData[0]?.finalAmount,
                originalPrice: planHistoryData[0]?.originalPrice,
                changeStartToggle: changeStartToggle,
                membershipStartDate: changeStartToggle ? currentDate : planHistoryData[0]?.planStart,
            }
            await axios.post(`${API_HOST}/api/v1/admin/listings/expirydate/onlyupdateexpirydate`, body)
            await FetchData()
            setSnackbarMessage('Expiry Date Updated Successfully');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            resetThings()
        } catch (error) {
            setSnackbarMessage('An error occurred , Please try later');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            setSectionLoader(false)
        }

    }

    function resetThings() {
        setUpdateOnlyExpiry(false)
        setUpdateOnlyExpiryFinalPricing(false)
        setMembershipDate("")
        setComments("")
        setMembership("");
        setEffectiveAmount("")
        setSomeUpdateMade(true)
    }

    async function updateFinalPricingOnly() {
        try {
            if (!planHistoryData || planHistoryData.length == 0 || !planHistoryData?.[0]?.planId) {
                setSnackbarMessage('No Membership Plan Allotted Yet');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                return
            }
            const planId = planHistoryData[0].planId
            if (!planHistoryData[0]?.slug || !planHistoryData[0]?.profileType) {
                setSnackbarMessage('Invalid , Please Try Later');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                return
            }

            if (effectiveAmount >= 0) {

            } else {
                setSnackbarMessage('Use Valid Final Pricing');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                return
            }
            if (Number(effectiveAmount) < Number(planHistoryData[0]?.finalAmount)) {
                setSnackbarMessage('Lowering the Final Price Not Allowed');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                return
            }
            setSectionLoader(true)
            const body = {
                Comments: comments ? comments : "Final Price Updated Successfully",
                planId,
                slug: planHistoryData[0].slug,
                planStart: planHistoryData[0]?.planStart,
                planEndDate: planHistoryData[0]?.planEndDate,
                profileType: planHistoryData[0]?.profileType,
                finalAmount: planHistoryData[0]?.finalAmount,
                newFinalAmount: effectiveAmount >= 0 ? effectiveAmount : 0,
                originalPrice: planHistoryData[0]?.originalPrice,
            }

            await axios.post(`${API_HOST}/api/v1/admin/listings/finalpricing/onlyupdatefinalpricing`, body)
            await FetchData()
            setSnackbarMessage('Final Pricing Updated Successfully');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            resetThings()
        } catch (error) {
            setSnackbarMessage('An error occurred , Please try later');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            setSectionLoader(false)
        }
    }

    async function updateCompleteMembership() {
        try {
            if (!membership) {
                setSnackbarMessage('Choose A Memebership');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                return
            }
            if (!membershipDate) {
                setSnackbarMessage('Membership Expiry Date Missing');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                return
            }
            if (effectiveAmount >= 0) {

            } else {
                setSnackbarMessage('Use Valid Final Pricing');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                return
            }
            let membershipStartDate = data?.premiumStartingDate ? data.premiumStartingDate : (new Date()).toISOString()
            if (planHistoryData && planHistoryData.length == 0 && planHistoryData?.[0]?.planId) {
                membershipStartDate = planHistoryData[0].planStart
            }
            if (changeStartToggle) {
                membershipStartDate = (new Date()).toISOString()
            }
            const planId = membership;
            let slug = data?.venueSlug ? data?.venueSlug : data?.vendorSlug
            if (!slug) {
                setSnackbarMessage('Invalid , Please Try Later');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                return
            }
            setSectionLoader(true)
            let find = plan.find((data, index) => data._id == membership)
            if (!find || !find?.amount) {
                setSnackbarMessage('Invalid , Please Try Later');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                return
            }

            const body = {
                Comments: comments ? comments : "New Membership Allotted",
                planId,
                slug: slug,
                planStart: membershipStartDate,
                planEndDate: (new Date(membershipDate)).toISOString(),
                profileType: data?.venueSlug ? "VENUE" : "VENDOR",
                finalAmount: effectiveAmount,
                originalPrice: find?.amount,
                code: find?.code,
                remainingAmount: finalRemainingAmount
            }

            await axios.post(`${API_HOST}/api/v1/admin/listings/membership/updatenewmembership`, body)
            await FetchData()
            setSnackbarMessage('Membership Updated Successfully');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            resetThings()
        } catch (error) {
            setSnackbarMessage('An error occurred , Please try later');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            setSectionLoader(false)
        }
    }

    useEffect(() => {
        FetchData()
    }, [])

    async function AddSubAmountPayment(type) {
        if (paymentAmount <= 0) {
            setSnackbarMessage('Enter Valid Amount');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return
        }
        if (!planHistoryData || planHistoryData.length == 0 || !planHistoryData?.[0]?.planId) {
            setSnackbarMessage('No Membership Plan Allotted Yet');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return
        }
        const planId = planHistoryData[0].planId
        if (!planHistoryData[0]?.slug || !planHistoryData[0]?.profileType) {
            return
        }
        try {
            setSectionLoader(true)
            const body = {
                paymentAmount,
                paymentComments: paymentComments ? paymentComments : "No Comments",
                planId,
                type: type,
                slug: planHistoryData[0].slug,
                profileType: planHistoryData[0]?.profileType,
                finalAmount: planHistoryData[0]?.finalAmount
            }
            await axios.post(`${API_HOST}/api/v1/admin/listings/payments/addsubamountpayment`, body)
            await FetchData()
            resetThings()
        } catch (error) {
            setSnackbarMessage('An error occurred , Please try later');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            setSectionLoader(false)
        }
    }


    return (
        <Modal
            open={showUpdateMembershipModel}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Box sx={boxStyles}>
                <IconButton
                    onClick={() => {
                        if (someUpdateMade)
                            setChangeMemberShipClicked(!ChangeMemberShipClicked);

                        setShowUpdateMembershipModel(false)
                    }}
                    sx={{
                        position: 'relative',
                        left: '90%',
                        marginTop: '-30px',

                    }}
                >

                    <CloseIcon />
                </IconButton>
                <Typography variant={'h5'} sx={{ fontFamily: 'Poppins' }}>
                    Service Membership
                </Typography>
                <Typography sx={{ pt: 1, fontFamily: 'Poppins' }}>
                    Service Membership are additional services or policies enforced by
                    you.
                </Typography>
                <Divider sx={{ my: 2 }} />
                {mainLoader ? <Box sx={BoxStyles}>
                    <CircularProgress />
                </Box> :

                    <Box sx={{ width: '100%', typography: 'body1', position: "relative" }}>
                        {sectionLoader ? <Box sx={BoxStylesLoader}>
                            <CircularProgress />
                        </Box> : null}
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Manage Membership" value="1" />
                                    {finalRemainingAmount > 0 ? <Tab label={`${finalRemainingAmount} Remaining`} value="2" /> : <Tab label="Manage Payment" value="2" />}
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <MembershipSection>

                                    <MembershipSectionBox1>
                                        <InnerMembershipBox>
                                            <Typography sx={{ fontFamily: 'Poppins', mt: 2, mb: 1 }}>
                                                Membership
                                            </Typography>
                                            <Select
                                                fullWidth
                                                sx={{ fontFamily: 'Poppins', width: "100%" }}
                                                onChange={handleMembershipChange}
                                                value={membership}
                                            >
                                                {
                                                    plan.map((data, index) => {
                                                        return <MenuItem key={index} value={data._id}>{data.label}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </InnerMembershipBox>

                                        <InnerMembershipBox>
                                            <Typography sx={{ fontFamily: 'Poppins', mt: 2, mb: 1 }}>
                                                Membership Pricing
                                            </Typography>
                                            <ShowPrice>
                                                {
                                                    membership && plan.find((data, index) => data._id == membership).amount
                                                }
                                            </ShowPrice>
                                        </InnerMembershipBox>



                                        <InnerMembershipBox>
                                            <Typography sx={{ fontFamily: 'Poppins', mt: 2, mb: 1 }}>
                                                Final Pricing
                                            </Typography>
                                            <InputEp value={effectiveAmount} onChange={(e) => {
                                                if (!membership) {
                                                    return
                                                } else {
                                                    let find = plan.find((data, index) => data._id == membership)
                                                    if (find && !find?.canbeModified) {
                                                        setSnackbarMessage('Modification In final Pricing Not Allowed');
                                                        setSnackbarSeverity('error');
                                                        setSnackbarOpen(true);
                                                        return
                                                    }
                                                }

                                                const regex = /^[0-9]+$/
                                                if (e.target.value.length > 0 && e.target.value[0] == 0) {
                                                    setEffectiveAmount("0")
                                                }
                                                else if ((regex.test(e.target.value) && Number(e.target.value) <= 0)) {
                                                    setEffectiveAmount("0")
                                                }
                                                else if (!e.target.value) {

                                                    setEffectiveAmount("")

                                                } else {
                                                    if (regex.test(e.target.value)) {
                                                        setEffectiveAmount(e.target.value)
                                                    }
                                                }
                                            }} />
                                        </InnerMembershipBox>




                                    </MembershipSectionBox1>

                                    <Typography sx={{ fontFamily: 'Poppins', mt: 2, mb: 1 }}>
                                        Expiry Date
                                    </Typography>
                                    <TextField
                                        type={'date'}
                                        fullWidth
                                        onChange={handleMembershipDateChange}
                                        value={membershipDate}
                                        sx={{ fontFamily: 'Poppins' }}
                                    />{' '}

                                    <p style={{ fontSize: "12px", fontWeight: "800", color: "red" }}>Don't Choose Older or Today's Date</p>
                                    <SwitvhBox>
                                        <Switch color="success" onChange={() => setChangeStartToggle(!changeStartToggle)} checked={changeStartToggle} />
                                        <SwitvhBoxText>Want To Update Start Date</SwitvhBoxText>
                                    </SwitvhBox>
                                    <Typography sx={{ fontFamily: 'Poppins', mt: 2, }}>
                                        Comments
                                    </Typography>

                                    <TextAreaArea spellCheck={false} value={comments} onChange={(e) => setComments(e.target.value)} />
                                    <section style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "10px", flexWrap: "wrap" }}>
                                        <section style={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "10px", marginTop: "15px" }}>
                                            <input checked={updateOnlyExpiry} onChange={() => { setUpdateOnlyExpiryFinalPricing(false); setUpdateOnlyExpiry(!updateOnlyExpiry) }} type="checkbox" />
                                            <p onClick={() => {

                                                setUpdateOnlyExpiryFinalPricing(false); setUpdateOnlyExpiry(!updateOnlyExpiry)
                                            }} style={{ fontSize: "14px", cursor: "pointer" }}>Only Update Membership Expiry Date</p>
                                        </section>

                                        <section style={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "10px", marginTop: "15px" }}>
                                            <input checked={updateOnlyFinalPricing} onChange={() => {
                                                  if (planHistoryData[0].planId) {
                                                    setMembership(planHistoryData[0].planId)
                                                }
                                                setUpdateOnlyExpiry(false); setUpdateOnlyExpiryFinalPricing(!updateOnlyFinalPricing) }} type="checkbox" />
                                            <p style={{ fontSize: "14px", cursor: "pointer" }} onClick={() => {
                                                if (planHistoryData[0].planId) {
                                                    setMembership(planHistoryData[0].planId)
                                                }
                                                setUpdateOnlyExpiry(false); setUpdateOnlyExpiryFinalPricing(!updateOnlyFinalPricing)
                                            }}>Only Update Membership Final Pricing</p>
                                        </section>
                                    </section>
                                    <Button
                                        variant='contained'
                                        sx={{ textTransform: 'capitalize', fontFamily: 'Poppins', mt: 3 }}
                                        fullWidth
                                        onClick={() => {
                                            if (updateOnlyExpiry) {
                                                updateExpiryOnlyFunction()
                                            } else if (updateOnlyFinalPricing) {
                                                updateFinalPricingOnly()
                                            } else {
                                                updateCompleteMembership()
                                            }
                                        }}
                                        disabled={(!updateOnlyExpiry && !updateOnlyFinalPricing) && (!membership || !membershipDate || !(effectiveAmount >= 0))}
                                    >
                                        Save
                                    </Button>
                                </MembershipSection>
                                <Divider sx={{ my: 2 }} />
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Date</TableCell>
                                                <TableCell align="center">Membership</TableCell>
                                                <TableCell align="center">Start-Date</TableCell>
                                                <TableCell align="center">End-Date</TableCell>
                                                <TableCell align="center">Pricing</TableCell>
                                                <TableCell align="center">Final Pricing</TableCell>
                                                <TableCell align="center">Comment</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {planHistoryData.map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="center">
                                                        {row.createdAt ? moment(row.createdAt).tz("Asia/Kolkata").format('DD-MM-YYYY HH:mm') : ""}
                                                    </TableCell>
                                                    <TableCell align="center">{row.planId ? planObjectIdAndName[row.planId] ? planObjectIdAndName[row.planId] : "" : ""}</TableCell>
                                                    <TableCell align="center">{row.planStart ? moment(row.planStart).tz("Asia/Kolkata").format('DD-MM-YYYY') : ""}</TableCell>
                                                    <TableCell align="center">{row.planEndDate ? moment(row.planEndDate).tz("Asia/Kolkata").format('DD-MM-YYYY') : ""}</TableCell>
                                                    <TableCell align="center">{row.originalPrice} </TableCell>
                                                    <TableCell align="center">{row.finalAmount} </TableCell>
                                                    <TableCell align="center">{row.message}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>









                            </TabPanel>

                            <TabPanel value="2">
                                <MembershipSection>
                                    <MembershipSectionBox2>
                                        <InnerMembershipBox2>
                                            <IndeterminateCheckBoxIcon onClick={() => AddSubAmountPayment("SUB")} style={{ color: "red", fontSize: "50px", cursor: "pointer" }} />
                                        </InnerMembershipBox2>
                                        <InnerMembershipBox>
                                            <Typography sx={{ fontFamily: 'Poppins', mt: 2, mb: 1 }}>
                                                Amount
                                            </Typography>
                                            <InputEp value={paymentAmount} onChange={(e) => {
                                                if (planHistoryData.length > 0 && finalRemainingAmount >= 0) {
                                                    const regex = /^[0-9]+$/
                                                    if ((regex.test(e.target.value) && Number(e.target.value) <= 0)) {
                                                        setPaymentAmount("0")
                                                    }
                                                    else if (!e.target.value) {

                                                        setPaymentAmount("")

                                                    } else {
                                                        if (regex.test(e.target.value) && e.target.value <= finalRemainingAmount) {
                                                            setPaymentAmount(e.target.value)
                                                        } else {
                                                            setSnackbarMessage(`Can't Exceed The ${finalRemainingAmount} Amount`);
                                                            setSnackbarSeverity("error");
                                                            setSnackbarOpen(true);
                                                        }
                                                    }
                                                }
                                            }} />
                                        </InnerMembershipBox>
                                        <InnerMembershipBox2>
                                            <AddBoxIcon onClick={() => AddSubAmountPayment("ADD")} style={{ color: "green", fontSize: "50px", cursor: "pointer" }} />
                                        </InnerMembershipBox2>
                                    </MembershipSectionBox2>

                                    <Typography sx={{ fontFamily: 'Poppins', mt: 2, }}>
                                        Comments
                                    </Typography>

                                    <TextAreaArea spellCheck={false} value={paymentComments} onChange={(e) => setPaymentComments(e.target.value)} />



                                </MembershipSection>

                                <Divider sx={{ my: 2 }} />
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Date</TableCell>
                                                <TableCell align="center">Type</TableCell>
                                                <TableCell align="center">Membership</TableCell>
                                                <TableCell align="center">Amount</TableCell>
                                                <TableCell align="center">Comment</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {payments.map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="center">
                                                        {row.createdAt ? moment(row.createdAt).tz("Asia/Kolkata").format('DD-MM-YYYY HH:mm') : ""}
                                                    </TableCell>
                                                    <TableCell align="center">{row.planId ? planObjectIdAndName[row.planId] ? planObjectIdAndName[row.planId] : "" : ""}</TableCell>
                                                    <TableCell align="center">{row.paymentType}</TableCell>
                                                    <TableCell align="center">{row.amount}</TableCell>
                                                    <TableCell align="center">{row.message ? row.message : "No Comments"}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>
                        </TabContext>
                    </Box>}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: "center", horizontal: "center" }}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                        sx={{ width: "100%" }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>

            </Box>
        </Modal>
    );
};

export default NewUpdateMembershipModal;
