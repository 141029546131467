import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import {
  ChangeMembershipContext,
  DataContext,
  TypeContext,
} from '../../../App';

import { Modal } from '@mui/material';
import { boxStyles } from '../listings/Card';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
const SwitvhBox = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
gap: 2px;
flex-direction: row-reverse;
background: skyblue;
border-radius: 6px;
padding: 2px 9px ;
`;

const SwitvhBoxText = styled.p`
font-size: 15px;
font-weight: 700;
`;

const MembershipModal = ({ updateStatus, data }) => {
  const [changeStartToggle, setChangeStartToggle] = useState(false)
  const [membership, setMembership] = useState('');
  const { ChangeMemberShipClicked, setChangeMemberShipClicked } = useContext(
    ChangeMembershipContext
  );
  const { isVendor } = useContext(TypeContext);
  const [membershipDate, setMembershipDate] = useState(
    data?.length > 0
      ? new Date(
        isVendor
          ? data?.vendorMetadata?.find(
            (item) => item.propertyName === 'VENDOR_PREMIUM_DATE'
          )?.propertyValue
          : data?.venueMetadata?.find(
            (item) => item.propertyName === 'VENUE_PREMIUM_DATE'
          )?.propertyValue
      )
        .toISOString()
        .split('T')[0]
      : null
  );

  const handleMembershipDateChange = (event) => {
    // console.log(event.target.value)
    const originalDate = new Date(event.target.value);
    const currentDate = new Date();
    const formattedDate = new Date(originalDate);
    if (formattedDate < currentDate) {

    } else {
      // const year = formattedDate.getFullYear();
      // const month = formattedDate.getMonth() + 1; // Adding 1 because getMonth() returns 0-indexed month
      // const day = formattedDate.getDate();

      // const formattedDateString = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
      // console.log(formattedDateString);
      setMembershipDate(event.target.value);
    }
  };

  const handleMembershipChange = (event) => {
    setMembership(event.target.value);
  };

  useEffect(() => {
    let key = 0
    if (!data)
      return
    if (typeof data === 'object' && data.hasOwnProperty("venuePremiumStatus")) {
      if (typeof data.venuePremiumStatus == undefined)
        return
      key = data.venuePremiumStatus
      setMembership(key)
    } else if (typeof data === 'object' && data.hasOwnProperty("vendorPremiumStatus")) {
      if (typeof data?.vendorPremiumStatus == undefined)
        return
      key = data.vendorPremiumStatus
      setMembership(key)
    }

  })

  const date = new Date(membershipDate);

  const handleClose = () => setChangeMemberShipClicked(false);

  return (
    <Modal
      open={ChangeMemberShipClicked}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={boxStyles}>
        <IconButton
          onClick={() => setChangeMemberShipClicked(false)}
          sx={{
            position: 'relative',
            left: '90%',
            marginTop: '-20px',
          }}
        >

          <CloseIcon />
        </IconButton>
        <Typography variant={'h5'} sx={{ fontFamily: 'Poppins' }}>
          Service Membership
        </Typography>
        <Typography sx={{ pt: 1, fontFamily: 'Poppins' }}>
          Service Membership are additional services or policies enforced by
          you.
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography sx={{ fontFamily: 'Poppins', mt: 2, mb: 2 }}>
          Change Membership
        </Typography>
        <Select
          fullWidth
          sx={{ fontFamily: 'Poppins' }}
          onChange={handleMembershipChange}
          value={membership}
        >
          <MenuItem value={0} sx={{ fontFamily: 'Poppins' }}>
            Premium
          </MenuItem>
          <MenuItem value={1} sx={{ fontFamily: 'Poppins' }}>
            Featured
          </MenuItem>
          <MenuItem value={2} sx={{ fontFamily: 'Poppins' }}>
            Basic
          </MenuItem>
        </Select>
        <Typography sx={{ fontFamily: 'Poppins', mt: 2, mb: 2 }}>
          Membership Date
        </Typography>
        <TextField
          type={'date'}
          fullWidth
          onChange={handleMembershipDateChange}
          value={membershipDate}
          sx={{ fontFamily: 'Poppins' }}
        />{' '}
        <p style={{ fontSize: "12px", fontWeight: "800", color: "red" }}>Don't Choose Older or Today's Date</p>

        <SwitvhBox>
          <Switch color="success"  onChange={()=>setChangeStartToggle(!changeStartToggle)} checked={changeStartToggle} />
          <SwitvhBoxText>Want To Update Start Date</SwitvhBoxText>
        </SwitvhBox>
        <Button
          variant='contained'
          sx={{ textTransform: 'capitalize', fontFamily: 'Poppins', mt: 3 }}
          fullWidth
          onClick={() => updateStatus(date, membership,changeStartToggle)}
          disabled={membership === '' || membershipDate === null}
        >
          Save
        </Button>
      </Box>
    </Modal>
  );
};

export default MembershipModal;
