import {
  selectMetadata,
  zipMetadata,
} from '../../../../utility/schemaMetadata';
import { Grid, MenuItem, Select, Typography } from '@mui/material';
import { useValueSelector } from './hook';

export function BoolSelectQuestion(props) {
  const {
    questionText,
    questionOptions,
    vendorMetadataType,
    vendorMetadataDependencies,
    vendorMetadataDefaultValue,
    vendorMetadataKey,
  } = props;

  const [vendorMetadata, setVendorMetadata] = useValueSelector({
    defaultValue: () => {
      return [];
    },
    vendorValue: (vendorData) => {
      return vendorData.vendorMetadata;
    },
    venueValue: () => {
      return [];
    },
    updateVendorValue: (prevValue, partialValue) => {
      return {
        ...prevValue,
        vendorMetadata: zipMetadata(prevValue.vendorMetadata, partialValue),
      };
    },
    updateVenueValue: (prevValue, partialValue) => {
      return prevValue;
    },
  });

  const currentMetadataValue = selectMetadata(
    vendorMetadata,
    vendorMetadataKey,
    vendorMetadataDefaultValue
  );

  const unBoolean = (someBoolValue) => {
    return `##${someBoolValue}##`;
  };

  const toBoolean = (someUnBooledValue) => {
    const middleValue = someUnBooledValue.slice(2, -2);
    return middleValue === 'true';
  };

  return (
    <>
      <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography>
          <b>{questionText}</b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Select
          fullWidth
          sx={{ fontFamily: 'Poppins' }}
          value={unBoolean(currentMetadataValue)}
          onChange={(e) => {
            setVendorMetadata([
              // @ts-ignore
              ...vendorMetadata,
              {
                propertyName: vendorMetadataKey,
                propertyType: 'BOOLEAN',
                // @ts-ignore
                propertyValue: toBoolean(e.target.value),
              },
            ]);
          }}
        >
          {questionOptions.map((questionOpt) => {
            let { optionText, optionValue } = questionOpt;

            if (typeof optionValue === 'boolean') {
              optionValue = unBoolean(optionValue);
            }

            return (
              <MenuItem
                sx={{ fontFamily: 'Poppins' }}
                value={optionValue}
                key={`${optionText}.${optionValue}`}
              >
                {optionText}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
    </>
  );
}
