import React, { useContext, useState } from "react";
import styled from "styled-components";
import {
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Button,
  Alert,
  Snackbar,
} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {
  DataContext,
  ListingContext,
  PendingContext,
  TypeContext,
} from "../../../App";
import axios from "axios";
import { API_HOST } from "../../Api/url";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { VendorQuestionnaire } from "./VendorQuestionnaire";
import { selectMetadata, zipMetadata } from "../../../utility/schemaMetadata";
import { useServiceRender, useValueSelector } from "./questionnaire/hook";
import VenueQuestionnaire from "./VenueQuestionnaire";

const Wrapper = styled.div`
  margin: 20px 0px 20px 0px;
`;

const VENUE_METADATA_NAME_MAP = {
  VENUE_ESTABLISHMENT_DATE: "Date of Establishment",
  VENUE_PARKING_AVAILABLE: "Parking Availability",
  VENUE_DJ_INTERNAL: "DJ Policy",
  VENUE_CATERING_INTERNAL: "Catering Policy",
  VENUE_DECOR_INTERNAL: "Decor Policy",
  VENUE_ALCOHOL_INTERNAL: "Alcohol Policy",
  VENUE_ROOM_COUNT: "Available Room Count",
  VENUE_PLATFORM_DATE: "Date of joining",
  VENUE_PREMIUM_DATE: "Listing Active Since",
};

const Features = ({ setSelectedTab }) => {
  const [open, setOpen] = useState(false);

  const { vendorData } = useContext(DataContext);
  const { listingTickets, setListingTickets } = useContext(ListingContext);
  const { isPending } = useContext(PendingContext);
  const { isVendor } = useContext(TypeContext);
  const [metadataValues, setMetadataValues] = useValueSelector({
    defaultValue: () => {
      return [];
    },
    vendorValue: (vendorData) => {
      return vendorData.vendorMetadata;
    },
    venueValue: (venueData) => {
      return venueData.venueMetadata;
    },
    updateVenueValue: (prevValue, partialValue) => {
      return {
        ...prevValue,
        venueMetadata: zipMetadata(prevValue.venueMetadata, partialValue),
      };
    },
    updateVendorValue: (prevValue, partialValue) => {
      return {
        ...prevValue,
        vendorMetadata: zipMetadata(prevValue.vendorMetadata, partialValue),
      };
    },
  });

  const [serviceTypes, setServiceTypes] = useValueSelector({
    defaultValue: () => {
      return [];
    },
    venueValue: () => {
      return [];
    },
    vendorValue: (vendorData) => {
      return vendorData.vendorServiceTypes;
    },
    updateVenueValue: (prevValue, partialValue) => {
      return prevValue;
    },
    updateVendorValue: (prevValue, partialValue) => {
      return {
        ...prevValue,
        vendorServiceTypes: Array.from(new Set(partialValue)),
      };
    },
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const serviceFeatureElements = useServiceRender({
    fallbackElement: null,
    venueElement: (
      <LocalizationProvider>
        <VenueQuestionnaire />
      </LocalizationProvider>
    ),
    vendorElement: (
      <LocalizationProvider>
        <VendorQuestionnaire setSelectedTab={setSelectedTab} />
      </LocalizationProvider>
    ),
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const uploadData = async () => {
    setOpen(true)
    if (isVendor) {
      try {
        await axios.put(`${API_HOST}/api/v1/vendors/${vendorData.vendorSlug}`, {
          vendorMetadata: metadataValues,
          vendorServiceTypes: serviceTypes,
        });
        setSnackbarMessage("Vendor service features changed successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage(
          "An error occurred while changing service features "
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }finally{
        setOpen(false)
      }
    } else {
      console.log(metadataValues)
      try {
        await axios.put(`${API_HOST}/api/v1/venues/${vendorData.venueSlug}`, {
          venueMetadata: metadataValues,
        });
        setSnackbarMessage("Venue service features  changed successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage(
          "An error occurred while changing service features "
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }finally{
        setOpen(false)
      }
    }
  };

  const featureDescription = useServiceRender({
    fallbackElement: (
      <>
        {/* <Typography variant={'h4'}>Service Features</Typography> */}
        <Typography sx={{ pl: 2 }}>
          <ul>
            <li>
              Service Features are additional services or policies enforced by
              you.
            </li>
            <li>These additional features may enhance your listing</li>
            <li>Input or Select the appropriate policy for a given service</li>
          </ul>
        </Typography>
      </>
    ),
    vendorElement: (
      <>
        {/* <Typography variant={'h4'}>Service Features Questionnaire</Typography> */}
        <Typography sx={{ pl: 2 }}>
          <ul>
            <li>
              Service Features are additional services or policies enforced by
              you.
            </li>
            <li>These additional features may enhance your listing</li>
            <li>Answer the questionnaire regarding your service policies</li>
          </ul>
        </Typography>
      </>
    ),
  });

  return (
    <>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack
        spacing={2}
        sx={{
          height: "100%",
          flexGrow: 1,
          
        }}
      >
        <Stack  spacing={2}>
          {listingTickets &&
            isPending &&
            listingTickets.some((item) => {
              return (
                item.updatedFields.includes("vendorServiceTypes") ||
                item.updatedFields.includes("venueMetadata") ||
                item.updatedFields.includes("vendorMetadata")
              );
            }) && <p>(Updated)</p>}
          {featureDescription}
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Grid  container spacing={1} sx={{ alignItems: "center" }}>
          {serviceFeatureElements}
        </Grid>
        <Button
          variant="contained"
          sx={{
            fontFamily: "Poppins",
            textTransform: "capitalize",
            marginTop: "20px",
            marginBottom:"20px"
          }}
          onClick={uploadData}
        >
           {isPending &&vendorData?.premiumStartingDate?"Approve & Save " : "Save"}
        </Button>

        <br/>
              </Stack>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%", marginBottom: "20px" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Features;
