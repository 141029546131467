import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  Link,
  Snackbar,
  Stack,
  TextField,
} from '@mui/material';
import { makeAPIRequest } from '../../utility/mediaHandler';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function LoginForm() {
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleClick = useCallback(async () => {
    const { isSuccess, isError, responseCode, requestError, responseData } =
      await makeAPIRequest({
        requestEndpoint: '/auth/login',
        requestMethod: 'POST',
        bodyParams: {
          userMail: emailAddress,
          userPassword: password,
          userType: 'ADMIN',
        },
      });

    if (isSuccess) {
      const { responseStatus } = responseData;
      if (responseStatus === 'SUCCESS') {
        navigate('/');
      }
    }

    if (!responseData) {
      setError(
        'A network error occurred. Please check your internet connection and try again.'
      );
    }
    if (responseData) {
      if (responseCode === 400) {
        setError('Please check username or password');
      }
      if (responseCode === 404) {
        setError(
          `Failed to load resource status code: ${error.response.status}`
        );
      }
      setOpen(true);
    } else {
      console.log(error);
    }
    setOpen(true);
  }, [emailAddress, password]);

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name='email'
          label='Email address'
          defaultValue={emailAddress}
          onChange={(e) => {
            setEmailAddress(e.target.value);
          }}
        />
        <TextField
          name='password'
          label='Password'
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge='end'
                >
                  <VisibilityIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          defaultValue={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </Stack>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={{ my: 2 }}
      >
        <Link variant='subtitle2' underline='hover'>
          Forgot password?
        </Link>
      </Stack>
      <Button
        fullWidth
        size='large'
        type='submit'
        variant='contained'
        onClick={handleClick}
      >
        Login
      </Button>
      {error && (
        <Snackbar
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleClose} severity='error' sx={{ width: '100%' }}>
            {error.message || error}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
