import { Button, IconButton } from "@mui/material";
import React, { useState } from "react";
import { styled } from "styled-components";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const CardWrapper = styled.div`
  background-color: white;
  height: 420px;
  width: 310px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  box-shadow: 2px 2px 2px 1px skyblue;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  flex-grow: 1;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-family: Poppins;
  font-size: 13px;
`;

const Input = styled.input`
  padding: 5px;
  border-radius: 5px;
  color: #ada7a7;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
`;

const AddPhotoBox = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #0b63f8;
  height: 100%;
  cursor: pointer;
  margin-bottom: 16px;
  max-height: 250px;
`;

const AddPhotoText = styled.span`
  font-size: 16px;
  margin-left: 8px;
  font-family: Poppins;
  color: #0b63f8;
`;

const HiddenInput = styled.input`
  display: none;
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
`;

const ImageWrapper = styled.div`
  position: relative;
  max-width: 100%;
  height: 250px;
  margin-bottom: 8px;
  &:hover ${ButtonContainer} {
    opacity: 1;
  }
`;

const PreviewImage = styled.img`
  height: 100%;
  width: 100%;
  transition: filter 0.2s ease-in-out;

  ${ImageWrapper}:hover & {
    filter: blur(2px);
  }
`;

const Card = ({
  id,
  selectedFile,
  previewImage,
  onFileChange,
  onEditClick,
  onDeleteClick,
  handleClick,
  data,
}) => {
  const [adURL, setAdURL] = useState(data ? data.adURL : "maangalbazaar.com");

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      onFileChange(event);
    }
  };

  return (
    <CardWrapper>
      {data || previewImage ? (
        <ImageWrapper>
          <PreviewImage
            src={selectedFile ? previewImage : data.imageURL}
            alt="Selected Image"
          />
          {selectedFile || data ? (
            <ButtonContainer>
              <IconButton onClick={onEditClick}>
                <EditIcon sx={{ color: "white" }} />
              </IconButton>
              <IconButton onClick={onDeleteClick}>
                <DeleteIcon sx={{ color: "red" }} />
              </IconButton>
            </ButtonContainer>
          ) : (
            <ButtonContainer>
              <IconButton onClick={onEditClick}>
                <EditIcon sx={{ color: "#0b63f8" }} />
              </IconButton>
            </ButtonContainer>
          )}
        </ImageWrapper>
      ) : (
        <AddPhotoBox htmlFor={`file-input-${id}`}>
          <AddAPhotoIcon fontSize="large" sx={{ color: "#0b63f8" }} />
          <AddPhotoText>Add Advertisement Image</AddPhotoText>
        </AddPhotoBox>
      )}
      <HiddenInput
        id={`file-input-${id}`}
        type="file"
        accept="image/*"
        onChange={handleFileChange}
      />
      <InputWrapper>
        <Label htmlFor={`link-${id}`}>Link to Advertisement</Label>
        <Input
          type="url"
          id={`link-${id}`}
          placeholder="Add link here"
          value={adURL}
          onChange={(e) => setAdURL(e.target.value)}
        />
      </InputWrapper>
      <Button
        variant="contained"
        sx={{ boxShadow: "none", textTransform: "capitalize" }}
        onClick={() => handleClick(selectedFile, id, adURL)}
      >
        Upload
      </Button>
    </CardWrapper>
  );
};

export default Card;
