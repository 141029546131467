import React, { useEffect, useState } from 'react';
import Card from './Card';
import styled from 'styled-components';
import { Grid } from '@mui/material';
import axios from 'axios';
import { API_HOST } from '../../Api/url';
import { makeAPIRequest, manageMedia } from '../../../utility/mediaHandler';
import { Alert, Snackbar, Box } from '@mui/material';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

const Wrapper = styled.div`
  margin: 20px 0px 20px 0px;
`;

const StyledNo = styled.p`
  margin: 12px 0px;
  font-size: 18px;
  font-weight: 500;
  font-family: Poppins;
`;

const TopVenues = () => {
  const [backDropLoader, setBackDropLoader] = useState(true)
  const [data, setData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');


  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const fetchData = async () => {
    try {
      setBackDropLoader(true)
      const response = await axios.get(`${API_HOST}/api/v1/meta/top-venues`);
      setData(response.data.topVenues);
      // console.log(response.data.topVenues, "ye top venues ha")
    } catch (error) {
      console.log(error);
    } finally {
      setBackDropLoader(false)
    }
  };

  const handleClick = async (mainData, selectedFile, title, id, link, description) => {
    setBackDropLoader(true)
    await manageMedia({
      mediaFiles: [selectedFile],
      fileMethod: 'PUT',
      objectKeyGenFn: (fileHandle, fileIdx, fileMethod) => {
        return `media/top-venues/${title}/${id}`;
      },
    });


    let objectkey = `media/top-venues/${title}/${id}`;
    let coverImagekey = `media/top-venues/${title}/${id}`
    if (!selectedFile) {
      objectkey = mainData.ImageobjectKey;
      coverImagekey = mainData.ImageobjectKey;
    }
    try {
      await makeAPIRequest({
        requestEndpoint: '/meta/top-venues',
        requestMethod: 'POST',
        bodyParams: {
          venueLink: `${link}`,
          venueDescription: description,
          venueName: title,
          venueCoverImageObjectKey: coverImagekey,
          venueIndex: id,
          ImageobjectKey: objectkey
        },
      });
      if (fetchData) {
        fetchData();
        setSnackbarMessage(`Venues ${id+1} updated successfully`);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      }
     
    } catch (error) {
      fetchData();
      setSnackbarMessage('Fail To Update Top wedding venues , Try After Some Time !');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }


   
    // else {
    //   setSnackbarMessage('An error occurred while updating top wedding venues');
    //   setSnackbarSeverity('error');
    //   setSnackbarOpen(true);
    // }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClose = () => {
    // setBackDropLoader(false)
  };
  const handleOpen = () => {
    // setBackDropLoader(true)
  }

  return (
    <Wrapper>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backDropLoader}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container spacing={2}>
        {[...Array(4)].map((_, index) => {
          const item = data[index];
          return (
            <Grid item xs={6} key={`${index}-${item}`}>
              <StyledNo>Venue {index + 1}</StyledNo>
              <Card
                data={data && item}
                onUploadSuccess={fetchData}
                id={index}
                handleClick={handleClick}
              />
            </Grid>
          );
        })}
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

export default TopVenues;
