import { useContext } from 'react';
import { DataContext, TypeContext } from '../../../../App';

export function useValueSelector(ValueSelectorArgs) {
  const { isVendor } = useContext(TypeContext);
  const { vendorData, setVendorData } = useContext(DataContext);
  const {
    venueValue,
    defaultValue,
    vendorValue,
    updateVenueValue,
    updateVendorValue,
  } = ValueSelectorArgs;

  const defaultUpdater = (newValue) => {
    console.warn(
      'Contextual value was updated without a proper Venue or Vendor context'
    );
    console.warn(`Updated value: ${JSON.stringify(newValue, null, 4)}`);
  };

  const venueUpdater = (newValue) => {
    if (updateVenueValue && !isVendor) {
      const updatedData = updateVenueValue(vendorData, newValue);
      setVendorData(updatedData);
    }
  };

  const vendorUpdater = (newValue) => {
    if (updateVendorValue && isVendor) {
      const updatedData = updateVendorValue(vendorData, newValue);
      setVendorData(updatedData);
    }
  };

  if (isVendor) {
    if (vendorValue) {
      return [vendorValue(vendorData), vendorUpdater];
    } else {
      return [defaultValue(), defaultUpdater];
    }
  } else {
    if (venueValue) {
      return [venueValue(vendorData), venueUpdater];
    } else {
      return [defaultValue(), defaultUpdater];
    }
  }
}

export function useServiceRender(args) {
  const { isVendor } = useContext(TypeContext);
  const {
    vendorElement = null,
    fallbackElement = null,
    venueElement = null,
  } = args;

  if (isVendor) {
    return vendorElement ?? fallbackElement;
  }
  return venueElement ?? fallbackElement;
}
