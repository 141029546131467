import { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { API_HOST } from '../../../utility/mediaHandler';
import axios from 'axios';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Alert, Grid, Snackbar } from "@mui/material";
import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import moment from 'moment-timezone';


const TabContainer = styled.div`
margin-top:10px;
display: flex;
gap:10px;

`
const Vendor = styled.h1`
width: 100%;
padding: 3px 25px;
background-color:${(props) => props.status == 0 ? "green" : "white"};
color:${(props) => props.status == 0 ? "white" : "black"};
text-align: center;
box-shadow: 0px 0px 2px black;
cursor: pointer;
transition: 0.3s ease;
&:active{
    transform: scale(0.98);
}
`
const Venue = styled.h1`
width: 100%;
padding: 3px 25px;
background-color:${(props) => props.status == 1 ? "green" : "white"};
color:${(props) => props.status == 1 ? "white" : "black"};
text-align: center;
box-shadow: 0px 0px 2px black;
cursor: pointer;
transition: 0.3s ease;
&:active{
    transform: scale(0.98);
}`;

const RefreshButton = styled.button`
background-color: white;
padding: 2px 8px;
font-size: 13px;
box-shadow: 0px 0px 3px black;
border-radius: 4px;
&:active{
    transform: scale(0.98);
}
display: flex;
justify-content: center;
align-items: center;
gap:5px;


`

const VendorImage = styled.img`
object-fit: cover;
object-position: left top;
width:40px;
height: 40px;
min-width:40px;
min-height: 40px;
max-width:40px;
max-height: 40px;
border-radius: 150px;
`
const VendorAnchor = styled.a`
font-weight: 800;
color: navy;
cursor: pointer;
`;
const VendorType = styled.p`
color: white;
font-size: 14px;
font-weight: 800;
background-color: ${(props) => props.type == "venue" ? "#007FFF" : "#17B169"};
padding: 5px 10px;
width:fit-content;


`
const VendorDate = styled.p`
text-align: center;
font-size: 14px;
font-weight: 800;
min-width: 160px;
color: white;
background-color: ${(props) => props.imp == "1" ? "green" : props.imp == "2" ? "orangered" : "red"};
padding: 5px 6px;
width:fit-content;
`;
const UserType = styled.p`
font-size: 14px;
font-weight: 800;
color: white;
padding: 5px 10px;
width:fit-content;
background-color: ${(props) => props.type == "ADMIN" ? "red" : props.type == "SERVICE" ? "#FFAA33" : "#FFAC1C"};
`;

const UserName = styled.p`

`
const SearchBox = styled.p`
padding-top: 15px;
display: flex;
justify-content: space-between;
align-items: center;
gap:15px
`
const SearchInput = styled.input`
width: 350px;
box-shadow: 0px 0px 2px black;
border-radius: 3px;
padding:5px 8px;
height: 40px;
outline: none;
`;

const StatusButton = styled.button`
background-color: ${(props) => props.status == "UNLIST" ? "RED" : "GREEN"};
color: white;
padding: 5px 10px;
border-radius: 4px;
&:active {
        transform: scale(0.96);
    }
    font-weight: 600;
`;

const UpdateButton = styled.button`
background-color: green;
color: white;
padding: 5px 10px;
border-radius: 4px;
&:active {
        transform: scale(0.96);
    }
    font-weight: 600;
`;
const MetaTitleDescription = styled.p`
max-width: 200px;
min-width: 200px;
word-break: break-all;
`
const UpdateBox = styled.div`
display: flex;
justify-content: space-between;
align-items: flex-end;
gap:15px

`;
const UpdateBoxTitle = styled.input`
width: 100%;
box-shadow: 0px 0px 2px black;
border-radius: 3px;
padding:5px 8px;
height: 40px;
outline: none;
`;
const UpdateBoxDescription = styled.input`
width: 100%;
box-shadow: 0px 0px 2px black;
border-radius: 3px;
padding:5px 8px;
height: 40px;
outline: none;
`;
const UpdateBoxButton = styled.button`
width: fit-content;
box-shadow: 0px 0px 2px black;
border-radius: 3px;
padding:5px 15px;
height: 40px;
outline: none;
background-color: green;
color: white;
&:active {
        transform: scale(0.96);
    }
    font-weight: 600;
`;
const TitleDescriptionBox = styled.div`
width: 100%;
`;
const UpdateLable = styled.p`
width: 100%;
font-size: 14px;
word-break: keep-all;
display: flex;
`;

const VendorName = styled.p`
margin: 10px 0px;
font-weight: 800;
font-size: 18px;
text-align: center;
`;



export default function MetaVendorVenue() {
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(0)
    const [vendorVenue, setVendorVenue] = useState({ vendors: [], venues: [] })
    const [vendor, setVendor] = useState([])
    const [venue, setVenue] = useState([])
    const [page, setPage] = useState(0);
    const [searchValue, setSearchValue] = useState("")
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [showUpdateBox, SetShowUpdateBox] = useState(false)
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("")
    const [slug, setSlug] = useState("")
    const [vendorName, setVendorName] = useState("")


    async function UpdateMetaTitleDescription() {
        if (!slug || slug.trim().lenght == 0) {
            setSnackbarMessage(`SomeThing Went Wrong Fail To Update Meta ${vendorName ? `Of ${vendorName}` : ""}, Please Try Later`);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return
        }
        let flag = true
        if (!title || title.trim().lenght == 0) {
            flag = false;
            setSnackbarMessage(`Enter Valid Title To Update Meta ${vendorName ? `Of ${vendorName}` : ""}, Please Try Later`);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
        if (!description || description.trim().lenght == 0) {
            flag = false;
            setSnackbarMessage(`Enter Valid Description To Update Meta ${vendorName ? `Of ${vendorName}` : ""}, Please Try Later`);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
        if (status !== 0 && status !== 1) {
            flag = false
        }
        if (flag) {
            try {
                const body = {
                    slug: slug,
                    title: title,
                    description: description,
                    type: status == 0 ? "vendor" : "venue"
                }
                setOpen(true)
                await axios.post(`${API_HOST}/api/v1/admin/update-vendor-venue-meta`, body);
                fetchVendorVenue();
                setSnackbarMessage(`Meta Of ${vendorName} Successfully Updated`);
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            } catch {
                setSnackbarMessage(`Meta Of ${vendorName}, Please Try Later`);
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            } finally {
                setOpen(false)
            }
        }
    }



    function filterSearch(index, searchValue) {

        if (index == 0) {
            if (searchValue.trim().length == 0) {
                setVendor(vendorVenue.vendors)
            }
            const UpdatedArray = vendorVenue.vendors.filter(item =>
                item.vendorName && item.vendorName.toLowerCase().includes(searchValue.toLowerCase())
            );
            setVendor(UpdatedArray)

        } else if (index == 1) {
            if (searchValue.trim().length == 0) {
                setVenue(vendorVenue.venues)
            }
            const UpdatedArray = vendorVenue.venues.filter(item =>
                item.venueName && item.venueName.toLowerCase().includes(searchValue.toLowerCase())
            );
            setVenue(UpdatedArray)
        }
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    async function fetchVendorVenue() {
        try {
            setOpen(true)
            const response = await axios.get(`${API_HOST}/api/v1/admin/get-all-vendor-venue`);
            setVendorVenue(response.data.service);
            setVenue(response.data.service.venues);
            setVendor(response.data.service.vendors);
            if(searchValue.trim().length>0){
                filterSearch(status,searchValue.trim())
            }
        } catch (error) {

        } finally {
            setOpen(false)
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const CheckImportanceOfLead = useCallback((timeStamp) => {
        const currentDate = moment().add(5, 'hours').add(30, 'minutes');
        const dateToCheck = moment(timeStamp).add(5, 'hours').add(30, 'minutes');
        const differenceInDays = currentDate.diff(dateToCheck, "days");
        if (differenceInDays <= 30) {
            return "1";
        } else if (differenceInDays <= 60) {
            return "2";
        } else {
            return "3";
        }
    }, []);

    const ChangeTimeBasedUponIndianTime = (timeStamp) => {
        const formattedDate = moment(timeStamp).tz('Asia/Kolkata').format("DD MMM YYYY , HH:mm");
        return formattedDate ? formattedDate : timeStamp;
    };

    useEffect(() => {
        fetchVendorVenue()

    }, [])

    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    }

    return (
        <main id="updatemeta">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <TabContainer>
                <Vendor status={status} onClick={() => { setStatus(0); setPage(0); setSearchValue(""); setVendor(vendorVenue.vendors); setVenue(vendorVenue.venues); SetShowUpdateBox(false); setTitle(""); setDescription(""); setSlug(""); setVendorName("") }}>Vendor</Vendor>
                <Venue status={status} onClick={() => { setStatus(1); setPage(0); setSearchValue(""); setVendor(vendorVenue.vendors); setVenue(vendorVenue.venues); SetShowUpdateBox(false); setTitle(""); setDescription(""); setSlug(""); setVendorName("") }}>Venue</Venue>
            </TabContainer>
            <SearchBox>
                <SearchInput spellCheck={false} onChange={(e) => { setSearchValue(e.target.value); filterSearch(status, e.target.value); }} value={searchValue} placeholder="Search Vendor / Venue Name" />
                <RefreshButton onClick={() => { fetchVendorVenue(); setPage(0); setSearchValue("") }} ><AutorenewIcon /> Refresh</RefreshButton>
            </SearchBox>
            {showUpdateBox && <VendorName>{vendorName}</VendorName>}
            {showUpdateBox && <UpdateBox>
                <TitleDescriptionBox>
                    <UpdateLable>Meta Title</UpdateLable>
                    <UpdateBoxTitle onChange={(e) => setTitle(e.target.value)} type="text" value={title} />
                </TitleDescriptionBox>
                <TitleDescriptionBox>
                    <UpdateLable>Meta Description</UpdateLable>
                    <UpdateBoxDescription onChange={(e) => setDescription(e.target.value)} type="text" value={description} />
                </TitleDescriptionBox>
                <UpdateBoxButton onClick={UpdateMetaTitleDescription}>Update</UpdateBoxButton>
            </UpdateBox>}
            {status == 0 && <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '15px' }}>
                <TableContainer>
                    <Table stickyHeader aria-label='sticky table'>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Name / Link</TableCell>
                                <TableCell>Vendor Category</TableCell>
                                <TableCell>Meta Title</TableCell>
                                <TableCell>Meta Description</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {vendor
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((data, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            backgroundColor: index % 2 === 0 ? '#f7f7f7' : 'white',
                                        }}
                                    >
                                        <TableCell><VendorImage src={data.vendorCoverImageObjectKey ? `https://maangal-optim-prod.s3.ap-south-1.amazonaws.com/${data.vendorCoverImageObjectKey}` : "https://omnileadzdev.s3.amazonaws.com/NjVhNjU1NzhkMTE5NzMwMWE5NzRiNGI1/1711969834975_Web_Photo_Editor.jpg"} /></TableCell>
                                        <TableCell><VendorDate imp={CheckImportanceOfLead(data.createdAt ? data.createdAt : "2024-01-01T02:05:39.547Z")}>{ChangeTimeBasedUponIndianTime(data.createdAt ? data.createdAt : "2024-01-01T02:05:39.547Z")}</VendorDate></TableCell>
                                        <TableCell>< VendorAnchor target="_blank" href={`https://maangalbazaar.com/vendor_profile/${data.vendorSlug}`}>{data.vendorName}</ VendorAnchor></TableCell>
                                        <TableCell>{data.vendorCategory.replace(/_/g, " ")} / {data.vendorSubcategory.replace(/_/g, " ")}</TableCell>
                                        <TableCell><MetaTitleDescription>{data.metaTitle}</MetaTitleDescription></TableCell>
                                        <TableCell><MetaTitleDescription>{data.metaDescription}</MetaTitleDescription></TableCell>
                                        <TableCell><a href="#updatemeta"><UpdateButton onClick={() => { setTitle(data.metaTitle); setVendorName(data.vendorName); setDescription(data.metaDescription); setSlug(data.vendorSlug); SetShowUpdateBox(true); }}>Update Meta</UpdateButton></a></TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component='div'
                    count={vendor.length ? vendor.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>}


            {status == 1 && <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '15px' }}>
                <TableContainer>
                    <Table stickyHeader aria-label='sticky table'>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Name / Link</TableCell>
                                <TableCell>Meta Title</TableCell>
                                <TableCell>Meta Description</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {venue
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((data, index) => {
                                    return <TableRow
                                        key={index}
                                        sx={{
                                            backgroundColor: index % 2 === 0 ? '#f7f7f7' : 'white',
                                        }}
                                    >


                                        <TableCell><VendorImage src={data.venueCoverImageObjectKey ? `https://maangal-optim-prod.s3.ap-south-1.amazonaws.com/${data.venueCoverImageObjectKey}` : "https://omnileadzdev.s3.amazonaws.com/NjVhNjU1NzhkMTE5NzMwMWE5NzRiNGI1/1711969834975_Web_Photo_Editor.jpg"} /></TableCell>
                                        <TableCell><VendorDate imp={CheckImportanceOfLead(data.createdAt ? data.createdAt : "2024-01-01T02:05:39.547Z")}>{ChangeTimeBasedUponIndianTime(data.createdAt ? data.createdAt : "2024-01-01T02:05:39.547Z")}</VendorDate></TableCell>
                                        <TableCell>< VendorAnchor target="_blank" href={`https://maangalbazaar.com/venue_profile/${data.vendorSlug}`}>{data.venueName}</ VendorAnchor></TableCell>
                                        <TableCell><MetaTitleDescription>{data.metaTitle}</MetaTitleDescription></TableCell>
                                        <TableCell><MetaTitleDescription>{data.metaDescription}</MetaTitleDescription></TableCell>
                                        <TableCell><a href="#updatemeta"><UpdateButton onClick={() => { setTitle(data.metaTitle); setVendorName(data.venueName); setDescription(data.metaDescription); setSlug(data.venueSlug); SetShowUpdateBox(true); }}>Update Meta</UpdateButton></a></TableCell>


                                    </TableRow>
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component='div'
                    count={venue.length ? venue.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>


        </main>
    )
}