import { createContext } from 'react';

const AuthContext = createContext({
  authStatus: 'UNAUTHENTICATED',
  setAuthValue: (updatedAuthData) => {
    throw new Error('Attempted to update auth context before initialization!');
  },
});

export default AuthContext;
