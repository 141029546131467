import WindowIcon from '@mui/icons-material/Window';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { ReactComponent as Bagtick } from '../../assets/bagtick.svg';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import CollectionsIcon from '@mui/icons-material/Collections';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ReorderIcon from '@mui/icons-material/Reorder';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PieChartIcon from '@mui/icons-material/PieChart';
import RateReviewIcon from '@mui/icons-material/RateReview';
import LanguageIcon from '@mui/icons-material/Language';
export const tabList = [
  {
    tab: 'listing',
    icon: WindowIcon,
  },
  {
    tab: 'Users',
    icon: SupervisedUserCircleIcon,
  },
  {
    tab: 'Analytics',
    icon: PieChartIcon,
  },
  {
    tab: 'Seo',
    icon: LanguageIcon,
  },
  {
    tab: 'Vendor Review',
    icon: RateReviewIcon,
  },
  {
    tab: 'vendors',
    icon: ReorderIcon,
  },
  {
    tab: 'requests',
    icon: AssignmentTurnedInIcon,
  },
  { tab: 'pending', icon: HorizontalRuleIcon },
  { tab: 'approved', icon: HorizontalRuleIcon },
  { tab: 'rejected', icon: HorizontalRuleIcon },
  { tab: 'unlisted', icon: HorizontalRuleIcon },
  {
    tab: 'membership',
    icon: WorkspacePremiumIcon,
  },
  { tab: 'premium', icon: HorizontalRuleIcon },
  { tab: 'featured', icon: HorizontalRuleIcon },
  { tab: 'basic', icon: HorizontalRuleIcon },
  { tab: 'landing page', icon: Bagtick },
  { tab: 'wedding venues', icon: HorizontalRuleIcon },
  { tab: 'vendor categories', icon: HorizontalRuleIcon },
  { tab: 'gallery', icon: HorizontalRuleIcon },
  { tab: 'reviews', icon: HorizontalRuleIcon },
  {
    tab: 'advertisements',
    icon: HorizontalRuleIcon,
  },
];

export const tabList2 = [
  {
    tab: 'Profile',
    icon: AccountBoxIcon,
  },
  {
    tab: 'service Features',
    icon: SignalCellularAltIcon,
  },
  { tab: 'locations', icon: LocationOnIcon },
  {
    tab: 'Portfolio',
    icon: CollectionsIcon,
  },
  {
    tab: 'photos',
    icon: HorizontalRuleIcon,
  },
  {
    tab: 'albums',
    icon: HorizontalRuleIcon,
  },
  {
    tab: 'videos',
    icon: HorizontalRuleIcon,
  },
  {
    tab: 'pricing',
    icon: CurrencyRupeeIcon,
  },
];
