import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_HOST } from '../../Api/url';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import styled from 'styled-components';
import GroupsIcon from '@mui/icons-material/Groups';

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 4px 8px;
  max-width: 300px;

  width: 100%;
  border:1px solid gray;
  margin-top: 20px;
  height: 57px;
  @media (max-width:600px) {
    max-width: 100%;
}
`;

const SearchInput = styled(InputBase)`
  margin-left: 8px;
  flex: 1;
`;

const BoxContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: end;
gap: 10px;
@media (max-width:600px) {
  flex-wrap: wrap;
  margin-top: 10px;
}
`

const Total = styled.div`
justify-content: space-between;
width: 100%;
background-color: ${(props) =>
    props.color === "c0ccf851-3319-4651-aa9c-4d89dd1a1416" ? "darkmagenta" :
      props.color === "c0ccf851-3319-4651-aa9c-4d89dd1a1417" ? "darkgreen" :
        "red"
  };
color: white;
display: flex;
padding: 10px;
border-radius: 8px;
box-shadow: 1px 1px 2px gray;
margin:auto;
width:350px;
@media (max-width:600px) {
 width:100%;
}
`
const CountIcon = styled.p`
width:35px;
height: 35px;
border-radius: 44px;
background-color:lightgray;
padding: 2px;
display: flex;
justify-content: center;
align-items: center;
box-sizing: content-box;
color: navy;
box-shadow: 1px 1px 2px gray;
`

const CountText = styled.p`

font-size: 18px;
font-weight:500;
text-align: center;
font-family: monospace;
`
const CountNumber = styled.p`
font-size: 22px;
font-weight:600;
text-align: center;
font-family: monospace;
`
const CountDiv = styled.div`

display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
gap:5px

`

const VendorList = () => {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("")
  const [type, setType] = React.useState('c0ccf851-3319-4651-aa9c-4d89dd1a1416');
  const [color, setColor] = useState("c0ccf851-3319-4651-aa9c-4d89dd1a1416")
  const handleChange = (event) => {
    setType(event.target.value);
  };

  const getUsers = async () => {
    try {
      const response = await axios.get(
        `${API_HOST}/api/v1/admin/service-users`, {
        params: {
          searchText,
          type
        }
      }
      );
      setUserList(response.data.serviceUsers);
      setColor(type)
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, [searchText, type]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '75vh',
        }}
      >
        <CircularProgress />
      </div>
    );
  }



  return <>
    <Total color={color}>
      <CountDiv>
        <CountText>{color == "c0ccf851-3319-4651-aa9c-4d89dd1a1416" ? "Total Vendors" : color == "c0ccf851-3319-4651-aa9c-4d89dd1a1417" ? "Complete Signup Vendors" : "InComplete Signup Vendors"}</CountText>
        <CountNumber>{userList.length}</CountNumber>
      </CountDiv>
      <CountIcon>
        <GroupsIcon />
      </CountIcon>
    </Total>

    <BoxContainer>
      <SearchContainer>
        <SearchIcon />
        <SearchInput spellCheck={false} value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder="Search..." />
        {searchText.length > 0 ? <CancelIcon style={{ cursor: "pointer" }} onClick={() => setSearchText("")} /> : null}
      </SearchContainer>

      <FormControl style={{ width: 300 }}>
        <InputLabel id="demo-simple-select-label">Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type}
          label="type"
          onChange={handleChange}
        >
          <MenuItem value={"c0ccf851-3319-4651-aa9c-4d89dd1a1416"}>All</MenuItem>
          <MenuItem value={"c0ccf851-3319-4651-aa9c-4d89dd1a1417"}>Complete Signup</MenuItem>
          <MenuItem value={"c0ccf851-3319-4651-aa9c-4d89dd1a1418"}>Incomplete Signup</MenuItem>
        </Select>
      </FormControl>
    </BoxContainer>

    {

      userList.length == 0 ? <div style={{ marginTop: "50px", width: "100%", textAlign: "center" }}>No vendors available</div> :
        <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '32px' }}>
          <TableContainer>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  <TableCell>Service Type</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Contact</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user, index) => (
                    <TableRow
                      key={user.userMail}
                      sx={{
                        backgroundColor: index % 2 === 0 ? '#f7f7f7' : 'white',
                      }}
                    >
                      <TableCell>{user.linkedServiceType?user.linkedServiceType:"-"}</TableCell>
                      <TableCell>{new Date(user.createdAt).toLocaleString()}</TableCell>
                      <TableCell>{user.userFullName}</TableCell>
                      <TableCell>{user.userMail}</TableCell>
                      <TableCell>{user.userContact}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component='div'
            count={userList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
    }
  </>
};

export default VendorList;
