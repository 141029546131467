import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';

import ServiceQuestions from './service-questions.json';
import { useContext, useEffect, useState } from 'react';
import { useValueSelector } from './hook';
import { DataContext, TypeContext } from '../../../../App';

export function ServiceTypes() {
  const [questionOptions, setQuestionOptions] = useState([]);
  const { isVendor } = useContext(TypeContext);
  const { vendorData } = useContext(DataContext);
  const [serviceTypes, setServiceTypes] = useValueSelector({
    defaultValue: () => {
      return [];
    },
    venueValue: () => {
      return [];
    },
    vendorValue: (vendorData) => {
      return vendorData.vendorServiceTypes;
    },
    updateVenueValue: (prevValue, partialValue) => {
      return prevValue;
    },
    updateVendorValue: (prevValue, partialValue) => {
      return {
        ...prevValue,
        vendorServiceTypes: Array.from(new Set(partialValue)),
      };
    },
  });

  // useEffect(() => {
  //   console.log(serviceTypes);
  // }, [serviceTypes]);

  useEffect(() => {
    if (!isVendor) {
      return;
    }

    const filteredQuestions = ServiceQuestions.filter((questionObj) => {
      return (
        questionObj.vendorCategory === vendorData.vendorCategory &&
        questionObj.vendorSubcategory === vendorData.vendorSubcategory
      );
    });

    if (filteredQuestions.length > 0) {
      const selectedTypes = filteredQuestions[0].serviceTypes || [];
      setQuestionOptions(selectedTypes);
    }
  }, [vendorData]);

  if (questionOptions.length === 0) {
    return null;
  }

  return (
    <>
      <Grid  item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography>
          <b>Which of the following services do you provide?</b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container spacing={1}>
          {questionOptions.map((optionValue, optionIdx) => {
            return (
              <Grid item xs={12} sm={6} md={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={serviceTypes.includes(optionValue)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setServiceTypes([...serviceTypes, optionValue]);
                        } else {
                          setServiceTypes(
                            serviceTypes.filter((serviceType) => {
                              return serviceType !== optionValue;
                            })
                          );
                        }
                      }}
                    />
                  }
                  label={optionValue}
                  sx={{ fontFamily: 'Poppins' }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ my: 2 }} />
      </Grid>
    </>
  );
}
