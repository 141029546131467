import {
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  ImageList,
  ImageListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
  Alert,
  Snackbar,
  useTheme,
  IconButton,
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useCallback, useContext, useEffect, useState } from "react";
import { makeAPIRequest, manageMedia } from "../../../utility/mediaHandler";
import { styled } from "styled-components";
import { DataContext, TypeContext } from "../../../App";
import axios from "axios";
import { API_HOST } from "../../Api/url";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import { checkMultipleImageFileDimensions } from "../../../utility/mediaHandler";
import { IMAGE_DIMENSIONS } from "../../../utility/mediaHandler";
import CropEasy from "../../../utility/cropEasy";
import EditIcon from '@mui/icons-material/Edit';
const Wrapper = styled.div`
  margin: 20px 0px 20px 0px;
`;

const CropUploadButton = styled.button`
padding:5px 10px;
border:0;
border-radius: 8px;
font-weight:${(props) => (props.state ? "500" : "400")};
color:${(props) => (props.state ? "white" : "black")};
background-color: ${(props) => (props.state ? "SlateBlue" : "transparent")};
transition: all 0.4s ease-in-out;
border:${(props) => (props.state ? "2px solid transparent" : "2px solid black")};
`;
const CropUploadButtonConatiner = styled.div`
padding:5px 20px 0px 20px;
display: flex;
height:60px;
justify-content: flex-start;
align-items: center;
gap:12px;
`;


function PhotoForm({
  isOpen,
  albumId,
  onClose,
  onFilesUpload,
  data,
  isVendor,
  fetchAlbumPhotos,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarSeverity,
}) {
  const [imageLoader, setImageLoader] = useState(false)
  const [imageErrorSuccess, setImageErrorSuccess] = useState({ error: false, success: false })
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [photoURL, setPhotoURL] = useState(null)
  const [file, setFile] = useState(null)
  const [openCrop, setOpenCrop] = useState(false)
  const [withEdit, setWithEdit] = useState(false)

  const uploadFiles = useCallback(async () => {
    setImageLoader(true)
    const [allImagesPassed, imageResults] =
      await checkMultipleImageFileDimensions(
        selectedFiles,
        IMAGE_DIMENSIONS.PORTFOLIO_PHOTO
      );

    // if (!allImagesPassed) {
    //   alert(
    //     `Please upload images of size (${IMAGE_DIMENSIONS.PORTFOLIO_PHOTO.imgWidth}x${IMAGE_DIMENSIONS.PORTFOLIO_PHOTO.imgHeight})`
    //   );
    //   return;
    // }
    const objectKeyGenFn = (fileHandle, fileIdx, reqMethod) => {
      const { name } = fileHandle;

      const fileNameSegments = name.split(".");
      const fileName = fileNameSegments.slice(0, -1).join("");
      const fileExt = fileNameSegments[fileNameSegments.length - 1] || "";

      if (!isVendor) {
        return `media/venues/${data.venueSlug}/portfolio/albums/${albumId}/photos/${fileName}.${fileExt}`;
      } else {
        return `media/vendors/${data.vendorSlug}/portfolio/albums/${albumId}/photos/${fileName}.${fileExt}`;
      }
    };

    const mediaResponse = await manageMedia({
      mediaFiles: selectedFiles,
      objectKeyGenFn: objectKeyGenFn,
      fileMethod: "PUT",
    });

    await Promise.all(
      mediaResponse.map(async (item) => {
        await axios.post(
          `${API_HOST}/api/v1/admin/${isVendor ? "vendors" : "venues"}/${isVendor ? data.vendorSlug : data.venueSlug
          }/portfolio/albums/${albumId}/photos`,
          {
            objectKey: item.objectKey,
          }
        );
      }),
      setSnackbarMessage("Albums updated successfully"),
      setSnackbarSeverity("success"),
      setSnackbarOpen(true)
    );

    await fetchAlbumPhotos();
    setSelectedFiles([])
    setImageLoader(false)
    setFile(null)
    setPhotoURL(null)
    setImageErrorSuccess({ error: false, success: true })
    onClose();
    setImageErrorSuccess({ error: false, success: false })
  }, [albumId, onClose, onFilesUpload, selectedFiles]);


  function closePhotoBox() {
    setSelectedFiles([])
    setImageLoader(false)
    setFile(null)
    setPhotoURL(null)
    setImageErrorSuccess({ error: false, success: false })
    onClose()
  }

  return (
    <>
      {openCrop ? <CropEasy photoURL={photoURL} file={file} setOpenCrop={setOpenCrop} setFile={setSelectedFiles} uploadCoverImage={uploadFiles} photoAlbum={true} setPhotoURL={setPhotoURL} /> : null}

      <Dialog open={isOpen} onClose={closePhotoBox}>
        <CropUploadButtonConatiner >
          <CropUploadButton onClick={() => {
            if (selectedFiles.length == 0) {
              setWithEdit(true)
            }
          }} state={withEdit}><EditIcon/> Edit & Upload</CropUploadButton>
          <CropUploadButton onClick={() =>{
            if(!file)
            setWithEdit(false)
          }} state={!withEdit}>Upload</CropUploadButton>
        </CropUploadButtonConatiner>
        <DialogTitle>Upload New Photos</DialogTitle>
        <DialogContent>
          <Grid container sx={{ alignItems: "center" }} spacing={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type={"file"}
                inputProps={{
                  accept: ".jpg, .jpeg, .png",
                  multiple:!withEdit,
                }}
                fullWidth
                onChange={(e) => {
                  setImageLoader(false)
                  setImageErrorSuccess({ error: false, success: false })
                  // @ts-ignore
                  if ((e.target.files || []).length > 0) {
                    if (withEdit) {
                      setFile(e.target.files[e.target.files.length - 1])
                      setPhotoURL(URL.createObjectURL(e.target.files[e.target.files.length - 1]))
                      setOpenCrop(true)
                    } else {
                      const fileList = e.target.files;

                      const fileArray = Array.from(fileList);

                      setSelectedFiles(fileArray);
                    }
                  }
                }}
              />
              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "15px" }}><p style={{ color: "red", fontSize: "13px" }}>Allowed file types: JPG, JPEG, PNG </p>{imageLoader ? <CircularProgress style={{ width: "20px", height: "20px" }} /> : null}{imageErrorSuccess.error ? <ErrorIcon style={{ width: "25px", height: "25px", color: "red" }} /> : null}{imageErrorSuccess.success ? <CheckCircleIcon style={{ width: "25px", height: "25px", color: "green" }} /> : null}</div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "space-between",
            px: 4,
          }}
        >
          <Button
            variant={"contained"}
            onClick={() => {
              setSelectedFiles([])
              setImageLoader(false)
              setFile(null)
              setPhotoURL(null)
              setImageErrorSuccess({ error: false, success: false })
              onClose();
              
            }}
            color={"error"}
          >
            Close Form
          </Button>
          <Button
            variant={"contained"}
            sx={{
              my: 2,
            }}
            onClick={() => {
              uploadFiles();
            }}
            disabled={selectedFiles.length === 0}
          >
            Upload Photos
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function AlbumItem({
  albumName,
  albumId,
  setActive,
  isVendor,
  vendorData,
  fetchData,
}) {
  const theme = useTheme();

  const handleDeleteAlbum = async (albumId) => {
    if (isVendor) {
      try {
        await axios.delete(
          `${API_HOST}/api/v1/admin/vendors/${vendorData.vendorSlug}/portfolio/albums/${albumId}`
        );
        fetchData();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await axios.delete(
          `${API_HOST}/api/v1/admin/venues/${vendorData.venueSlug}/portfolio/albums/${albumId}`
        );
        fetchData();
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Grid item lg={4} md={6} sm={6} xs={12}>
      <Box
        sx={{
          height: "auto",
          display: "flex",
          border: "1px dotted darkgrey",
          color: theme.palette.text.primary,
          cursor: "pointer",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        onClick={() => {
          setActive();
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FolderIcon sx={{ margin: "0 4px", color: "darkgray" }} />
          <ListItemText disableTypography primary={albumName} />
        </div>
        <IconButton
          aria-label="delete"
          color="error"
          onClick={(event) => {
            event.stopPropagation();
            handleDeleteAlbum(albumId);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Grid>
  );
}

function AlbumForm({ isOpen, onClose, onAlbumCreate, isVendor, data }) {
  const [albumName, setAlbumName] = useState("");

  const createAlbum = useCallback(async () => {
    const { isSuccess, isError, requestError, responseCode, responseData } =
      await makeAPIRequest({
        requestEndpoint: `/admin/${isVendor ? "vendors" : "venues"}/${isVendor ? data.vendorSlug : data.venueSlug
          }/portfolio/albums`,
        requestMethod: "POST",
        bodyParams: {
          albumName: albumName,
        },
      });

    if (isSuccess) {
      const { responseStatus } = responseData;
      if (responseStatus === "SUCCESS") {
        const { albumId } = responseData;
        onAlbumCreate(albumId, albumName);
        onClose();
      }
    }
  }, [albumName]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Create New Album</DialogTitle>
      <DialogContent>
        <Grid container sx={{ alignItems: "center" }} spacing={2}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography>Album Name</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              placeholder={"New Album"}
              onChange={(e) => {
                setAlbumName(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "space-between",
          px: 4,
        }}
      >
        <Button variant={"contained"} onClick={onClose} color={"error"}>
          Close Form
        </Button>
        <Button
          variant={"contained"}
          sx={{
            my: 2,
          }}
          onClick={createAlbum}
          disabled={albumName === ""}
        >
          Create New Album
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function AlbumView({
  albumId,
  albumName,
  onReturn,
  data,
  isVendor,
  fetchData,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarSeverity,
}) {
  const [albumPhotos, setAlbumPhotos] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const fetchAlbumPhotos = async () => {
    const response = await axios.get(
      `${API_HOST}/api/v1/${isVendor ? "vendors" : "venues"}/${isVendor ? data.vendorSlug : data.venueSlug
      }/portfolio/albums/${albumId}/photos`
    );
    setAlbumPhotos(response.data.albumPhotos);
  };

  const handleDeleteAlbumPhoto = async (photoId) => {
    if (!isVendor) {
      try {
        await axios.delete(
          `${API_HOST}/api/v1/admin/venues/${data.venueSlug}/portfolio/albums/${albumId}/photos/${photoId}`
        );
        fetchAlbumPhotos();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await axios.delete(
          `${API_HOST}/api/v1/admin/vendors/${data.vendorSlug}/portfolio/albums/${albumId}/photos/${photoId}`
        );
        fetchAlbumPhotos();
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchAlbumPhotos();
  }, [albumId]);

  return (
    <>
      <PhotoForm
        isOpen={showForm}
        albumId={albumId}
        onClose={() => {
          setShowForm(false);
        }}
        onFilesUpload={(uploadedFiles) => {
          setAlbumPhotos((prevPhotos) => {
            return [...uploadedFiles, ...prevPhotos];
          });
        }}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarSeverity={setSnackbarSeverity}
        data={data}
        fetchData={fetchData}
        isVendor={isVendor}
        fetchAlbumPhotos={fetchAlbumPhotos}
      />
      <Grid item xs={2}>
        <IconButton
          variant="contained"
          sx={{ backgroundColor: "#509de9", color: "" }}
          onClick={onReturn}
        >
          <ArrowBackRoundedIcon />
        </IconButton>
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Typography variant={"h4"}>{albumName}</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Button
          variant={"contained"}
          onClick={() => {
            setShowForm(true);
          }}
          sx={{
            boxShadow: 0,
          }}
        >
          {/* <Iconify icon={'eva:plus-fill'} /> */}
          Upload Photo
        </Button>
      </Grid>
      <Grid xs={12}>
        <Divider sx={{ my: 2 }} />
      </Grid>
      <Grid xs={12} sx={{ pt: 2 }}>
        <ImageList cols={3} gap={8} variant={"masonry"}>
          {albumPhotos.map(({ photoId, photoURL }) => {
            return (
              <ImageListItem key={photoId}>
                <img
                  src={photoURL}
                  loading={"lazy"}
                  alt={`${albumName}-${photoId}`}
                  style={{
                    borderRadius: 16,
                    cursor: "pointer",
                    transition: "all 0.3s ease-in-out",
                  }}
                  className="portfolio-image"
                />
                <IconButton
                  aria-label="delete"
                  color="error"
                  style={{
                    position: "absolute",
                    top: "85%",
                    left: "90%",
                    transform: "translate(-50%, -50%)",
                    opacity: 1,
                    transition: "all 0.3s ease-in-out",
                  }}
                  className="delete-icon"
                  onClick={() => handleDeleteAlbumPhoto(photoId)}
                >
                  <DeleteIcon />
                </IconButton>
              </ImageListItem>
            );
          })}
        </ImageList>
      </Grid>
    </>
  );
}

const Albums = () => {
  const [showForm, setShowForm] = useState(false);
  const [activeAlbumIdx, setActiveAlbumIdx] = useState(-1);
  const [albumList, setAlbumList] = useState([]);
  const { isVendor } = useContext(TypeContext);
  const { vendorData } = useContext(DataContext);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const noAlbumsToDisplay = Object.keys(albumList).length === 0;

  const fetchData = async () => {
    let response;
    if (isVendor) {
      try {
        response = await axios.get(
          `${API_HOST}/api/v1/vendors/${vendorData.vendorSlug}/portfolio/albums`
        );
        setAlbumList(response.data.portfolioAlbums);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        response = await axios.get(
          `${API_HOST}/api/v1/venues/${vendorData.venueSlug}/portfolio/albums`
        );
        setAlbumList(response.data.portfolioAlbums);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Wrapper>
      <Stack spacing={2}>
        {activeAlbumIdx === -1 ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant={"h5"} sx={{ fontFamily: "Poppins" }}>
                  Portfolio - Albums
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <Button
                  variant={"contained"}
                  onClick={() => {
                    setShowForm(true);
                  }}
                  sx={{
                    boxShadow: 0,
                    fontFamily: "Poppins",
                    textTransform: "capitalize",
                  }}
                >
                  Create New Album
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontFamily: "Poppins" }}>
                  Albums are a collection of photos grouped by events, or
                  locations. Create or add photos to your albums to collectively
                  best works of art of user.
                </Typography>
              </Grid>
              <AlbumForm
                isOpen={showForm}
                isVendor={isVendor}
                data={vendorData}
                onClose={() => {
                  setShowForm(false);
                }}
                onAlbumCreate={(albumId, albumName) => {
                  setAlbumList((prevList) => {
                    return [
                      {
                        albumId: albumId,
                        albumName: albumName,
                        albumImageCount: 0,
                      },
                      ...prevList,
                    ];
                  });
                }}
              />
            </Grid>
            <Divider />
          </>
        ) : null}
        <Grid container spacing={1}>
          {noAlbumsToDisplay ? (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography variant={"h6"}>
                  <i>Create an album to get started</i>
                </Typography>
              </Box>
            </Grid>
          ) : activeAlbumIdx !== -1 ? (
            <AlbumView
              albumId={albumList[activeAlbumIdx].albumId}
              albumName={albumList[activeAlbumIdx].albumName}
              onReturn={() => {
                setActiveAlbumIdx(-1);
              }}
              isVendor={isVendor}
              data={vendorData}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarSeverity={setSnackbarSeverity}
              fetchData={fetchData}
            />
          ) : (
            albumList.map((albumData, albumIdx) => {
              return (
                <AlbumItem
                  key={albumData.albumId}
                  albumName={albumData.albumName}
                  isVendor={isVendor}
                  vendorData={vendorData}
                  fetchData={fetchData}
                  albumId={albumData.albumId}
                  // @ts-ignore
                  setActive={() => {
                    setActiveAlbumIdx(albumIdx);
                  }}
                />
              );
            })
          )}
        </Grid>
      </Stack>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

export default Albums;
