import React, {useState, useRef } from 'react';
import styled from 'styled-components';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const CardWrapper = styled.div`
background-color: white;
  border: 1px solid #0b63f8;
  height: 290px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 0px 5px black;
  @media (max-width:850px) {
    width:90%;
  }
  @media (max-width:550px) {
    width:95%;
  }
`;

const AddPhotoBox = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #0b63f8;
  height: 100%;
  cursor: pointer;
`;

const AddPhotoText = styled.span`
  font-size: 16px;
  margin-left: 8px;
  font-family: Poppins;
  color: #0b63f8;
`;

const HiddenInput = styled.input`
  display: none;
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
`;

const ImageWrapper = styled.div`
  position: relative;
  max-height: 80%;
  min-height: 80%;
  width: 100%;
  &:hover ${ButtonContainer} {
    opacity: 1;
  }
`;

const PreviewImage = styled.img`
  height: 100%;
  width: 100%;
  transition: filter 0.2s ease-in-out;

  ${ImageWrapper}:hover & {
    filter: blur(2px);
  }
`;
const LinkInputText = styled.input`
width:100%;
height: 60px;
box-shadow: 0px 0px 2px black;
 margin-top: 10px;
 border-radius: 5px;
 outline: none;
 padding: 2px 6px;
 font-size: 15px;
 font-weight: 500;
`

const GalleryCard = ({
  linkValue,
  setLinkValue,
  index,
  selectedFile,
  previewImage,
  onFileChange,
  onEditClick,
  onDeleteClick,
  data,
  id,
  onUploadSuccess,
}) => {
  const inputRef = useRef(null);
  return (
    <CardWrapper>
      {data || previewImage ? (
        <ImageWrapper>
          <PreviewImage
            src={selectedFile ? previewImage : data.mediaURL}
            alt='img'
          />
          {selectedFile ? (
            <ButtonContainer>
              <IconButton onClick={onEditClick}>
                <EditIcon sx={{ color: '#0b63f8' }} />
              </IconButton>
              <IconButton onClick={onDeleteClick}>
                <DeleteIcon sx={{ color: '#0b63f8' }} />
              </IconButton>
            </ButtonContainer>
          ) : (
            <ButtonContainer>
              <IconButton onClick={onEditClick}>
                <EditIcon sx={{ color: '#0b63f8' }} />
              </IconButton>
            </ButtonContainer>
          )}
        </ImageWrapper>
      ) : (
        <AddPhotoBox htmlFor={`file-input-${index}`}>
          <AddAPhotoIcon fontSize='large' sx={{ color: '#0b63f8' }} />
          <AddPhotoText>Add Photo</AddPhotoText>
        </AddPhotoBox>
      )}
      <HiddenInput
        ref={inputRef}
        id={`file-input-${index}`}
        type='file'
        accept='image/*'
        onChange={onFileChange}
      />
      <LinkInputText value={linkValue[index]} onChange={(e)=>{
        const updatedValue=[...linkValue];
        updatedValue[index]=e.target.value.trim().toString();
        setLinkValue([...updatedValue])

      }} spellCheck={false} type="text" />
    </CardWrapper>
  );
};

export default GalleryCard;
