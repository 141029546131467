import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_HOST } from '../../Api/url';
import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
} from '@mui/material';
import styled from 'styled-components';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import GroupsIcon from '@mui/icons-material/Groups';
import CancelIcon from '@mui/icons-material/Cancel';
import { InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const Text = styled.p`
font-weight:500;
text-align: center;
`
const TableHeader = styled.p`
font-weight:500;
text-align: center;
font-size: 16px;
padding: 5px;
margin: 0;
box-shadow: 0px 1px 2px black;
`

const CounterBox = styled.div`

margin-top:20px;
display: flex;
gap:20px;

`
const Total = styled.div`
justify-content: space-between;
width: 100%;
background-color: darkmagenta;
color: white;
display: flex;
padding: 10px;
border-radius: 8px;
box-shadow: 1px 1px 2px gray;
`
const ThisWeek = styled.div`
padding: 10px;

width: 100%;
background-color: green;
color: white;
display: flex;
border-radius: 8px;
box-shadow: 1px 1px 2px gray;
justify-content: space-between;

`
const ThisMonth = styled.div`
background-color: red;
padding: 10px;
width: 100%;
color: white;
display: flex;
border-radius: 8px;
box-shadow: 1px 1px 2px gray;
justify-content: space-between;
`
const CountIcon = styled.p`
width:35px;
height: 35px;
border-radius: 44px;
background-color:lightgray;
padding: 2px;
display: flex;
justify-content: center;
align-items: center;
box-sizing: content-box;
color: navy;
box-shadow: 1px 1px 2px gray;
`

const CountText = styled.p`

font-size: 18px;
font-weight:500;
text-align: center;
font-family: monospace;
`
const CountNumber = styled.p`
font-size: 22px;
font-weight:600;
text-align: center;
font-family: monospace;
`
const CountDiv = styled.div`

display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
gap:5px

`

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 4px 8px;
  max-width: 300px;
  width: 100%;
  border:1px solid gray;
  margin-top: 20px;
`;

const SearchInput = styled(InputBase)`
  margin-left: 8px;
  flex: 1;
`;


export function Users() {
    const [userList, setUserList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState({ month: 0, week: 0, total: 0 })
    const [searchText, setSearchText] = useState("")
    const getUsers = async () => {
        try {
            const response = await axios.get(
                `${API_HOST}/api/v1/admin/maangalbazaar-users`, {
                params: {
                    searchText: searchText
                }
            }
            );
            /* console.log(response.data) */
            const countOfUser = {
                month: response.data.month >= 0 ? response.data.month : 0,
                week: response.data.week >= 0 ? response.data.week : 0,
                total: response.data.maangalUsers.length >= 0 ? response.data.maangalUsers.length : 0
            }
            setCount({ ...countOfUser })
            setUserList(response.data.maangalUsers);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getUsers();
    }, [searchText]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '75vh',
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    return (
        <>

            <p>overview & summary</p>
            <CounterBox>
                <Total>
                    <CountDiv>
                        <CountText>Total Users</CountText>
                        <CountNumber>{count.total}</CountNumber>
                    </CountDiv>
                    <CountIcon>
                        <GroupsIcon />
                    </CountIcon>


                </Total>
                <ThisMonth>
                    <CountDiv>
                        <CountText>Monthly Users</CountText>
                        <CountNumber>{count.month}</CountNumber>
                    </CountDiv>
                    <CountIcon>
                        <PeopleAltIcon />
                    </CountIcon>


                </ThisMonth>
                <ThisWeek>
                    <CountDiv>
                        <CountText>Weekly Users</CountText>
                        <CountNumber>{count.week}</CountNumber>
                    </CountDiv>
                    <CountIcon>
                        <PersonIcon />
                    </CountIcon>


                </ThisWeek>
            </CounterBox>

            <SearchContainer>
                <SearchIcon />
                <SearchInput spellCheck={false} value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder="Search..." />
                {searchText.length>0? <CancelIcon style={{cursor:"pointer"}} onClick={()=>setSearchText("")}/>:null}
            </SearchContainer>
            {userList.length === 0 ? <div style={{marginTop:"50px",width:"100%",textAlign:"center"}}>No users available</div> : <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '20px' }}>
                <TableContainer>
                    <Table stickyHeader aria-label='sticky table'>
                        <TableHead>
                            <TableRow>
                                <TableCell><TableHeader>Full Name</TableHeader></TableCell>
                                <TableCell><TableHeader>Email</TableHeader></TableCell>
                                <TableCell><TableHeader>Contact</TableHeader></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userList
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((user, index) => (
                                    <TableRow
                                        key={user.userMail}
                                        sx={{
                                            backgroundColor: index % 2 === 0 ? '#f7f7f7' : 'white',
                                        }}
                                    >
                                        <TableCell><Text>{user.userFullName}</Text></TableCell>
                                        <TableCell><Text>{user.userMail}</Text></TableCell>
                                        <TableCell><Text>{user.userContact}</Text></TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component='div'
                    count={userList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            }
        </>
    );
};

