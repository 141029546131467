import React, { useContext, useEffect, useState } from "react";
import Card from "../listings/Card";
import { BoxStyles } from "../listings/Listings";
import { Wrapper } from "../listings/Listings";
import {
  ChangeMembershipContext,
  SearchQueryContext,
  TypeContext,
  CategoryContext,
} from "../../../App";
import axios from "axios";
import { API_HOST } from "../../Api/url";
import { Box, CircularProgress, Pagination, Alert, Snackbar } from "@mui/material";
import MembershipModal from "../membership/MembershipModal";
import LinearProgress from '@mui/material/LinearProgress';

const Basic = ({ isSearchClicked }) => {
  const [listings, setListings] = useState([]);
  const [isFetched, setIsFetched] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const { isVendor } = useContext(TypeContext);
  const { searchQuery } = useContext(SearchQueryContext);
  const [data, setData] = useState([]);
  const { ChangeMemberShipClicked, setChangeMemberShipClicked } = useContext(ChangeMembershipContext);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [totalPage, setTotalPage] = useState(1);
  const [tableLoader, setTableLoader] = useState(false)
  const { category, setCategory } = useContext(CategoryContext);
  const fetchListings = async (searchPage = 1) => {
    try {
      let response;
      if (isVendor) {
        if (isSearchClicked) {
          response = await axios.get(
            `${API_HOST}/api/v1/admin/premium-listings/vendors?premiumStatus=2&searchQuery=${searchQuery}&searchPage=${searchPage}`
          );
        } else {
          response = await axios.get(
            `${API_HOST}/api/v1/admin/premium-listings/vendors?premiumStatus=2&searchPage=${searchPage}&category=${category.value}`
          );
        }
      } else {
        if (isSearchClicked) {
          response = await axios.get(
            `${API_HOST}/api/v1/admin/premium-listings/venues?premiumStatus=2&searchQuery=${searchQuery}&searchPage=${searchPage}`
          );
        } else {
          response = await axios.get(
            `${API_HOST}/api/v1/admin/premium-listings/venues?premiumStatus=2&searchPage=${searchPage}`
          );
        }
      }
      setTotalPage(response.data.totalRecords)
      setListings(response.data.searchResults);
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetched(true);
      setTableLoader(false)
    }
  };

  useEffect(() => {
    setIsFetched(false);
    fetchListings();
  }, [isVendor, isSearchClicked, reloadData,category, ChangeMemberShipClicked]);

  const handleMembershipChange = () => {
    setReloadData(!reloadData);
  };

  const updateStatus = async (date, membership,updateStart) => {
    if (isVendor) {
      try {
        await axios.put(`${API_HOST}/api/v1/vendors/${data.vendorSlug}`, {
          vendorPremiumStatus: membership,
          vendorMetadata: [
            {
              propertyName: "VENDOR_PREMIUM_DATE",
              propertyType: "STRING",
              propertyValue: date.toISOString(),
            },
          ],
          updateStart:updateStart
        });
        setChangeMemberShipClicked(false);
        handleMembershipChange();
        setSnackbarMessage("Vendor membership changed successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage("An error occurred while changing membership");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } else {
      try {
        await axios.put(`${API_HOST}/api/v1/venues/${data.venueSlug}`, {
          venuePremiumStatus: membership,
          venueMetadata: [
            {
              propertyName: "VENUE_PREMIUM_DATE",
              propertyType: "STRING",
              propertyValue: date.toISOString(),
            },
          ],
          updateStart:updateStart
        });
        setChangeMemberShipClicked(false);
        handleMembershipChange();
        setSnackbarMessage("Venue membership changed successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage("An error occurred while changing membership");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  return (
    <Wrapper>
      {/* <MembershipModal updateStatus={updateStatus} /> */}
      {!isFetched ? (
        <Box sx={BoxStyles}>
          <CircularProgress />
        </Box>
      ) : listings.length === 0 ? (
        <p>No Basic {isVendor ? "Vendors" : "Venues"}</p>
      ) : (
        <div>
           <section style={{ marginBottom: "15px", display: "flex", flexDirction: "row-reverse", justifyContent: "space-between", alignItems: "center" }}>
            <Pagination siblingCount={1} onChange={(_, pageNumber) => {
              setTableLoader(true)
              fetchListings(pageNumber)

            }} variant="outlined" shape="rounded" count={Math.ceil(totalPage / 10)} color="primary" />
            <p>Total: {totalPage}</p>

          </section>
          <Box sx={{height:"5px", borderRadius: "20px", width: '100%' }}>
            {
              tableLoader ?
                <LinearProgress  />
                : null
            }
          </Box>
          {listings.map((listing, index) => {
            return (
              <Card
                key={`${listing}-${index}`}
                data={listing}
                isBasic={true}
                setData={setData}
              />
            );
          })}
        </div>

      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

export default Basic;
