import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Logo from '../../assets/logo.svg';
import { ReactComponent as Bagtick } from '../../assets/bagtick.svg';
import { tabList, tabList2, tabList3 } from './tabList';
import {
  ChangeMembershipContext,
  PendingContext,
  StatusContext,
  TypeContext,
  ViewContext,
} from '../../App';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { API_HOST } from '../Api/url';
import axios from 'axios';

const ListingWrapper = styled.div`
  width: 20%;
  background: #fff;
  height: 100vh;
  overflow: auto;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none !important;
  }
  padding: 0px 0 24px 0px;
`;

const StyledImage = styled.img`
  width: 140px;
  height: 53.694px;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  height: 132px;
  background: #fff;
`;

const TabWrapper = styled.div`
  padding: 20px 0px 0px 20px;
`;

const Tab = styled.p`
  text-transform: uppercase;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
`;

const Wrapper = styled.div`
  display: flex;
  padding: 12px 0px 12px 16px;
  cursor: pointer;
  color: ${(props) => (props.selected ? '#0B63F8' : '#000000')};
  border-left: ${(props) => (props.selected ? '2px solid #0B63F8' : 'none')};
`;

const TabsListing = ({ onTabChange }) => {
  const { isView } = useContext(ViewContext);
  const { ChangeMemberShipClicked } = useContext(ChangeMembershipContext);
  const { setIsPending } = useContext(PendingContext);
  const { tab, setTab } = useContext(StatusContext);
  const { isVendor } = useContext(TypeContext);
  const [selected, setSelected] = useState(
    !isView
      ? tabList.findIndex((obj) => obj.tab === 'listing')
      : tabList2.findIndex((obj) => obj.tab === 'Profile')
  );
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axios.post(`${API_HOST}/api/v1/auth/logout`);
      setIsPending(false);
      navigate('/login');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setSelected(
      !isView
        ? tabList.findIndex((obj) => obj.tab === 'listing')
        : tabList2.findIndex((obj) => obj.tab === 'Profile')
    );
  }, [isView]);

  const handleTabClick = (index) => {
    const currentTab = !isView ? tabList[index].tab : tabList2[index].tab;

    if (!isView) {
      if (
        currentTab === 'requests' ||
        currentTab === 'landing page' ||
        currentTab === 'membership'
      ) {
        return;
      }
    } else {
      if (
        currentTab === 'Portfolio' ||
        (isVendor && currentTab === 'locations')
      ) {
        return;
      }
    }

    setSelected(index);
    onTabChange(currentTab);
    setTab(currentTab);
  };

  return (
    <ListingWrapper>
      <LogoWrapper>
        <StyledImage src={Logo} alt={Logo} />
      </LogoWrapper>
      <TabWrapper>
        {(isView ? tabList2 : tabList).map((obj, index) => {
          if (isVendor && obj.tab === 'locations') {
            return null;
          }
          const Icon = obj.icon;
          return (
            <Wrapper
              key={`${obj.tab}-${index}`}
              selected={selected === index}
              onClick={() => handleTabClick(index)}
            >
              {Icon && (
                <>
                  {Icon === Bagtick ? (
                    <Bagtick
                      fill={selected === index ? '#0B63F8' : 'none'}
                      style={{ marginLeft: '-4px', marginRight: '16px' }}
                    />
                  ) : (
                    <Icon
                      sx={{
                        color: selected === index ? '#0B63F8' : 'gray',
                        marginRight: '4px',
                      }}
                    />
                  )}
                </>
              )}
              <Tab>{obj.tab}</Tab>
            </Wrapper>
          );
        })}
      </TabWrapper>
      {!isView && (
        <Button
          variant='contained'
          sx={{
            fontFamily: 'Poppins',
            textTransform: 'capitalize',
            width: '80%',
            margin: '20px',
          }}
          onClick={handleLogout}
        >
          Log Out
        </Button>
      )}
    </ListingWrapper>
  );
};

export default TabsListing;
