import React, { useContext, useEffect, useState } from 'react';
import Card from '../listings/Card';
import styled from 'styled-components';
import axios from 'axios';
import { API_HOST } from '../../Api/url';
import { CategoryContext, SearchQueryContext, TypeContext } from '../../../App';
import { Box, Pagination, CircularProgress } from '@mui/material';
import { BoxStyles } from '../listings/Listings';
import LinearProgress from '@mui/material/LinearProgress';

const Wrapper = styled.div`
  margin: 20px 0px 20px 0px;
`;

const RejectedList = ({ isSearchClicked }) => {
  const [listings, setListings] = useState([]);
  const [isFetched, setIsFetched] = useState(false);
  const { isVendor } = useContext(TypeContext);
  const [tickets, setTickets] = useState([]);
  const { searchQuery, setSearchQuery } = useContext(SearchQueryContext);
  const { category } = useContext(CategoryContext);
  const [totalPage, setTotalPage] = useState(1);
  const [tableLoader, setTableLoader] = useState(false)

  const fetchTickets = async (vendorSlug, venueSlug) => {
    try {
      let response;
      if (isVendor) {
        response = await axios.get(
          `${API_HOST}/api/v1/vendors/${vendorSlug}/listing-status?listingStatus=REJECTED`
        );
      } else {
        response = await axios.get(
          `${API_HOST}/api/v1/venues/${venueSlug}/listing-status?listingStatus=REJECTED`
        );
      }
      setTickets(response.data.listingTickets);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    listings.forEach((item) => {
      const vendorSlug = item.vendorSlug;
      const venueSlug = item.venueSlug;
      fetchTickets(vendorSlug, venueSlug);
    });
  }, [listings]);

  const fetchListings = async (searchPage = 1) => {
    try {
      let response;
      if (isVendor) {
        if (isSearchClicked) {
          response = await axios.post(
            `${API_HOST}/api/v1/admin/listings/vendors?searchQuery=${searchQuery}&searchPage=${searchPage}`,
            { vendorListingStatus: 'REJECTED', vendorCategory: category.value }
          );
        } else {
          response = await axios.post(
            `${API_HOST}/api/v1/admin/listings/vendors?searchPage=${searchPage}`,
            { vendorListingStatus: 'REJECTED', vendorCategory: category.value }
          );
        }
      } else {
        if (isSearchClicked) {
          response = await axios.post(
            `${API_HOST}/api/v1/admin/listings/venues?searchQuery=${searchQuery}&searchPage=${searchPage}`,
            { venueListingStatus: 'REJECTED' }
          );
        } else {
          response = await axios.post(
            `${API_HOST}/api/v1/admin/listings/venues?searchPage=${searchPage}`,
            { venueListingStatus: 'REJECTED' }
          );
        }
      }
      setTotalPage(response.data.totalRecords)
      setListings(response.data.searchResults);

    } catch (error) {
      console.log(error);
    } finally {
      setIsFetched(true);
      setTableLoader(false)
    }
  };

  useEffect(() => {
    setIsFetched(false);
    fetchListings();
  }, [isVendor, isSearchClicked, category]);

  return (
    <Wrapper>
      {!isFetched ? (
        <Box sx={BoxStyles}>
          <CircularProgress />
        </Box>
      ) : listings.length === 0 ? (
        <p>No Rejected {isVendor ? 'Vendors' : 'Venues'}</p>
      ) : (
        <div>
          <section style={{ marginBottom: "15px", display: "flex", flexDirction: "row-reverse", justifyContent: "space-between", alignItems: "center" }}>
            <Pagination siblingCount={1} onChange={(_, pageNumber) => {
              setTableLoader(true)
              fetchListings(pageNumber)

            }} variant="outlined" shape="rounded" count={Math.ceil(totalPage / 10)} color="primary" />
            <p>Total: {totalPage}</p>

          </section>
          <Box sx={{ height: "5px", borderRadius: "20px", width: '100%' }}>
            {
              tableLoader ?
                <LinearProgress />
                : null
            }
          </Box>
          {listings.map((item, index) => {
            return (
              <Card
                key={`${item}-${index}`}
                data={item}
                ticket={tickets[index]}
                isRejected={true}
              />
            );
          })}
        </div>
      )}
    </Wrapper>
  );
};

export default RejectedList;
