import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Container,
  Divider,
  Link as MaterialLink,
  Typography,
} from '@mui/material';

import LoginForm from './LoginForm';
import { useContext, useEffect } from 'react';
import AuthContext from './authContext';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function LoginPage() {
  const authCtx = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    const { authStatus } = authCtx;
    if (authStatus === 'AUTHENTICATED') {
      navigate('/');
    }
  }, [authCtx, navigate]);

  return (
    <>
      <StyledRoot>
        <Container maxWidth='sm'>
          <StyledContent>
            <Typography variant='h4' gutterBottom>
              Sign in
            </Typography>
            {/* <Typography variant='body2'>
              Don’t have an account? {''}
              <MaterialLink variant={'subtitle2'}>
                <Link to={'/signup'}>Create one</Link>
              </MaterialLink>
            </Typography> */}
            <Divider sx={{ my: 2 }} />
            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
