import { Grid } from "@mui/material";
import React, { useState } from "react";
import Card from "./Card";
import styled from "styled-components";
import axios from "axios";
import { API_HOST } from "../../Api/url";
import { useEffect } from "react";
import { Alert, Snackbar ,Box} from "@mui/material";
import { makeAPIRequest, manageMedia } from "../../../utility/mediaHandler";
import LinearProgress from '@mui/material/LinearProgress';

const Wrapper = styled.div`
  margin: 20px 0px 20px 0px;
`;

const StyledNo = styled.p`
  margin: 12px 0px;
  font-size: 18px;
  font-weight: 500;
  font-family: Poppins;
`;

const TopCategories = () => {
  const [data, setData] = useState([]);
  const [backDropLoader, setBackDropLoader] = useState(true)
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const fetchData = async () => {
    try {
      setBackDropLoader(true)
      const response = await axios.get(
        `${API_HOST}/api/v1/meta/top-vendor-categories`
      );
      // console.log("new data",response.data.topCategories)
      // console.log(response.data.topCategories)
      setData(response.data.topCategories);
    } catch (error) {
      console.log(error);
    }finally{
      setBackDropLoader(false)
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClick = async (
    mainData,
    selectedFile,
    title,
    id,
    description,
    categoryCanonicalName
  ) => {
    
    setBackDropLoader(true)
    await manageMedia({
      mediaFiles: [selectedFile],
      fileMethod: "PUT",
      objectKeyGenFn: (fileHandle, fileIdx, fileMethod) => {
        return `media/top-vendors/${title}/${id}`;
      },
    });

    let objectkey = `media/top-vendors/${title}/${id}`;
    let coverImagekey = `media/top-vendors/${title}/${id}`
    if (!selectedFile) {
      objectkey = mainData.ImageobjectKey;
      coverImagekey = mainData.ImageobjectKey;
    }

    let result=await makeAPIRequest({
      requestEndpoint: "/meta/top-vendor-categories",
      requestMethod: "POST",
      bodyParams: {
        categoryCoverImageObjectKey: coverImagekey,
        categoryName: title,
        categoryDescription: description,
        categoryIndex: id,
        categoryCanonicalName: categoryCanonicalName,
        ImageobjectKey: objectkey,
      },
    });

    if(!result.isSuccess){
      fetchData();
      
      setSnackbarMessage("Fail To Update Top wedding categoriesy");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }else{
    if (fetchData) {
      fetchData();
      
      setSnackbarMessage("Top wedding categories updated successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    }
  }
  };

  return (
    <Wrapper>
     <Box sx={{height:"5px", borderRadius: "20px", width: '100%' }}>
       {backDropLoader?<LinearProgress />:null}
     </Box>
    
      <Grid container spacing={2}>
        {data &&
          data.map((item, index) => {
            return (
              <Grid item xs={6} key={`${item}-${index}`}>
                <StyledNo>Category {index + 1}</StyledNo>
                <Card
                  data={item}
                  onUploadSuccess={fetchData}
                  id={index}
                  handleClick={handleClick}
                />
              </Grid>
            );
          })}
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

export default TopCategories;
