import React, { createContext, useEffect, useState } from 'react';
import './App.css';
import Root from './components/Root';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import LoginPage from './components/Login/LoginPage';
// import ProfileContext from './components/Login/profileContext';
import AuthContext from './components/Login/authContext';
import { makeAPIRequest } from './utility/mediaHandler';

export const ViewContext = createContext();
export const TypeContext = createContext();
export const DataContext = createContext();
export const ChangeMembershipContext = createContext();
export const StatusContext = createContext();
export const SearchQueryContext = createContext();
export const CategoryContext = createContext();
export const PendingContext = createContext();
export const ListingContext = createContext();

function App() {
  const [isView, setIsView] = useState(false);
  const [isVendor, setIsVendor] = useState(false);
  const [ChangeMemberShipClicked, setChangeMemberShipClicked] = useState(false);
  const [category, setCategory] = useState('');
  const [isPending, setIsPending] = useState('false');
  const [listingTickets, setListingTickets] = useState([]);
  // const [profileState, setProfileState] = useState(null);
  const [tab, setTab] = useState('');
  const [authState, setAuthState] = useState({
    authStatus: 'UNAUTHENTICATED',
  });
  // const [profileFetchComplete, setProfileFetchComplete] = useState(false);
  const [vendorData, setVendorData] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const authRequest = async () => {
      const { isSuccess, isError, responseCode, requestError, responseData } =
        await makeAPIRequest({
          requestEndpoint: '/auth/refresh',
          requestMethod: 'POST',
        });

      if (isSuccess) {
        const { responseStatus } = responseData;
        if (responseStatus === 'SUCCESS') {
          const { authStatus } = responseData;
          if (authStatus === 'AUTHENTICATED') {
            const { authData } = responseData;
            setAuthState({
              authData: authData,
              authStatus: authStatus,
            });
          } else {
            setAuthState({
              authStatus: 'UNAUTHENTICATED',
            });
          }
        } else {
          setAuthState({
            authStatus: 'UNAUTHENTICATED',
          });
        }
      }
    };
    authRequest().catch((err) => {
      console.error(err);
      setAuthState({
        authStatus: 'UNAUTHENTICATED',
      });
    });
  }, [location.pathname]);

  useEffect(() => {
    const { authStatus } = authState;
    if (authStatus === 'UNAUTHENTICATED') {
      navigate('/login');
      return;
    }
    if (authStatus === 'AUTHENTICATED') {
      const {
        authData: { userType },
      } = authState;

      if (userType !== 'ADMIN') {
        setAuthState({
          authStatus: 'UNAUTHENTICATED',
        });
        navigate('/login');
      }
    }
  }, [authState, navigate, setAuthState]);

  return (
    <div className='app'>
      <AuthContext.Provider
        value={{
          ...authState,
          setAuthValue: setAuthState,
        }}
      >
        <ListingContext.Provider value={{ listingTickets, setListingTickets }}>
          <PendingContext.Provider value={{ isPending, setIsPending }}>
            <ViewContext.Provider value={{ isView, setIsView }}>
              <TypeContext.Provider value={{ isVendor, setIsVendor }}>
                <DataContext.Provider value={{ vendorData, setVendorData }}>
                  <ChangeMembershipContext.Provider
                    value={{
                      ChangeMemberShipClicked,
                      setChangeMemberShipClicked,
                    }}
                  >
                    <StatusContext.Provider value={{ tab, setTab }}>
                      <SearchQueryContext.Provider
                        value={{ searchQuery, setSearchQuery }}
                      >
                        <CategoryContext.Provider
                          value={{ category, setCategory }}
                        >
                          <Routes>
                            <Route exact path='/' element={<Root />} />
                            <Route path='/login' element={<LoginPage />} />
                          </Routes>
                        </CategoryContext.Provider>
                      </SearchQueryContext.Provider>
                    </StatusContext.Provider>
                  </ChangeMembershipContext.Provider>
                </DataContext.Provider>
              </TypeContext.Provider>
            </ViewContext.Provider>
          </PendingContext.Provider>
        </ListingContext.Provider>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
