import {
  useServiceRender,
  useValueSelector,
} from "../features/questionnaire/hook";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { DataContext, ListingContext, PendingContext } from "../../../App";
import axios from "axios";
import { API_HOST } from "../../Api/url";

export default function LocationsPage() {
  const { isPending } = useContext(PendingContext);
  const { listingTickets, setListingTickets } = useContext(ListingContext);
  const [locationData, setLocationData] = useValueSelector({
    defaultValue: () => {
      return [];
    },
    venueValue: (venueData) => {
      return venueData.venueLocations;
    },
    vendorValue: (vendorData) => {
      return [];
    },
    updateVenueValue: (prevValue, partialValue) => {
      return {
        ...prevValue,
        venueLocations: partialValue,
      };
    },
    updateVendorValue: (prevValue, partialValue) => {
      return prevValue;
    },
  });
  const { vendorData } = useContext(DataContext);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const pageContent = useServiceRender({
    fallbackElement: null,
    vendorElement: null,
    venueElement: (
      <Stack spacing={2}>
        {listingTickets &&
          isPending &&
          listingTickets.some((item) => {
            return item.updatedFields.includes("venueLocations");
          }) && <p>(Updated)</p>}
        <Typography>
          <ul>
            <li>
              A venue type is a banquet hall, wedding hall, or common area fit
              for gatherings
            </li>
            <li>
              Select the type of venue and enter the name, approximate seated
              and floating capacity for each
            </li>
          </ul>
        </Typography>
        <Stack
          direction={"row"}
          sx={{
            justifyContent: "end",
          }}
        >
          <Button
            variant={"contained"}
            onClick={() => {
              setLocationData([
                ...locationData,
                {
                  locationType: "HALL",
                  locationName: "",
                  locationCapacitySeated: 0,
                  locationCapacityFloating: 0,
                  locationEnvironment: "INDOORS",
                },
              ]);
            }}
          >
            Add venue
          </Button>
        </Stack>
        <Divider sx={{ py: 0 }} />
        <Grid container spacing={1}>
          {locationData.map((locationObj, locationIdx) => {
            const {
              locationType,
              locationCapacityFloating,
              locationCapacitySeated,
              locationName,
              locationEnvironment,
            } = locationObj;

            const setCurrentLocationData = (locationDat) => {
              const beforeData = locationData.slice(0, locationIdx);
              const afterData = locationData.slice(locationIdx + 1);

              setLocationData([...beforeData, locationDat, ...afterData]);
            };

            return (
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    padding: 2,
                    borderRadius: 2,
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant={"h6"}>
                        {`Venue #${locationIdx + 1}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ py: 0 }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <>
                        <Typography>Venue Name</Typography>
                        <TextField
                          fullWidth
                          value={locationName}
                          placeholder={"Venue Name"}
                          onChange={(e) => {
                            setCurrentLocationData({
                              ...locationObj,
                              locationName: e.target.value,
                            });
                          }}
                        />
                      </>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <>
                        <Typography>Venue Type</Typography>
                        <Select
                          fullWidth
                          value={locationType}
                          onChange={(e) => {
                            setCurrentLocationData({
                              ...locationObj,
                              // @ts-ignore
                              locationType: e.target.value,
                            });
                          }}
                        >
                          {/* <MenuItem value={'HALL'}>Hall</MenuItem>
                          <MenuItem value={'LAWN'}>Hotel</MenuItem> */}
                          <MenuItem value={"LAWN"}>Lawn</MenuItem>
                          <MenuItem value={"BANQUET"}>Banquet Hall</MenuItem>
                          <MenuItem value={"RESORT"}>Resort</MenuItem>
                          <MenuItem value={"SMALL_FUNCTION_LOCATION"}>
                            Small Function Location
                          </MenuItem>
                          {/* <MenuItem value={'KALYANA_MANDAPAM'}>
                            Kalyana Mandapam
                          </MenuItem> */}
                          <MenuItem value={"EXOTIC_HOMESTAY"}>
                            Exotic Homestay
                          </MenuItem>
                          <MenuItem value={"4_STAR_PLUS_HOTEL"}>
                            4-Star+ Hotel
                          </MenuItem>
                          <MenuItem value={"DESTINATION_WEDDING"}>
                            Destination Wedding
                          </MenuItem>
                        </Select>
                      </>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <>
                        <Typography>Venue Capacity (Seated)</Typography>
                        <TextField
                          fullWidth
                          value={locationCapacitySeated}
                          placeholder={"Venue Capacity (Seated)"}
                          onChange={(e) => {
                            const parsedValue = Number.parseInt(e.target.value);

                            if (Number.isNaN(parsedValue)) return;

                            setCurrentLocationData({
                              ...locationObj,
                              locationCapacitySeated: parsedValue,
                            });
                          }}
                        />
                      </>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <>
                        <Typography>Venue Capacity (Floating)</Typography>
                        <TextField
                          fullWidth
                          value={locationCapacityFloating}
                          placeholder={"Venue Capacity (Floating)"}
                          onChange={(e) => {
                            const parsedValue = Number.parseInt(e.target.value);

                            if (Number.isNaN(parsedValue)) return;

                            setCurrentLocationData({
                              ...locationObj,
                              locationCapacityFloating: parsedValue,
                            });
                          }}
                        />
                      </>
                    </Grid>
                    <Grid item xs={12}>
                      <>
                        <Typography>Venue Environment</Typography>
                        <RadioGroup
                          row
                          value={locationEnvironment}
                          onChange={(e) => {
                            setCurrentLocationData({
                              ...locationObj,
                              locationEnvironment: e.target.value,
                            });
                          }}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label={"Indoor Venue"}
                            value={"INDOORS"}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label={"Outdoor Venue"}
                            value={"OUTDOORS"}
                          />
                        </RadioGroup>
                      </>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant={"contained"}
                        color={"error"}
                        fullWidth
                        onClick={() => {
                          const prevLocations = locationData.slice(
                            0,
                            locationIdx
                          );
                          const nextLocations = locationData.slice(
                            locationIdx + 1
                          );

                          setLocationData([...prevLocations, ...nextLocations]);
                        }}
                      >
                        Delete Venue
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    ),
  });

  const uploadData = async () => {
    try {
      await axios.put(`${API_HOST}/api/v1/venues/${vendorData.venueSlug}`, {
        venueLocations: locationData,
      });
      setSnackbarMessage("Venue locations changed successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("An error occurred while changing profile");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <div>{pageContent}</div>
      <Button
        variant="contained"
        sx={{
          fontFamily: "Poppins",
          textTransform: "capitalize",
          marginTop: "20px",
          width: "100%",
        }}
        disabled={locationData.length === 0}
        onClick={uploadData}
      >
        {isPending &&vendorData?.premiumStartingDate?"Approve & Save " : "Save"}
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
