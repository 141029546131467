import { Fragment, useContext, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { makeAPIRequest } from "../../../utility/mediaHandler";
import { BoolSelectQuestion } from "./questionnaire/BoolSelect";
import { NumericSelectQuestion } from "./questionnaire/NumericSelect";
import { TextSelectQuestion } from "./questionnaire/TextSelect";
import { NumericInputQuestion } from "./questionnaire/NumericInput";
import { TextInputQuestion } from "./questionnaire/TextInput";
import { TextMultipleQuestion } from "./questionnaire/TextMultiple";
import { NumericMultipleQuestion } from "./questionnaire/NumericMultiple";
import { BitFieldQuestion } from "./questionnaire/BitField";
import { ServiceTypes } from "./questionnaire/ServiceTypes";
import { CommonQuestions } from "./questionnaire/CommonQuestions";
import { DataContext, TypeContext } from "../../../App";
import { useValueSelector } from "./questionnaire/hook";
import { zipMetadata } from "../../../utility/schemaMetadata";

function VendorQuestion(props) {
  const {
    questionType,
    vendorMetadataType,
    vendorMetadataDependencies,
    vendorMetadataKey,
    vendorMetadataDefaultValue,
    questionText,
    questionOptions,
  } = props;

  const [zipComplete, setZipComplete] = useState(false);

  const [vendorMetadata, setVendorMetadata] = useValueSelector({
    defaultValue: () => {
      return [];
    },
    vendorValue: (vendorData) => {
      return vendorData.vendorMetadata;
    },
    venueValue: () => {
      return [];
    },
    updateVendorValue: (prevValue, partialValue) => {
      return {
        ...prevValue,
        vendorMetadata: zipMetadata(prevValue.vendorMetadata, partialValue),
      };
    },
    updateVenueValue: (prevValue, partialValue) => {
      return prevValue;
    },
  });

  useEffect(() => {
    const zippedMetadata = [
      // @ts-ignore
      {
        propertyName: vendorMetadataKey,
        propertyType: vendorMetadataType,
        propertyValue: vendorMetadataDefaultValue,
      },
      ...vendorMetadata,
      // Will overwrite with existing value
    ];
    // @ts-ignore
    setVendorMetadata(zippedMetadata);
    setZipComplete(true);
  }, []);

  if (!zipComplete) {
    return null;
  }

  const metadataDependenciesMet = vendorMetadata.every((metadataObj) => {
    const { propertyName, propertyValue } = metadataObj;
    const isDependency = vendorMetadataDependencies.some((value) => {
      const { dependencyKey } = value;
      if (dependencyKey === propertyName) {
        return true;
      }
      return false;
    });
    if (!isDependency) {
      return true;
      // Non-deps are assumed true
    }
    const dependencyState = vendorMetadataDependencies.find((dependencyObj) => {
      return dependencyObj.dependencyKey === propertyName;
    });
    if (dependencyState === undefined) {
      return false;
    }
    return dependencyState.dependencyValue === propertyValue;
  });

  if (!metadataDependenciesMet) {
    return null;
  }

  if (questionType === "BOOL_SELECT") {
    return (
      <>
        <BoolSelectQuestion
          key={vendorMetadataKey}
          questionType={"BOOL_SELECT"}
          vendorMetadataType={"BOOLEAN"}
          vendorMetadataKey={vendorMetadataKey}
          vendorMetadataDefaultValue={vendorMetadataDefaultValue}
          questionOptions={questionOptions}
          questionText={questionText}
          vendorMetadataDependencies={vendorMetadataDependencies}
        />
        {/* <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid> */}
      </>
    );
  }

  if (questionType === "NUMERIC_SELECT") {
    return (
      <>
        <NumericSelectQuestion
          key={vendorMetadataKey}
          questionText={questionText}
          vendorMetadataKey={vendorMetadataKey}
          vendorMetadataDependencies={vendorMetadataDependencies}
          questionOptions={questionOptions}
          questionType={"NUMERIC_SELECT"}
          vendorMetadataType={"NUMBER"}
          vendorMetadataDefaultValue={vendorMetadataDefaultValue}
        />
        {/* <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid> */}
      </>
    );
  }

  if (questionType === "TEXT_SELECT") {
    return (
      <>
        <TextSelectQuestion
          key={vendorMetadataKey}
          questionText={questionText}
          vendorMetadataKey={vendorMetadataKey}
          vendorMetadataDependencies={vendorMetadataDependencies}
          questionOptions={questionOptions}
          questionType={"TEXT_SELECT"}
          vendorMetadataType={"STRING"}
          vendorMetadataDefaultValue={vendorMetadataDefaultValue}
        />
        {/* <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid> */}
      </>
    );
  }

  if (questionType === "NUMERIC_INPUT") {
    return (
      <>
        <NumericInputQuestion
          key={vendorMetadataKey}
          questionText={questionText}
          vendorMetadataKey={vendorMetadataKey}
          vendorMetadataDependencies={vendorMetadataDependencies}
          questionOptions={[]}
          questionType={"NUMERIC_INPUT"}
          vendorMetadataType={"NUMBER"}
          vendorMetadataDefaultValue={vendorMetadataDefaultValue}
        />
        {/* <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid> */}
      </>
    );
  }

  if (questionType === "TEXT_INPUT") {
    return (
      <>
        <TextInputQuestion
          key={vendorMetadataKey}
          questionText={questionText}
          vendorMetadataKey={vendorMetadataKey}
          vendorMetadataDependencies={vendorMetadataDependencies}
          questionOptions={[]}
          questionType={"TEXT_INPUT"}
          vendorMetadataType={"STRING"}
          vendorMetadataDefaultValue={vendorMetadataDefaultValue}
        />
        {/* <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid> */}
      </>
    );
  }

  if (questionType === "TEXT_MULTIPLE") {
    return (
      <>
        <TextMultipleQuestion
          key={vendorMetadataKey}
          questionText={questionText}
          vendorMetadataKey={vendorMetadataKey}
          vendorMetadataDependencies={vendorMetadataDependencies}
          questionOptions={[]}
          questionType={"TEXT_MULTIPLE"}
          vendorMetadataType={"ARRAY_STRING"}
          vendorMetadataDefaultValue={vendorMetadataDefaultValue}
        />
        {/* <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid> */}
      </>
    );
  }

  if (questionType === "NUMERIC_MULTIPLE") {
    return (
      <>
        <NumericMultipleQuestion
          key={vendorMetadataKey}
          questionText={questionText}
          vendorMetadataKey={vendorMetadataKey}
          vendorMetadataDependencies={vendorMetadataDependencies}
          questionOptions={[]}
          questionType={"NUMERIC_MULTIPLE"}
          vendorMetadataType={"ARRAY_NUMBER"}
          vendorMetadataDefaultValue={vendorMetadataDefaultValue}
        />
        {/* <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid> */}
      </>
    );
  }

  if (questionType === "BITFIELD") {
    return (
      <>
        <BitFieldQuestion
          questionText={questionText}
          vendorMetadataKey={vendorMetadataKey}
          vendorMetadataDependencies={vendorMetadataDependencies}
          questionOptions={questionOptions}
          questionType={"BITFIELD"}
          vendorMetadataType={"NUMBER"}
          vendorMetadataDefaultValue={vendorMetadataDefaultValue}
        />
        {/* <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid> */}
      </>
    );
  }

  return null;
}

export function VendorQuestionnaire({ setSelectedTab }) {
  const { isVendor } = useContext(TypeContext);
  const { vendorData } = useContext(DataContext);

  const [vendorQuestions, setVendorQuestions] = useState([]);

  useEffect(() => {
    if (!isVendor) {
      return;
    }

    const fetchQuestions = async () => {
      const { isSuccess, isError, requestError, responseData, responseCode } =
        await makeAPIRequest({
          requestMethod: "GET",
          requestEndpoint: "/dashboard/vendor-questionnaire",
          queryParams: {
            vendorCategory: vendorData.vendorCategory,
            vendorSubcategory: vendorData.vendorSubcategory,
          },
        });

      if (isSuccess) {
        const { responseStatus } = responseData;
        console.log("responseData", responseData);
        if (responseStatus === "SUCCESS") {
          const { vendorQuestions: responseQuestions } = responseData;
          setVendorQuestions(responseQuestions);
        }
      }
    };

    fetchQuestions();
  }, []);

  if (!isVendor) {
    return null;
  }
// console.log(vendorQuestions,"vendor question kay  ha dhek lo")
  return (
    <Box sx={{ fontFamily: "Poppins", flexGrow: 1 }}>
      <Grid container spacing={2} sx={{ alignItems: "center" }}>
        <ServiceTypes  />
        {vendorQuestions.map((questionData, questionIdx) => {
          return (
            <Fragment
              key={`${questionIdx}-${questionData.vendorMetadataKey}-group`}
            >
              <VendorQuestion
                key={`${questionIdx}-${questionData.vendorMetadataKey}`}
                {...questionData}
              />
            </Fragment>
          );
        })}
        <CommonQuestions setSelectedTab={setSelectedTab} />
      </Grid>
    </Box>
  );
}
