import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import CancelIcon from '@mui/icons-material/Cancel';
import { API_HOST } from '../../Api/url';
import axios from 'axios';

import {
    Box, CircularProgress, Alert, Snackbar, TextField,
    Typography,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
const BoxStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "17vh",
};
const BoxStyles2 = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "17vh",
};

const ShowSavePlanModelBox = styled.div`
z-index: 999;
position: absolute;
top: 0;
left: 0;
display: flex;
justify-content: center;
align-items: center;
width: 100vw;
height:100vh;
max-height:100vh;
background-color: #68686872;

overflow-y: scroll;
`;
const MainLoaderBoxBox = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 99;
background-color: #68686872;
display: flex;
justify-content: center;
align-items: center;
`;
const ButtonSection = styled.div`
margin-top: 5px;
display: flex;
justify-content: center;
align-items: center;
gap: 15px;
padding: 2% 5%;
background-color: white;
bottom: -10px;
width: 100%;
left: 0;
@media (max-width:480px) {
    flex-wrap: wrap;
}

`
const ButtonSectionButton1 = styled.button`
width: 80%;
background-color: green;
color: white;
padding: 5px;
cursor: pointer;
border-radius: 5px;
font-weight: 500;
font-size: 16px;

`

const ButtonSectionButton2 = styled.button`
width: 80%;
background-color: skyblue;
color: black;
padding: 5px;
cursor: pointer;
border-radius: 5px;
font-weight: 500;
font-size: 16px;
`
const PageHeading = styled.h2`
background-color: white;
text-align: center;
padding: 5px 10px;
margin-top: 5px;
font-size: 18px;
font-weight: 600;

`
const RemaingingSection = styled.div`
width: 100%;
background-color: white;
padding: 5px;
transition: all 0.2s ease-in-out;

`
const CalcuratedSection = styled.div`
width: 100%;
height: 30px;
padding: 0 5px;
font-size: 14px;
display: flex;
justify-content: space-between;
align-self: center;
`;
const ShowPrice = styled.div`
text-align: center;
margin: 5px 0px;
padding: 5px;
display: flex;
border:1px dashed gray;
color: green;
justify-content: space-between;
align-items: center;

`

const ShowEPrice = styled.div`
text-align: center;
margin: 5px 0px;
padding: 5px;
display: flex;
color: green;
justify-content: space-between;
align-items: center;

`
const Label = styled.p`
width: 100%;
color:  gray;
text-align: start;
font-size: 14px;
`

const InputEp = styled.input`
outline: none;
border:1px solid gray;
padding: 8px 8px;
font-size: 16px;
`
const ShowTextArea = styled.div`
text-align: center;
margin: 5px 0px;
padding: 5px;
justify-content: space-between;
align-items: center;

`
const TextAreaArea = styled.textarea`
width: 100%;
min-height: 60px;
max-height: 60px;
resize: none;
border:1px solid gray;
outline: none;
border-radius: 5px;
font-size: 12px;
color: gray;
padding: 8px;

`
export default function ShowSavePlanModel({ showSaveAndPlanDecide, setShowSaveAndDecidePlanModel, isVendor, handleApprove, venueVendorSlug, setVenueVendorSlug, approvedDone, setApprovedDone }) {
    const [plan, setPlans] = useState([]);
    const [mainLoader, setMainLoader] = useState(true)
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [choosePlan, setChoosePlan] = useState('');
    const [effectiveAmount, setEffectiveAmount] = useState('')
    const [amountReceive, SetAmountReceive] = useState('0')
    const [membershipDate, setMembershipDate] = useState("2024-07-20");
    const [submitiionLoader, setSubmittionLoader] = useState(false)
    const [comments, setComments] = useState("")

    async function submitApprovedData() {
        let flag = true;
        let message = ""
        if (!choosePlan) {
            message += message.length > 0 ? ", Plan Type" : "Plan Type"
        }
        const regex = /^[0-9]+$/
        if (!regex.test(effectiveAmount)) {
            message += message.length > 0 ? ", Final Price" : "Final Price"
        }
        if (!membershipDate) {
            message += message.length > 0 ? ", Membership Date" : "Membership Date"
        }
        if (message.length > 0) {
            flag = false;
            setSnackbarMessage(`${message} missing. Complete them`);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
        if (flag && venueVendorSlug) {
            try {
                setSubmittionLoader(true)
                const body = {
                    choosePlan,
                    effectiveAmount,
                    amountReceive,
                    membershipDate,
                    comments
                }

                if (isVendor) {
                    await axios.post(
                        `${API_HOST}/api/v1/admin/listings/vendors/${venueVendorSlug}/listing-status-with-plan`,
                        body
                    );
                } else {
                    await axios.post(
                        `${API_HOST}/api/v1/admin/listings/venues/${venueVendorSlug}/listing-status-with-plan`,
                        body
                    );
                }
                setSnackbarMessage('Successfully Updated');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setApprovedDone(!approvedDone)
                setShowSaveAndDecidePlanModel(false)
                setSubmittionLoader(false)
            } catch (error) {
                setSnackbarMessage('An error occurred , Please try later');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                setSubmittionLoader(false)
            }
        }
    }
    const handleChange = (event) => {

        const value = plan.find((data, index) => data._id == event.target.value).amount
        if (value) {
            setEffectiveAmount(value)
            setChoosePlan(event.target.value);
        }
    };

    const handleMembershipDateChange = (event) => {
        const originalDate = new Date(event.target.value);
        const currentDate = new Date();
        const formattedDate = new Date(originalDate);
        if (formattedDate < currentDate) {

        } else {
            setMembershipDate(event.target.value);
        }
    };
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };
    async function FetchPlanPricingData() {
        try {
            const response = await axios.get(`${API_HOST}/api/v1/admin/getplanpricing`)
            if (Array.isArray(response?.data?.response)) {
                setPlans(response.data.response)
                setMainLoader(false)
            }
        } catch (error) {
            setSnackbarMessage('An error occurred , Please try later');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    }
    useEffect(() => {
        FetchPlanPricingData()
        const date = new Date();
        const year = date.getFullYear() + 1;
        const month = date.getMonth()+1;
        const updatedDate = date.getDate();

        
        setMembershipDate(`${year}-${month < 10 ? `0${month}` : month}-${updatedDate < 10 ? `0${updatedDate}` : updatedDate}`)
    }, [])

    return <ShowSavePlanModelBox>

        <CancelIcon onClick={() => {
            if (!submitiionLoader) {
                setShowSaveAndDecidePlanModel(false); setVenueVendorSlug("")
            }
        }} style={{ position: "absolute", right: "10px", top: "10px", zIndex: "9999", cursor: "pointer", fontSize: "45px" }}></CancelIcon>
        <div style={{ background: "white", position: "relative", width: "450px", padding: "2%" }}>
            {submitiionLoader && <MainLoaderBoxBox>
                <Box sx={BoxStyles2}>
                    <CircularProgress />
                </Box>
            </MainLoaderBoxBox>}
            <PageHeading>Configure Vendor/Venue Service Membership  </PageHeading>
            <RemaingingSection>


                {mainLoader ? <Box sx={BoxStyles}>
                    <CircularProgress />
                </Box> : <RemaingingSection>
                    <CalcuratedSection>
                        <p>
                            {
                                choosePlan ? `${effectiveAmount - amountReceive} Remaining` : ""
                            }
                        </p>
                        <p>
                            {
                                choosePlan ? effectiveAmount <= plan.find((data, index) => data._id == choosePlan).amount ? `${((plan.find((data, index) => data._id == choosePlan).amount - effectiveAmount) / plan.find((data, index) => data._id == choosePlan).amount) * 100}% Discount` : `Rs ${(effectiveAmount - plan.find((data, index) => data._id == choosePlan).amount)}+ Gain` : ""
                            }
                        </p>

                    </CalcuratedSection>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Choose Membership</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={choosePlan}
                            label="Chose Membership"
                            onChange={handleChange}
                        >
                            {
                                plan.map((data, index) => {
                                    return <MenuItem key={index} value={data._id}>{data.label}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                    {choosePlan && plan.find((data, index) => data._id == choosePlan) && <ShowPrice>
                        <Label>Membership Pricing</Label>
                        {
                            plan.find((data, index) => data._id == choosePlan).amount
                        }
                    </ShowPrice>}

                    {choosePlan && plan.find((data, index) => data._id == choosePlan) && <ShowEPrice>
                        <Label>Final Pricing</Label>
                        <InputEp value={effectiveAmount} onChange={(e) => {
                            const regex = /^[0-9]+$/
                            if (!choosePlan) {
                                return
                            } else {
                                let find = plan.find((data, index) => data._id == choosePlan)
                                if (find && !find?.canbeModified) {
                                    setSnackbarMessage('Modification In final Pricing Not Allowed');
                                    setSnackbarSeverity('error');
                                    setSnackbarOpen(true);
                                    return
                                }
                            }
                            if (e.target.value.length > 0 && e.target.value[0] == 0) {
                                setEffectiveAmount("0")
                            }
                            else if ((regex.test(e.target.value) && Number(e.target.value) <= 0)) {
                                setEffectiveAmount("0")
                            }
                            else if (!e.target.value) {

                                setEffectiveAmount("")

                            } else {
                                if (regex.test(e.target.value)) {
                                    setEffectiveAmount(e.target.value)
                                }
                            }
                        }} />
                    </ShowEPrice>}

                    {choosePlan && plan.find((data, index) => data._id == choosePlan) && <ShowEPrice>
                        <Label>Amount Receive</Label>
                        <InputEp value={amountReceive} onChange={(e) => {
                            const regex = /^[0-9]+$/

                            if (e.target.value.length > 0 && e.target.value[0] == 0) {
                                SetAmountReceive("0")
                            }
                            else if ((regex.test(e.target.value) && Number(e.target.value) <= 0)) {
                                SetAmountReceive("0")
                            }
                            else if (!e.target.value) {

                                SetAmountReceive("")

                            } else {
                                if (regex.test(e.target.value) && Number(e.target.value) <= Number(effectiveAmount)) {
                                    SetAmountReceive(e.target.value)
                                } else if (Number(e.target.value) > Number(effectiveAmount)) {
                                    setSnackbarMessage('Amount receive higher than final pricing not allowed ');
                                    setSnackbarSeverity('error');
                                    setSnackbarOpen(true);
                                }
                            }
                        }} />
                    </ShowEPrice>}

                    {choosePlan && <Typography sx={{ fontFamily: 'Poppins', color: "gray", fontSize: "14px" }}>
                        Expire Date
                    </Typography>}
                    {choosePlan && <TextField
                        type={'date'}
                        fullWidth
                        onChange={handleMembershipDateChange}
                        value={membershipDate}
                        sx={{ fontFamily: 'Poppins' }}
                    />
                    }
                    {choosePlan && <p style={{ fontSize: "12px", fontWeight: "700", color: "red" }}>Don't Choose Older or Today's Date</p>}

                    {choosePlan && plan.find((data, index) => data._id == choosePlan) && <ShowTextArea>
                        <Label>Comments</Label>
                        <TextAreaArea spellCheck={false} value={comments} onChange={(e) => setComments(e.target.value)} />

                    </ShowTextArea>}
                </RemaingingSection>
                }

            </RemaingingSection>
            <ButtonSection >
                <ButtonSectionButton1 onClick={() => submitApprovedData()}>
                    Approve
                </ButtonSectionButton1>
                {/* <ButtonSectionButton2 onClick={() => { setShowSaveAndDecidePlanModel(false); handleApprove(venueVendorSlug, true) }}>
                    Skip & Approve
                </ButtonSectionButton2> */}
            </ButtonSection>
        </div>

        <Snackbar
            open={snackbarOpen}
            autoHideDuration={2000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                sx={{ width: '100%' }}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </ShowSavePlanModelBox>
}