import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Listings from "../RightSection.js/listings/Listings";
import TopVenues from "../RightSection.js/venues/TopVenues";
import TopCategories from "../RightSection.js/categories/TopCategories";
import Gallery from "../RightSection.js/gallery/Gallery";
import Reviews from "../RightSection.js/reviews/Reviews";
import Advertisements from "../RightSection.js/advertisements/Advertisements";
import PendingList from "./Pending/PendingList";
import {Users} from "./users/users"
import Analytics from "./analytics/analytics";
import {
  CategoryContect,
  CategoryContext,
  ChangeMembershipContext,
  SearchQueryContext,
  TypeContext,
  ViewContext,
} from "../../App";
import Features from "./features/Features";
import ApprovedList from "./approved/ApprovedList";
import RejectedList from "./rejected/RejectedList";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Profile from "./profile/Profile";
import Photos from "./portfoilo/Photos";
import Videos from "./portfoilo/Videos";
import Albums from "./portfoilo/Albums";
import Pricing from "./pricing/Pricing";
import Premium from "./premium/Premium";
import Basic from "./basic/Basic";
import Highlighter from "./highlighter/Highlighter";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ReactComponent as Search } from "../../assets/search.svg";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { categoriesList } from "./categories/categoriesList";
import LocationsPage from "./Locations/LocationsPage";
import VendorList from "./vendorListing/VendorList";
import Unlisted from "./unlisted/Unlisted";
import axios from "axios";
import { API_HOST } from "../Api/url";
import { VendorVenueReview } from "./vendorvenuereview/VendorVenueReview";
import MetaVendorVenue from "./metaVendorVenue/metaVendorVenue";

const DashBoardWrapper = styled.div`
  height: 100vh;
  overflow: auto;
  background-color: #f2f2f2;
  flex: 1;
  padding: 36px 45px 24px 45px;
`;
const StyledInput = styled.input`
  color: #ada7a7;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 8px 40px;
  width: 450px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 36px 0px 20px px;
  margin-top: 12px;
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

const InputContainer = styled.div`
  position: relative;
`;

const ClearIconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 2px;
  visibility: ${(props) => (props.searchQuery === "" ? "hidden" : "visible")};
  cursor: pointer;
  color: #ada7a7;
`;

const Heading = styled.h1`
  color: #000;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: underline;
  margin-top: 16px;
`;

const stylesButton = {
  textTransform: "capitalize",
  fontFamily: "Poppins",
  marginBottom: "16px",
};

const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Select = styled.select`
  height: 40px;
  width: 30%;
  margin-top: 12px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-family: "Poppins";
`;

const adminComponents = {
  "wedding venues": TopVenues,
  "vendor categories": TopCategories,
  gallery: Gallery,
  reviews: Reviews,
  advertisements: Advertisements,
  listing: Listings,
  pending: PendingList,
  approved: ApprovedList,
  rejected: RejectedList,
  premium: Premium,
  basic: Basic,
  featured: Highlighter,
  vendors: VendorList,
  unlisted: Unlisted,
  Users:Users,
  Analytics:Analytics,
  "Seo":MetaVendorVenue,
  "Vendor Review":VendorVenueReview
};

const vendorComponents = {
  "service Features": Features,
  Profile: Profile,
  locations: LocationsPage,
  photos: Photos,
  videos: Videos,
  albums: Albums,
  pricing: Pricing,
};
const premiumArray = ["premium", "basic", "featured"]
const listArray = ["listing", "pending", "approved", "rejected", "unlisted"]
const Dashboard = ({ selectedTab, setSelectedTab }) => {
  const [value, setValue] = useState(0);
  const [totalVendors, setTotalVendors] = useState(0);
  const [totalVenues, setTotalVenues] = useState(0);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const { isVendor, setIsVendor } = useContext(TypeContext);
  const { isView, setIsView } = useContext(ViewContext);
  const { searchQuery, setSearchQuery } = useContext(SearchQueryContext);
  const { category, setCategory } = useContext(CategoryContext);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setIsVendor(newValue === 1);
  };
  const Content = !isView
    ? adminComponents[selectedTab]
    : vendorComponents[selectedTab];
  const wrapperRef = useRef(null);

  const fetchListings = async () => {
    try {
      let response1;
      let response2;
      let requestBody = {};
      let count = 0;
      if (selectedTab !== "listing") {
        requestBody.vendorListingStatus = `${selectedTab.toUpperCase()}`;
        requestBody.venueListingStatus = `${selectedTab.toUpperCase()}`;
      }

      if (isVendor) {
        requestBody.vendorCategory = category.value;
      }
      if (selectedTab == "featured")
        count = 1
      else if (selectedTab == "basic") {
        count = 2
      }
      // console.log(searchQuery,"fetch",category.value)
      if ([...listArray, ...premiumArray].includes(selectedTab)) {
        if (isSearchClicked) {
          if (premiumArray.includes(selectedTab)) {
            
            response1 = await axios.get(
              `${API_HOST}/api/v1/admin/premium-listings/vendors?premiumStatus=${count}&searchQuery=${searchQuery}`)
            response2 = await axios.get(
              `${API_HOST}/api/v1/admin/premium-listings/venues?premiumStatus=${count}&searchQuery=${searchQuery}`
            );
          } else {
            response1 = await axios.post(
              `${API_HOST}/api/v1/admin/listings/vendors?searchQuery=${searchQuery}`,
              requestBody
            );
            response2 = await axios.post(
              `${API_HOST}/api/v1/admin/listings/venues?searchQuery=${searchQuery}`,
              requestBody
            );
          }
        } else {
          if (premiumArray.includes(selectedTab)) {
            response1 = await axios.get(
              `${API_HOST}/api/v1/admin/premium-listings/vendors?premiumStatus=${count}&category=${category.value}`,
            );
            response2 = await axios.get(
              `${API_HOST}/api/v1/admin/premium-listings/venues?premiumStatus=${count}`
            );
          } else {
            response1 = await axios.post(
              `${API_HOST}/api/v1/admin/listings/vendors`,
              requestBody
            );
            response2 = await axios.post(
              `${API_HOST}/api/v1/admin/listings/venues`,
              requestBody
            );
          }
        }
        setTotalVendors(response1.data.totalRecords);
        setTotalVenues(response2.data.totalRecords);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [selectedTab]);

  useEffect(() => {
    if (!searchQuery) {
      setIsSearchClicked(false);
    }
  }, [searchQuery]);

  useEffect(() => {
    setSearchQuery("");
  }, [value, selectedTab, category]);

  useEffect(() => {
    setCategory("");
  }, [selectedTab, isVendor]);

  useEffect(() => {
    if(category.value=="SHOWALL")
    setCategory("")
    fetchListings();
  }, [isSearchClicked, category, searchQuery, selectedTab]);
  // console.log(selectedTab,"ye tab ha ")

  return (
    <DashBoardWrapper ref={wrapperRef}>
      <div>
        {isView && (
          <Button
            sx={stylesButton}
            variant="outlined"
            onClick={() => {
              setIsView(!isView);
            }}
          >
            <ArrowBackIcon fontSize="small" sx={{ marginRight: "6px" }} />
            Back
          </Button>
        )}
        <Heading>{selectedTab}</Heading>
        {(selectedTab === "listing" ||
          selectedTab === "pending" ||
          selectedTab === "approved" ||
          selectedTab === "rejected" ||
          selectedTab === "premium" ||
          selectedTab === "featured" ||
          selectedTab === "basic" ||
          selectedTab === "unlisted") && (
            <Div>
              <InputWrapper>
                <InputContainer>
                  <SearchIconWrapper>
                    <Search width="20px" height="20px" />
                  </SearchIconWrapper>
                  <StyledInput
                    type="text"
                    placeholder="Search"
                    onChange={(event) => setSearchQuery(event.target.value)}
                    value={searchQuery}
                    style={{ paddingRight: "40px" }}
                  />
                  <ClearIconWrapper
                    searchQuery={searchQuery}
                    onClick={() => setSearchQuery("")}
                  >
                    <ClearRoundedIcon sx={{ width: "20px", height: "20px" }} />
                  </ClearIconWrapper>
                </InputContainer>
                <Button
                  variant="contained"
                  sx={{
                    ml: 1,
                    textTransform: "capitalize",
                    fontFamily: "Poppins",
                  }}
                  onClick={() => {
                    setIsSearchClicked(true);
                  }}
                  disabled={searchQuery === ""}
                >
                  Search
                </Button>
              </InputWrapper>
              {["listing", "pending", "approved", "rejected","premium","basic","featured"].includes(
                selectedTab
              ) &&
                isVendor && (
                  <Select
                    key={selectedTab}
                    onChange={(e) => {
                      const selectedCategory = categoriesList.find(
                        (item) => item.value === e.target.value
                      );
                      setCategory(selectedCategory);
                    }}
                  >
                    {/* <option value="">Select Category</option> */}
                    {categoriesList.map((item) => {
                      return <option value={item.value}> {item.category}</option>;
                    })}
                  </Select>
                )}
            </Div>
          )}
      </div>
      {!isView &&
        selectedTab !== "gallery" &&
        selectedTab !== "reviews" &&
        selectedTab !== "wedding venues" &&
        selectedTab !== "vendor categories" &&
        selectedTab !== "advertisements" &&
        selectedTab !== "vendors" &&selectedTab !== "Users"&&selectedTab !== "Analytics"&&selectedTab !== "Seo"&&selectedTab !== "Vendor Review" && (
          <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab style={{ fontWeight: "700" }} label={`Venues (${typeof totalVenues == "number" ? totalVenues : ".."})`} />
              <Tab style={{ fontWeight: "700" }} label={`Vendors (${typeof totalVendors == "number" ? totalVendors : ".."})`} />
            </Tabs>
          </Box>
        )}
      {Content && (
        <Content
          isSearchClicked={isSearchClicked}
          setSelectedTab={setSelectedTab}
        />
      )}
    </DashBoardWrapper>
  );
};

export default Dashboard;
