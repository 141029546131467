import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { API_HOST } from '../../Api/url';
import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
} from '@mui/material';
import moment from 'moment-timezone';
import styled from 'styled-components';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useNavigate } from 'react-router-dom';
const TypeDiv = styled.div`
margin-top:10px;
display: flex;
gap:10px;


`;
const TypeName1 = styled.h1`
width: 100%;
padding: 3px 25px;
background-color:${(props) => props.status == 0 ? "green" : "white"};
color:${(props) => props.status == 0 ? "white" : "black"};
text-align: center;
box-shadow: 0px 0px 2px black;
cursor: pointer;
transition: 0.3s ease;
&:active{
    transform: scale(0.98);
}
`
const TypeName2 = styled.h1`
width: 100%;
padding: 3px 25px;
background-color:${(props) => props.status == 1 ? "green" : "white"};
color:${(props) => props.status == 1 ? "white" : "black"};
text-align: center;
box-shadow: 0px 0px 2px black;
cursor: pointer;
transition: 0.3s ease;
&:active{
    transform: scale(0.98);
}

`
const TypeName3 = styled.h1`
width: 100%;
padding: 10px 25px;
background-color:${(props) => props.status == 2 ? "green" : "white"};
color:${(props) => props.status == 2 ? "white" : "black"};
text-align: center;
box-shadow: 0px 0px 2px black;
cursor: pointer;
transition: 0.3s ease;
&:active{
    transform: scale(0.98);
}

`
const TypeName4 = styled.h1`
width: 100%;
padding: 10px 25px;
background-color:${(props) => props.status == 3 ? "green" : "white"};
color:${(props) => props.status == 3 ? "white" : "black"};
text-align: center;
box-shadow: 0px 0px 2px black;
transition: 0.3s ease;
cursor: pointer;
&:active{
    transform: scale(0.98);
}
`
const RefreshButton = styled.button`
background-color: white;
padding: 2px 8px;
font-size: 13px;
box-shadow: 0px 0px 3px black;
border-radius: 4px;
&:active{
    transform: scale(0.98);
}
display: flex;
justify-content: center;
align-items: center;
gap:5px;


`
const VendorImage = styled.img`
object-fit: cover;
object-position: left top;
min-width:40px;
min-height: 40px;
max-width:40px;
max-height: 40px;
border-radius: 150px;
`
const VendorAnchor = styled.a`
font-weight: 800;
color: navy;
cursor: pointer;
`;
const VendorType = styled.p`
color: white;
font-size: 14px;
font-weight: 800;
background-color: ${(props) => props.type == "venue" ? "#007FFF" : "#17B169"};
padding: 5px 10px;
width:fit-content;


`
const VendorDate = styled.p`
font-size: 14px;
font-weight: 800;
color: white;
background-color: ${(props) => props.imp == "1" ? "green" : props.imp == "2" ? "orangered" : "red"};
padding: 5px 10px;
width:fit-content;
`;
const UserType = styled.p`
font-size: 14px;
font-weight: 800;
color: white;
padding: 5px 10px;
width:fit-content;
background-color: ${(props) => props.type == "ADMIN" ? "red" : props.type == "SERVICE" ? "#FFAA33" : "#FFAC1C"};
`;

const UserName = styled.p`

`
const SearchBox = styled.p`
padding-top: 15px;
display: flex;
justify-content: space-between;
align-items: center;
gap:15px
`
const SearchInput = styled.input`
width: 350px;
box-shadow: 0px 0px 2px black;
border-radius: 3px;
padding:5px 8px;
height: 40px;
outline: none;
`;

const Analytics = () => {
    const navigate = useNavigate()
    const [status, setStatus] = useState(0)
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [searchValue, setSearchValue] = useState("")
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [analyticsData, setAnalyticsData] = useState({
        viewContact: [],
        chatInitiate: []
    })
    const [viewContactArray, setViewConatactArray] = useState([])
    const [chatInitiateArray, setChatInitiateArray] = useState([])

    const CheckImportanceOfLead = useCallback((timeStamp) => {
        const currentDate = moment().tz('Asia/Kolkata');
        const dateToCheck = moment(timeStamp).tz('Asia/Kolkata');
        const differenceInDays = currentDate.diff(dateToCheck, "days");
        if (differenceInDays <= 2) {
            return "1";
        } else if (differenceInDays <= 6) {
            return "2";
        } else {
            return "3";
        }
    }, []);

    const ChangeTimeBasedUponIndianTime = useCallback((timeStamp) => {
        const formattedDate = moment(timeStamp).tz('Asia/Kolkata').format("DD MMM YYYY , HH:mm");
        return formattedDate ? formattedDate : timeStamp;
    }, []);

    async function getAllAnalyticsData() {
        try {
            setLoading(true)
            const result = await axios.get(`${API_HOST}/api/v1/analytics/getallanalyticsdata`);
            setAnalyticsData(result.data.analytics)
            setChatInitiateArray(result.data.analytics.chatInitiate)
            setViewConatactArray(result.data.analytics.viewContact)
            setLoading(false)
        } catch (error) {

        }
    }

    useEffect(() => {
        getAllAnalyticsData()
    }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '75vh',
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    function filterSearch(index, searchValue) {

        if (index == 0) {
            if (searchValue.trim().length == 0) {
                setViewConatactArray(analyticsData.viewContact)
            }
            const UpdatedArray = analyticsData.viewContact.filter(item =>
                item.data.vendorData.vendorName && item.data.vendorData.vendorName.toLowerCase().includes(searchValue.toLowerCase())
            );
            setViewConatactArray(UpdatedArray)

        } else if (index == 1) {
            if (searchValue.trim().length == 0) {
                setChatInitiateArray(analyticsData.chatInitiate)
            }
            const UpdatedArray = analyticsData.chatInitiate.filter(item =>
                item.data.vendorData.vendorName && item.data.vendorData.vendorName.toLowerCase().includes(searchValue.toLowerCase())
            );
            setChatInitiateArray(UpdatedArray)
        }
    }

    return (
        <>
            <TypeDiv>
                {/* <TypeName1 status={status} onClick={() => setStatus(0)}>Visitors Logs</TypeName1> */}
                <TypeName1 status={status} onClick={() => { setStatus(0); setPage(0); setSearchValue(""); setChatInitiateArray(analyticsData.chatInitiate); setViewConatactArray(analyticsData.viewContact) }}>View Contacts</TypeName1>
                <TypeName2 status={status} onClick={() => { setStatus(1); setPage(0); setSearchValue(""); setViewConatactArray(analyticsData.viewContact); setChatInitiateArray(analyticsData.chatInitiate); }}>Chat Initiate</TypeName2>
                {/* <TypeName4 status={status} onClick={() => setStatus(3)}>Online Vendors</TypeName4> */}
            </TypeDiv>
            <SearchBox>
                <SearchInput spellCheck={false} onChange={(e) => { setSearchValue(e.target.value); filterSearch(status, e.target.value); }} value={searchValue} placeholder="Search Vendor / Venue" />
                <RefreshButton onClick={getAllAnalyticsData}><AutorenewIcon /> Refresh</RefreshButton>
            </SearchBox>
            {status == 0 && <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '15px' }}>
                <TableContainer>
                    <Table stickyHeader aria-label='sticky table'>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Name / Link</TableCell>
                                <TableCell>Vendor Contact</TableCell>
                                <TableCell>User / Service Provider Name</TableCell>
                                <TableCell>User Type</TableCell>
                                <TableCell>User Contact</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {viewContactArray
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((user, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            backgroundColor: index % 2 === 0 ? '#f7f7f7' : 'white',
                                        }}
                                    >
                                        <TableCell><VendorImage src={user.data.vendorData?.vendorCoverImageURL ? user.data.vendorData?.vendorCoverImageURL : "https://omnileadzdev.s3.amazonaws.com/NjVhNjU1NzhkMTE5NzMwMWE5NzRiNGI1/1711969834975_Web_Photo_Editor.jpg"} /></TableCell>
                                        <TableCell>{user.data.vendorData.type && <VendorType type={`${user.data.vendorData.type}`}>{user.data.vendorData.type}</VendorType>}</TableCell>
                                        <TableCell><VendorDate imp={CheckImportanceOfLead(user.createdAt)}>{ChangeTimeBasedUponIndianTime(user.createdAt)}</VendorDate></TableCell>
                                        <TableCell>< VendorAnchor target="_blank" href={`${user.data.vendorData.type == "venue" ? `https://maangalbazaar.com/venue_profile/${user.data.vendorData.vendorSlug}` : `https://maangalbazaar.com/vendor_profile/${user.data.vendorData.vendorSlug}`}`}>{user.data.vendorData.vendorName}</ VendorAnchor></TableCell>
                                        <TableCell>{user.data.vendorData.vendorContact}</TableCell>
                                        <TableCell><UserName>{user.data.userData.userFullName}</UserName></TableCell>
                                        <TableCell><UserType type={`${user.data.userData.userType}`}>{user.data.userData.userType}</UserType></TableCell>
                                        <TableCell>{user.data.userData.userContact}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component='div'
                    count={viewContactArray.length ? viewContactArray.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>}



            {status == 1 && <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '15px' }}>
                <TableContainer>
                    <Table stickyHeader aria-label='sticky table'>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Name / Link</TableCell>
                                <TableCell>Vendor Contact</TableCell>
                                <TableCell>User / Service Provider Name</TableCell>
                                <TableCell>User Type</TableCell>
                                <TableCell>User Contact</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {chatInitiateArray
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((user, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            backgroundColor: index % 2 === 0 ? '#f7f7f7' : 'white',
                                        }}
                                    >
                                        <TableCell><VendorImage src={user.data.vendorData?.vendorCoverImageURL} /></TableCell>
                                        <TableCell>{user.data.vendorData.type && <VendorType type={`${user.data.vendorData.type}`}>{user.data.vendorData.type}</VendorType>}</TableCell>
                                        <TableCell><VendorDate imp={CheckImportanceOfLead(user.createdAt)}>{ChangeTimeBasedUponIndianTime(user.createdAt)}</VendorDate></TableCell>
                                        <TableCell>< VendorAnchor target="_blank" href={`${user.data.vendorData.type == "venue" ? `https://maangalbazaar.com/venue_profile/${user.data.vendorData.vendorSlug}` : `https://maangalbazaar.com/vendor_profile/${user.data.vendorData.vendorSlug}`}`}>{user.data.vendorData.vendorName}</ VendorAnchor></TableCell>
                                        <TableCell>{user.data.vendorData.vendorContact}</TableCell>
                                        <TableCell><UserName>{user.data.userData.userFullName}</UserName></TableCell>
                                        <TableCell><UserType type={`${user.data.userData.userType}`}>{user.data.userData.userType}</UserType></TableCell>
                                        <TableCell>{user.data.userData.userContact}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component='div'
                    count={chatInitiateArray.length ? chatInitiateArray.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>}
        </>

    );
};

export default Analytics;
