// import { makeAPIRequest, useAPIRequest } from '../../utils/apiHandler';
import { useEffect, useState } from 'react';
import { Grid, MenuItem, Select } from '@mui/material';
import { makeAPIRequest, useAPIRequest } from '../../../utility/mediaHandler';

export default function LocationSelect(props) {
  const [stateCode, setStateCode] = useState(props.stateCode);
  const [stateCity, setStateCity] = useState(props.cityName);

  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  const statesRequest = useAPIRequest({
    requestEndpoint: '/meta/states',
    requestMethod: 'GET',
  });

  useEffect(() => {
    if (
      !statesRequest.isLoading &&
      statesRequest.isSuccess &&
      statesRequest.responseData
    ) {
      const { responseStatus } = statesRequest.responseData;
      if (responseStatus === 'SUCCESS') {
        const { stateData } = statesRequest.responseData;
        setStateOptions(stateData);
      }
    }
  }, [
    statesRequest.isLoading,
    statesRequest.isSuccess,
    statesRequest.responseData,
  ]);

  useEffect(() => {
    if (stateCode === null) return;

    const fetchCities = async () => {
      const fetchedStateData = await makeAPIRequest({
        requestEndpoint: '/meta/states/:stateCode/cities',
        urlParams: {
          stateCode: stateCode,
        },
        requestMethod: 'GET',
      });

      if (
        fetchedStateData.isSuccess &&
        fetchedStateData.responseData.responseStatus === 'SUCCESS'
      ) {
        const { stateCities } = fetchedStateData.responseData;
        setCityOptions(stateCities);
        if (props.stateCode !== stateCode) {
          setStateCity(null);
        }
      }
    };

    fetchCities();
  }, [stateCode]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Select
            fullWidth
            value={`${stateCode}`}
            sx={{ flexGrow: 1 }}
            onChange={(e) => {
              if (stateOptions.length !== 0) {
                setStateCode(e.target.value);
                setStateCity(null);
                setCityOptions([]);
              }
            }}
            error={props.isInvalid || false}
          >
            <MenuItem value={'null'} disabled>
              Select State To Proceed
            </MenuItem>
            {stateOptions.map((stateOpt) => {
              return (
                <MenuItem value={stateOpt.stateCode} key={stateOpt.stateCode}>
                  {stateOpt.stateName}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            fullWidth
            value={cityOptions.length > 0 ? `${stateCity}` : 'null'}
            sx={{ flexGrow: 1 }}
            onChange={(e) => {
              props.onSelect({
                stateCode: stateCode,
                cityName: e.target.value,
              });
              setStateCity(e.target.value);
            }}
            disabled={cityOptions.length === 0}
            error={props.isInvalid || false}
          >
            <MenuItem value={'null'} disabled>
              Select City To Proceed
            </MenuItem>
            {cityOptions.length === 0 ? (
              <MenuItem value={`${stateCity}`} disabled>
                {stateCity}
              </MenuItem>
            ) : null}
            {cityOptions.map((cityOpt) => {
              return (
                <MenuItem value={cityOpt.cityName} key={cityOpt.cityName}>
                  {cityOpt.cityName}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
      </Grid>
    </>
  );
}
