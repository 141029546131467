import React, { useEffect, useState } from 'react';
import GalleryCard from './GalleryCard';
import { Alert, Button, Grid, Snackbar } from '@mui/material';
import styled from 'styled-components';
import axios from 'axios';
import { API_HOST } from '../../Api/url';
import { makeAPIRequest, manageMedia } from '../../../utility/mediaHandler';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
const Wrapper = styled.div`
  margin: 20px 0px 20px 0px;
`;

const StyledNo = styled.p`
  margin: 12px 0px;
  font-size: 18px;
  font-weight: 500;
  font-family: Poppins;
`;

const ButtonStyles = {
  marginTop: '20px',
  width: '100%',
  textTransform: 'capitalize',
  fontFamily: 'Poppins',
};

const Gallery = () => {
  const [open, setOpen] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = useState(Array(12).fill(null));
  const [previewImages, setPreviewImages] = useState(Array(12).fill(null));
  const [linkValue, setLinkValue] = useState([])
  const [galleryData, setGalleryData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleFileChange = (index) => (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const newSelectedFiles = [...selectedFiles];
      newSelectedFiles[index] = event.target.files[0];
      setSelectedFiles(newSelectedFiles);
      const reader = new FileReader();
      reader.onloadend = () => {
        const newPreviewImages = [...previewImages];
        newPreviewImages[index] = reader.result;
        setPreviewImages(newPreviewImages);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleEditClick = (index) => {
    const fileInput = document.querySelector(`#file-input-${index}`);
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleDeleteClick = (index) => {
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles[index] = null;
    setSelectedFiles(newSelectedFiles);

    const newPreviewImages = [...previewImages];
    newPreviewImages[index] = null;
    setPreviewImages(newPreviewImages);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_HOST}/api/v1/meta/gallery`);
      const links = response.data.galleryData.map((data, index) => data.link.toString())
      setLinkValue(links)
      setGalleryData(response.data.galleryData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClick = async () => {
    setOpen(true)
    const filteredFiles = selectedFiles.filter(
      (selectedFile) => selectedFile !== null
    );
    // console.log(filteredFiles)
    const mediaResponses = await manageMedia({
      mediaFiles: filteredFiles,
      fileMethod: 'PUT',
      objectKeyGenFn: (fileHandle, fileIdx, fileMethod) => {
        return `media/gallery/${selectedFiles.indexOf(fileHandle)}`;
      },
    });
    const updatedImageNumber = []
    await Promise.all(
      mediaResponses.map(async (mediaResponse) => {
        // console.log(mediaResponse,"media res")
        const { objectKey, fileHandle } = mediaResponse;
        const result = await makeAPIRequest({
          requestEndpoint: '/meta/gallery',
          requestMethod: 'POST',
          bodyParams: {
            objectKey: objectKey,
            galleryIndex: selectedFiles.indexOf(fileHandle),
          },
        });
        if (result.isSuccess) {
          updatedImageNumber.push(Number(objectKey.slice(14)) + 1)
        }
      })
    );
     await makeAPIRequest({
      requestEndpoint: '/meta/gallerylinkupdate',
      requestMethod: 'POST',
      bodyParams: {
       galleryLinks:linkValue
      },
    });
    
    fetchData();
    setSnackbarMessage(`Gallery Image ${updatedImageNumber.join(" , ")} And All Links updated successfully`);
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    setOpen(false)
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  }

  return (
    <Wrapper>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={2}>
        {[...Array(12)].map((_, index) => {
          const item = galleryData[index];
          return (
            <Grid item xs={6} key={`${item} - ${index}`}>
              <StyledNo>Image {index + 1}</StyledNo>
              <GalleryCard
                linkValue={linkValue}
                setLinkValue={setLinkValue}
                index={index}
                selectedFile={selectedFiles[index]}
                previewImage={previewImages[index]}
                onFileChange={handleFileChange(index)}
                onEditClick={() => handleEditClick(index)}
                onDeleteClick={() => handleDeleteClick(index)}
                data={item}
                onUploadSuccess={fetchData}
                id={index}
              />
            </Grid>
          );
        })}
      </Grid>
      <Button variant='contained' sx={ButtonStyles} onClick={handleClick}>
        Upload
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

export default Gallery;
