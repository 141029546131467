import { Button } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { categoriesList } from "./categoriesList";
import { useEffect } from "react";

const CardWrapper = styled.div`
background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  gap:15px;
  box-shadow: 0px 2px 3px black;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  flex-grow: 1;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-family: Poppins;
  font-size: 13px;
`;

const Input = styled.input`
  padding: 5px;
  border-radius: 5px;
  color: #ada7a7;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
`;

const Textarea = styled.textarea`
  padding: 5px;
  resize: none;
  border-radius: 5px;
  height: 100px;
  border-radius: 5px;
  color: #ada7a7;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
`;

const Select = styled.select`
  height: 30px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: none;
  cursor: pointer;
`;
const CurrentImage=styled.p`
cursor: pointer;
color: #007FFF;
font-weight: 500;
font-size: 12px;
margin-left:6px;


`

const Card = ({ data, onUploadSuccess, id, handleClick }) => {
  const [title, setTitle] = useState(data ? data.categoryName : "");
  const [description, setDescription] = useState(
    data ? data.categoryDescription : ""
  );
  const [link, setLink] = useState(data&&data.vendorLink&&data.vendorLink.length>0 ? data.vendorLink : "maangalbazaar.com");
  const [img, setImg] = useState(data ? data.categoryCoverImageURL : "");
  const [selectedFile, setSelectedFile] = useState();
  const [categoryCanonicalName, setCategoryCanonicalName] = useState(
    data ? data.categoryCanonicalName : ""
  );

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedFile(file);
    }
  };
  const handleImageClick = () => {
    window.open(data.categoryCoverImageURL, '_blank');
  };

  return (
    <CardWrapper>
      <InputWrapper> 
        <Label htmlFor="file">Add Category Image</Label>
        <p style={{ color: "red", fontSize: "12px" }}>Allowed file types: JPG, JPEG, PNG </p>
        <Input
          type="file"
          id="file"
          accept=".jpg, .jpeg, .png"
          onChange={handleFileChange}
        />
         
        <CurrentImage onClick={handleImageClick} >view current image</CurrentImage>
      </InputWrapper>
      
      <InputWrapper>
        <Label htmlFor="title">Select Category</Label>
        <Select
          onChange={(e) => {
            const selectedCategory =
              categoriesList &&
              categoriesList.find((item) => item.value === e.target.value);
            setTitle(selectedCategory.category);
            setCategoryCanonicalName(selectedCategory.value);
          }}
          value={categoryCanonicalName}
        >
          <option>Select category</option>
          {categoriesList &&
            categoriesList.map((item, index) => {
              return (
                <option
                  style={{ cursor: "pointer" }}
                  value={item.value}
                  key={`${item.category}-${index}`}
                >
                  {item.category}
                </option>
              );
            })}
        </Select>
      </InputWrapper>
      <InputWrapper>
        <Label htmlFor="description">Category Description</Label>
        <Textarea
          id="description"
          cols="30"
          rows="10"
          placeholder="Type here..."
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        ></Textarea>
      </InputWrapper>
      <InputWrapper>
        <Label htmlFor="link">Link to Category</Label>
        <Input
          type="url"
          id="link"
          placeholder="Add link here"
          value={link}
          onChange={(e) => setLink(e.target.value)}
        />
      </InputWrapper>
      <Button
        variant="contained"
        sx={{ boxShadow: "none", textTransform: "capitalize" }}
        onClick={() =>
          handleClick(
            data,
            selectedFile,
            title,
            id,
            description,
            categoryCanonicalName
          )
        }
      >
        Upload
      </Button>
    </CardWrapper>
  );
};

export default Card;
