import { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { API_HOST } from '../../../utility/mediaHandler';
import axios from 'axios';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Alert, Grid, Snackbar } from "@mui/material";
import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import moment from 'moment-timezone';


const TabContainer = styled.div`
margin-top:10px;
display: flex;
gap:10px;

`
const Vendor = styled.h1`
width: 100%;
padding: 3px 25px;
background-color:${(props) => props.status == 0 ? "green" : "white"};
color:${(props) => props.status == 0 ? "white" : "black"};
text-align: center;
box-shadow: 0px 0px 2px black;
cursor: pointer;
transition: 0.3s ease;
&:active{
    transform: scale(0.98);
}
`
const Venue = styled.h1`
width: 100%;
padding: 3px 25px;
background-color:${(props) => props.status == 1 ? "green" : "white"};
color:${(props) => props.status == 1 ? "white" : "black"};
text-align: center;
box-shadow: 0px 0px 2px black;
cursor: pointer;
transition: 0.3s ease;
&:active{
    transform: scale(0.98);
}`;

const RefreshButton = styled.button`
background-color: white;
padding: 2px 8px;
font-size: 13px;
box-shadow: 0px 0px 3px black;
border-radius: 4px;
&:active{
    transform: scale(0.98);
}
display: flex;
justify-content: center;
align-items: center;
gap:5px;


`

const VendorImage = styled.img`
object-fit: cover;
object-position: left top;
width:40px;
height: 40px;
min-width:40px;
min-height: 40px;
max-width:40px;
max-height: 40px;
border-radius: 150px;
`
const VendorAnchor = styled.a`
font-weight: 800;
color: navy;
cursor: pointer;
`;
const VendorType = styled.p`
color: white;
font-size: 14px;
font-weight: 800;
background-color: ${(props) => props.type == "venue" ? "#007FFF" : "#17B169"};
padding: 5px 10px;
width:fit-content;


`
const VendorDate = styled.p`
text-align: center;
font-size: 14px;
font-weight: 800;
min-width: 160px;
color: white;
background-color: ${(props) => props.imp == "1" ? "green" : props.imp == "2" ? "orangered" : "red"};
padding: 5px 6px;
width:fit-content;
`;
const UserType = styled.p`
font-size: 14px;
font-weight: 800;
color: white;
padding: 5px 10px;
width:fit-content;
background-color: ${(props) => props.type == "ADMIN" ? "red" : props.type == "SERVICE" ? "#FFAA33" : "#FFAC1C"};
`;

const UserName = styled.p`

`
const SearchBox = styled.p`
padding-top: 15px;
display: flex;
justify-content: space-between;
align-items: center;
gap:15px
`
const SearchInput = styled.input`
width: 350px;
box-shadow: 0px 0px 2px black;
border-radius: 3px;
padding:5px 8px;
height: 40px;
outline: none;
`;

const StatusButton = styled.button`
background-color: ${(props) => props.status == "UNLIST" ? "RED" : "GREEN"};
color: white;
padding: 5px 10px;
border-radius: 4px;
&:active {
        transform: scale(0.96);
    }
    font-weight: 600;
`;

const DeleteButton = styled.button`
background-color: red;
color: white;
padding: 5px 10px;
border-radius: 4px;
&:active {
        transform: scale(0.96);
    }
    font-weight: 600;
`;
const ReviewDescription = styled.p`
min-width: 300px;
`

export function VendorVenueReview() {
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(0)
    const [reviewData, setReviewData] = useState({ vendors: [], venues: [] })
    const [vendorReview, setVendorReview] = useState([])
    const [venueReview, setVenueReview] = useState([])
    const [page, setPage] = useState(0);
    const [searchValue, setSearchValue] = useState("")
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    function filterSearch(index, searchValue) {

        if (index == 0) {
            if (searchValue.trim().length == 0) {
                setVendorReview(reviewData.vendors)
            }
            const UpdatedArray = reviewData.vendors.filter(item =>
                item.vendorDetails[0].vendorName && item.vendorDetails[0].vendorName.toLowerCase().includes(searchValue.toLowerCase())
            );
            setVendorReview(UpdatedArray)

        } else if (index == 1) {
            if (searchValue.trim().length == 0) {
                setVenueReview(reviewData.venues)
            }
            const UpdatedArray = reviewData.venues.filter(item =>
                item.venueDetails[0].venueName && item.venueDetails[0].venueName.toLowerCase().includes(searchValue.toLowerCase())
            );
            setVenueReview(UpdatedArray)
        }
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    async function fetchReview() {
        try {
            setOpen(true)
            const response = await axios.get(`${API_HOST}/api/v1/admin/vendor-venue-review`);
            setReviewData(response.data.reviews);
            setVenueReview(response.data.reviews.venues);
            setVendorReview(response.data.reviews.vendors);
            if(searchValue.trim().length>0){
                filterSearch(status,searchValue.trim())
            }
        } catch (error) {

        } finally {
            setOpen(false)
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const CheckImportanceOfLead = useCallback((timeStamp) => {
        const currentDate = moment().add(5, 'hours').add(30, 'minutes');
        const dateToCheck = moment(timeStamp).add(5, 'hours').add(30, 'minutes');
        const differenceInDays = currentDate.diff(dateToCheck, "days");
        if (differenceInDays <= 2) {
            return "1";
        } else if (differenceInDays <= 6) {
            return "2";
        } else {
            return "3";
        }
    }, []);

    const ChangeTimeBasedUponIndianTime = (timeStamp) => {
        const formattedDate = moment(timeStamp).tz('Asia/Kolkata').format("DD MMM YYYY , HH:mm");
        return formattedDate ? formattedDate : timeStamp;
    };


    async function DeteleReview(id, name) {
        try {
            setOpen(true)
            await axios.delete(`${API_HOST}/api/v1/admin/delete-vendor-venue-review/${id}`);
            fetchReview();
            setSnackbarMessage(`Review By ${name} Successfully Deleted`);
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        } catch {
            setSnackbarMessage(`Fail To Deleted Review By ${name}, Please Try Later`);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        } finally {
            setOpen(false)
        }
    }

    async function ChangeStatusReview(id, name, status) {
        try {
            const body = {
                status: status == "UNLIST" ? "LIST" : "UNLIST"
            }
            setOpen(true)
            await axios.post(`${API_HOST}/api/v1/admin/change-status-vendor-venue-review/${id}`, body);
            fetchReview();
            setSnackbarMessage(`Status Of Review By ${name} Successfully Updated`);
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        } catch {
            setSnackbarMessage(`Fail To Updated Review By ${name}, Please Try Later`);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        } finally {
            setOpen(false)
        }
    }


    useEffect(() => {
        fetchReview()

    }, [])

    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    }




    return (
        <main>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <TabContainer>
                <Vendor status={status} onClick={() => { setStatus(0); setPage(0); setSearchValue(""); setVendorReview(reviewData.vendors); setVenueReview(reviewData.venues) }}>Vendor</Vendor>
                <Venue status={status} onClick={() => { setStatus(1); setPage(0); setSearchValue(""); setVendorReview(reviewData.vendors); setVenueReview(reviewData.venues) }}>Venue</Venue>
            </TabContainer>
            <SearchBox>
                <SearchInput spellCheck={false} onChange={(e) => { setSearchValue(e.target.value); filterSearch(status, e.target.value); }} value={searchValue} placeholder="Search Vendor / Venue" />
                <RefreshButton onClick={() => { fetchReview(); setPage(0); setSearchValue("") }} ><AutorenewIcon /> Refresh</RefreshButton>
            </SearchBox>


            {status == 0 && <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '15px' }}>
                <TableContainer>
                    <Table stickyHeader aria-label='sticky table'>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Name / Link</TableCell>
                                <TableCell>Vendor Category</TableCell>
                                <TableCell>Vendor City</TableCell>
                                <TableCell>User Name</TableCell>
                                <TableCell>Review Rating</TableCell>
                                <TableCell>Review Description</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {vendorReview
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((data, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            backgroundColor: index % 2 === 0 ? '#f7f7f7' : 'white',
                                        }}
                                    >
                                        <TableCell><VendorImage src={data.vendorDetails?.[0].vendorCoverImageObjectKey ? "https://maangal-optim-prod.s3.ap-south-1.amazonaws.com/" + data.vendorDetails?.[0].vendorCoverImageObjectKey : ""} alt="MB" /></TableCell>
                                        <TableCell><VendorDate imp={CheckImportanceOfLead(data.reviewPostedOnTimestamp)}>{ChangeTimeBasedUponIndianTime(data.reviewPostedOnTimestamp)}</VendorDate></TableCell>
                                        <TableCell><StatusButton onClick={() => ChangeStatusReview(data._id, data.userName, data.status)} status={data.status}>{data.status}</StatusButton></TableCell>
                                        <TableCell>< VendorAnchor target="_blank" href={`${data.reviewServiceType == "VENUE" ? `https://maangalbazaar.com/venue_profile/${data.reviewServiceSlug}` : `https://maangalbazaar.com/vendor_profile/${data.reviewServiceSlug}`}`}>{data.vendorDetails[0].vendorName}</ VendorAnchor></TableCell>
                                        <TableCell>{data.vendorDetails[0].vendorCategory.replace(/_/g, " ")} / {data.vendorDetails[0].vendorSubcategory.replace(/_/g, " ")}</TableCell>
                                        <TableCell>{data.vendorDetails[0].vendorCity}</TableCell>
                                        <TableCell>{data.userName}</TableCell>
                                        <TableCell>{data.reviewRating}</TableCell>
                                        <TableCell><ReviewDescription>{data.reviewDescription}</ReviewDescription></TableCell>
                                        <TableCell><DeleteButton onClick={() => {
                                            if (data.status == "UNLIST") {
                                                DeteleReview(data._id, data.userName)
                                            } else {
                                                setSnackbarMessage(`LIST Review Can Not Be Deleted, Make It UnList To Perform This Action`);
                                                setSnackbarSeverity("error");
                                                setSnackbarOpen(true);
                                            }

                                        }}>Delete</DeleteButton></TableCell>

                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component='div'
                    count={vendorReview.length ? vendorReview.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>}


            {status == 1 && <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '15px' }}>
                <TableContainer>
                    <Table stickyHeader aria-label='sticky table'>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Name / Link</TableCell>
                                <TableCell>Venue City</TableCell>
                                <TableCell>User Name</TableCell>
                                <TableCell>Review Rating</TableCell>
                                <TableCell>Review Description</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {venueReview
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((data, index) => {
                                    return <TableRow
                                        key={index}
                                        sx={{
                                            backgroundColor: index % 2 === 0 ? '#f7f7f7' : 'white',
                                        }}
                                    >
                                        <TableCell><VendorImage src={data.venueDetails[0].venueCoverImageObjectKey ? "https://maangal-optim-prod.s3.ap-south-1.amazonaws.com/" + data.venueDetails[0].venueCoverImageObjectKey : ""} alt="MB" /></TableCell>
                                        <TableCell><VendorDate imp={CheckImportanceOfLead(data.reviewPostedOnTimestamp)}>{ChangeTimeBasedUponIndianTime(data.reviewPostedOnTimestamp)}</VendorDate></TableCell>
                                        <TableCell><StatusButton onClick={() => ChangeStatusReview(data._id, data.userName, data.status)} status={data.status}>{data.status}</StatusButton></TableCell>
                                        <TableCell>< VendorAnchor target="_blank" href={`${data.reviewServiceType == "VENUE" ? `https://maangalbazaar.com/venue_profile/${data.reviewServiceSlug}` : `https://maangalbazaar.com/vendor_profile/${data.reviewServiceSlug}`}`}>{data.venueDetails[0].venueName}</ VendorAnchor></TableCell>
                                        <TableCell>{data.venueDetails[0].venueCity}</TableCell>
                                        <TableCell>{data.userName}</TableCell>
                                        <TableCell>{data.reviewRating}</TableCell>
                                        <TableCell><ReviewDescription>{data.reviewDescription}</ReviewDescription></TableCell>
                                        <TableCell><DeleteButton onClick={() => {
                                         if (data.status == "UNLIST") {
                                                DeteleReview(data._id, data.userName)
                                            } else {
                                                setSnackbarMessage(`LIST Review Can Not Be Deleted, Make It UnList To Perform This Action`);
                                                setSnackbarSeverity("error");
                                                setSnackbarOpen(true);
                                            }
                                        }}>Delete</DeleteButton></TableCell>


                                    </TableRow>
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component='div'
                    count={venueReview.length ? venueReview.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>


        </main>
    )
}