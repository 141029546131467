import {
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  ImageList,
  ImageListItem,
  Stack,
  TextField,
  Typography,
  Alert,
  IconButton,
  Snackbar,
} from "@mui/material";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import DeleteIcon from "@mui/icons-material/Delete";
import { useCallback, useContext, useEffect, useState } from "react";
import { manageMedia } from "../../../utility/mediaHandler";
import { styled } from "styled-components";
import { DataContext, TypeContext } from "../../../App";
import axios from "axios";
import { API_HOST } from "../../Api/url";
import { checkMultipleImageFileDimensions } from "../../../utility/mediaHandler";
import { IMAGE_DIMENSIONS } from "../../../utility/mediaHandler";
import CropEasy from "../../../utility/cropEasy";
import EditIcon from '@mui/icons-material/Edit';

const Wrapper = styled.div`
  margin: 20px 0px 20px 0px;
`;

const CropUploadButton = styled.button`
padding:5px 10px;
border:0;
border-radius: 8px;
font-weight:${(props) => (props.state ? "500" : "400")};
color:${(props) => (props.state ? "white" : "black")};
background-color: ${(props) => (props.state ? "SlateBlue" : "transparent")};
transition: all 0.4s ease-in-out;
border:${(props) => (props.state ? "2px solid transparent" : "2px solid black")};
`;
const CropUploadButtonConatiner = styled.div`
padding:5px 20px 0px 20px;
display: flex;
height:60px;
justify-content: flex-start;
align-items: center;
gap:12px;
`;

function PhotoForm({
  isOpen,
  onClose,
  onFilesUpload,
  isVendor,
  data,
  fetchData,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarSeverity,
}) {
  const [imageLoader, setImageLoader] = useState(false)
  const [imageErrorSuccess, setImageErrorSuccess] = useState({ error: false, success: false })
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [photoURL, setPhotoURL] = useState(null)
  const [file, setFile] = useState(null)
  const [openCrop, setOpenCrop] = useState(false)
  const [withEdit, setWithEdit] = useState(false)

  const uploadFiles = useCallback(async () => {
    setImageLoader(true)
    const [allImagesPassed, imageResults] =
      await checkMultipleImageFileDimensions(
        selectedFiles,
        IMAGE_DIMENSIONS.PORTFOLIO_PHOTO
      );

    // if (!allImagesPassed) {
    //   alert(
    //     `Please upload images of size (${IMAGE_DIMENSIONS.PORTFOLIO_PHOTO.imgWidth}x${IMAGE_DIMENSIONS.PORTFOLIO_PHOTO.imgHeight})`
    //   );
    //   return;
    // }
    const objectKeyGenFn = (fileHandle, fileIdx, reqMethod) => {
      const { name } = fileHandle;

      const fileNameSegments = name.split(".");
      const fileName = fileNameSegments.slice(0, -1).join("");
      const fileExt = fileNameSegments[fileNameSegments.length - 1] || "";

      if (!isVendor) {
        return `media/venues/${data.venueSlug}/portfolio/photos/${fileName}.${fileExt}`;
      } else {
        return `media/vendors/${data.vendorSlug}/portfolio/photos/${fileName}.${fileExt}`;
      }
    };

    const mediaResponses = await manageMedia({
      mediaFiles: selectedFiles,
      objectKeyGenFn: objectKeyGenFn,
      fileMethod: "PUT",
    });

    await Promise.all(
      mediaResponses.map(async (item) => {
        await axios.post(
          `${API_HOST}/api/v1/admin/${isVendor ? "vendors" : "venues"}/${isVendor ? data.vendorSlug : data.venueSlug
          }/portfolio/photos`,
          {
            objectKey: item.objectKey,
          }
        );
      }),
      setSnackbarMessage("Photos updated successfully"),
      setSnackbarSeverity("success"),
      setSnackbarOpen(true)
    );
    setSelectedFiles([])
    setImageLoader(false)
    setImageErrorSuccess({ error: false, success: true })
    onClose();
    setFile(null)
    setPhotoURL(null)
    setImageErrorSuccess({ error: false, success: false })

    await fetchData();
  }, [onClose, onFilesUpload, selectedFiles]);

  function closePhotoBox() {
    setSelectedFiles([])
    setImageLoader(false)
    setFile(null)
    setPhotoURL(null)
    setImageErrorSuccess({ error: false, success: false })
    onClose()
  }


  return (
    <>
      {openCrop ? <CropEasy photoURL={photoURL} file={file} setOpenCrop={setOpenCrop} setFile={setSelectedFiles} uploadCoverImage={uploadFiles} photoAlbum={true} setPhotoURL={setPhotoURL} /> : null}


      <Dialog open={isOpen} onClose={closePhotoBox}>
        <CropUploadButtonConatiner >
          <CropUploadButton onClick={() => {
            if (selectedFiles.length == 0) {
              setWithEdit(true)
            }
          }} state={withEdit}><EditIcon /> Edit & Upload</CropUploadButton>
          <CropUploadButton onClick={() => {
            if (!file)
              setWithEdit(false)
          }} state={!withEdit}>Upload</CropUploadButton>
        </CropUploadButtonConatiner>
        <DialogTitle sx={{ fontFamily: "Poppins" }}>
          Upload New Photos
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ alignItems: "center" }} spacing={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type={"file"}
                inputProps={{
                  accept: ".jpg, .jpeg, .png",
                  multiple: !withEdit,

                }}
                fullWidth
                onChange={(e) => {
                  setImageLoader(false)
                  setImageErrorSuccess({ error: false, success: false })
                  if ((e.target.files || []).length > 0) {
                    if (withEdit) {
                      setFile(e.target.files[e.target.files.length - 1])
                      setPhotoURL(URL.createObjectURL(e.target.files[e.target.files.length - 1]))
                      setOpenCrop(true)
                    } else {
                      const fileList = e.target.files;

                      const fileArray = Array.from(fileList);

                      setSelectedFiles(fileArray);
                    }
                  }
                }}
              />
              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "15px" }}><p style={{ color: "red", fontSize: "13px" }}>Allowed file types: JPG, JPEG, PNG </p>{imageLoader ? <CircularProgress style={{ width: "20px", height: "20px" }} /> : null}{imageErrorSuccess.error ? <ErrorIcon style={{ width: "25px", height: "25px", color: "red" }} /> : null}{imageErrorSuccess.success ? <CheckCircleIcon style={{ width: "25px", height: "25px", color: "green" }} /> : null}</div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "space-between",
            px: 4,
          }}
        >
          <Button
            variant={"contained"}
            sx={{
              fontFamily: "Poppins",
              textTransform: "capitalize",
            }}
            onClick={() => {
              setSelectedFiles([])
              setImageLoader(false)
              setFile(null)
              setPhotoURL(null)
              setImageErrorSuccess({ error: false, success: false })
              onClose();
            }}
            color={"error"}
          >
            Close Form
          </Button>
          <Button
            variant={"contained"}
            sx={{
              my: 2,
              fontFamily: "Poppins",
              textTransform: "capitalize",
            }}
            onClick={uploadFiles}
            disabled={selectedFiles.length === 0}
          >
            Upload Photos
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const Photos = () => {
  const [showForm, setShowForm] = useState(false);
  const [portfolioPhotos, setPortfolioPhotos] = useState([]);
  const { isVendor } = useContext(TypeContext);
  const { vendorData } = useContext(DataContext);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const fetchData = async () => {
    let response;
    if (isVendor) {
      try {
        response = await axios.get(
          `${API_HOST}/api/v1/vendors/${vendorData.vendorSlug}/portfolio/photos`
        );
        setPortfolioPhotos(response.data.portfolioPhotos);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        response = await axios.get(
          `${API_HOST}/api/v1/venues/${vendorData.venueSlug}/portfolio/photos`
        );
        setPortfolioPhotos(response.data.portfolioPhotos);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDeletePhoto = async (photoId) => {
    if (!isVendor) {
      try {
        await axios.delete(
          `${API_HOST}/api/v1/admin/venues/${vendorData.venueSlug}/portfolio/photos/${photoId}`
        );
        fetchData();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await axios.delete(
          `${API_HOST}/api/v1/admin/vendors/${vendorData.vendorSlug}/portfolio/photos/${photoId}`
        );
        fetchData();
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onFilesUpload = (uploadedFiles) => {
    setPortfolioPhotos((existingPhotos) => {
      return [...uploadedFiles, ...existingPhotos];
    });
  };

  return (
    <Wrapper>
      <Stack spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant={"h5"} sx={{ fontFamily: "Poppins" }}>
              Portfolio - Photos
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <Button
              variant={"contained"}
              onClick={() => {
                setShowForm(true);
              }}
              sx={{
                boxShadow: 0,
                fontFamily: "Poppins",
                textTransform: "capitalize",
              }}
            >
              Upload Photo
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontFamily: "Poppins" }}>
              Your portfolio brings out the best works of art from your
              vendors/venues. upload select photos that will be shown on
              vendor/venues profile
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <PhotoForm
            isOpen={showForm}
            onClose={() => {
              setShowForm(false);
            }}
            onFilesUpload={onFilesUpload}
            isVendor={isVendor}
            data={vendorData}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarSeverity={setSnackbarSeverity}
            fetchData={fetchData}
          />
        </Grid>
        <ImageList cols={3} gap={8} variant={"masonry"}>
          {portfolioPhotos.map((photoObj) => {
            const { photoId, photoURL } = photoObj;
            return (
              <ImageListItem key={photoId}>
                <img
                  src={photoURL}
                  alt={`Portfolio ${photoId}`}
                  style={{
                    borderRadius: 16,
                    cursor: "pointer",
                    transition: "all 0.3s ease-in-out",
                  }}
                  className="portfolio-image"
                />
                <IconButton
                  aria-label="delete"
                  color="error"
                  style={{
                    position: "absolute",
                    top: "85%",
                    left: "90%",
                    transform: "translate(-50%, -50%)",
                    opacity: 1,
                    transition: "all 0.3s ease-in-out",
                  }}
                  className="delete-icon"
                  onClick={() => handleDeletePhoto(photoId)}
                >
                  <DeleteIcon />
                </IconButton>
              </ImageListItem>
            );
          })}
        </ImageList>
        <Typography variant={"h6"} sx={{ textAlign: "center" }}>
          {portfolioPhotos.length === 0 && (
            <i>No photos found, add photos to get started</i>
          )}
        </Typography>{" "}
      </Stack>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

export default Photos;
