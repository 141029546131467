import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Button, Modal, Typography, Alert, Snackbar, } from "@mui/material";
import {
  ChangeMembershipContext,
  DataContext,
  PendingContext,
  ViewContext,
} from "../../../App";
import NewUpdateMembershipModal from "../membership/newUpdateMembershipModel"
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import { TypeContext } from "../../../App";
import { categoriesList, vendorSubcategoryList } from "../categories/categoriesList";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { API_HOST } from "../../Api/url";
import moment from 'moment-timezone';
const CardWrapper = styled.div`
border:${(props) => props.status == 3 ? "4px solid red" : ""};
position: relative;
  height: 340px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  display: flex;
margin-bottom: 30px;
  box-shadow: 
    0px 0px 5px 0px rgba(0,0,0,0.5),
    0px 0px 5px 2px rgba(0,0,0,0.2);
    
    
  
`;
const ExpiryDateRibbon = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  --r: .4em; /* control the ribbon shape (the radius) */
  --c: red;
z-index: 99;
  position: absolute;
  bottom: 5px;
  left: calc(-1 * var(--r));
  line-height: 1.8; 
  padding: calc(2 * var(--r)) .5em 0;
  border-radius: var(--r) 0 0 var(--r);
  background: 
    radial-gradient(100% 50% at left, var(--c) 98%, #0000 101%) 100% 100% / .5lh calc(100% - 2 * var(--r)),
    radial-gradient(100% 50% at right, #0005 98%, #0000 101%) 0 0 / var(--r) calc(2 * var(--r)),
    conic-gradient(at var(--r) calc(2 * var(--r)), #0000 25%, var(--c) 0) 0 0 / calc(101% - .5lh) 100%;
  background-repeat: no-repeat;   
`;
const StyledImage = styled.img`
height: 100%;
  width: 310px;
  object-fit: cover;
  border-radius: 16px;
  z-index: 99999;
  margin: auto;
`;

const ImageWrapper = styled.div`
position:relative;
z-index: 9;
  height: 100%;
`;

const DetailsWrapper = styled.div`
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: inherit;
`;

const Title = styled.p`
  color: #222;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin: 12px 0px 0px 0;
`;

const Description = styled.p`
  color: #777;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 12px 0 0px 0;
`;

const Text = styled.p`
  color: #130f26;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 112.5% */
  margin: 12px 0 0px 0;
  text-transform: capitalize;
`;

const Span = styled.span`
  color: #777777;
`;

const ButtonsWrapper = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const Textarea = styled.textarea`
  padding: 5px;
  resize: none;
  height: 100px;
  border-radius: 5px;
  color: #818181;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  width: 100%;
  margin-top: 16px;
`;

const buttonStyles = {
  width: "100%",
  fontFamily: "Poppins !important",
  textTransform: "capitalize",
};

export const boxStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid rgba(0, 0, 0, 0.2)",
  boxShadow: 24,
  p: 4,
};



const RotateBox = styled.div`
overflow: hidden;
/* background: skyblue; */
width:40px;
min-height: 40px;
border-radius: 10px;
position: absolute;
top:8px;
right:6px;
display: flex;
justify-content: center;
align-items: center;
gap:8px;
flex-direction:column;
padding:5px 0px;
backdrop-filter: blur(0.5px);
transition: all 0.3s ease-in-out;
  transform-origin: top right;
`;
const BackdropBox = styled.div`
width: 100%;
height: 100%;
backdrop-filter: blur(2px);
position: absolute;
top:0;
z-index: 9999;
display: flex;
justify-content: center;
align-items: center;
`;
const RoatteIcon = styled.div`
background-color:lightgreen;
border-radius: 44px;
&:hover {
    transform: scale(0.95);
  }

  &:active {
    transform: scale(0.92);
  }
  box-shadow: 2px 2px 2px 0px green;


`;
const Contianer = styled.div`
position: absolute;
display: flex;
top:0;
right:0;
width: fit-content;
z-index: 99;
gap:10px;
justify-content: flex-end;
align-items: center;
margin-right:4%;


`;
const DateAndTime = styled.div`
background-color:tomato;
padding: 2px 15px;
border-radius: 5px;
color: white;
font-weight: 500;

`;
const Ribbon = styled.div`
z-index: 300;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  --f: .7em;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1.8;
  padding-inline: 1lh;
  padding-bottom: var(--f);
  border-image: conic-gradient(#0008 0 0) 51%/var(--f);
  clip-path: polygon(
    100% calc(100% - var(--f)),100% 100%,calc(100% - var(--f)) calc(100% - var(--f)),var(--f) calc(100% - var(--f)), 0 100%,0 calc(100% - var(--f)),999px calc(100% - var(--f) - 999px),calc(100% - 999px) calc(100% - var(--f) - 999px));
  transform: translate(calc((cos(45deg) - 1)*100%), -100%) rotate(-45deg);
  transform-origin: 100% 100%;
  background-color: ${props => props.status == "APPROVED" ? 'green' : props.status == "PENDING" ? "#FFAE42" : props.status == "UNLISTED" ? "tomato" : "red"}; /* the main color  */
`;
const Card = ({
  data,
  isPendingPage,
  isApproved,
  isRejected,
  ticket,
  isPremium,
  isBasic,
  isHighLighter,
  handleDelete,
  handleReject,
  handleUnlist,
  isUnlistedPage,
  handleApprove,
  setData,
  isListing,
}) => {
  const [showRotate, setShowRotate] = useState(false)
  const [processStarted, setProcessStarted] = useState(false)
  const { isView, setIsView } = useContext(ViewContext);
  const { isVendor } = useContext(TypeContext);
  const { setVendorData } = useContext(DataContext);
  const [isRejectedClicked, setIsRejectedClicked] = useState(false);
  const [rejectedMessage, setRejectedMessage] = useState("");
  const { setIsPending } = useContext(PendingContext);
  const [open, setOpen] = useState(false);
  const [showUpdateMembershipModel, setShowUpdateMembershipModel] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [howMuchDate, setHowMuchDate] = useState();
  const [expType, setExpType] = useState(4)
  const maxLength = 100;
  const description = data.venueDescription || data.vendorDescription;
  //  console.log(data,"ye dat aha")
  let shortDescription;
  if (description.length > maxLength) {
    shortDescription = description.substring(0, maxLength) + ", ...";
  } else {
    shortDescription = description;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteConfirm = () => {
    if (isVendor) {
      const slug = data.vendorSlug;
      handleDelete(slug);
    } else {
      const slug = data.venueSlug;
      handleDelete(slug);
    }
    setOpen(false);
  };
  async function changeRotateFunction(key, side) {
    try {
      // console.log(key)
      const match = key.match(/amazonaws\.com\/(.*)/);
      const extractedString = match ? match[1] : null;
      if (extractedString == "static/cover-image.svg") {
        setSnackbarMessage(`This Image Can't Be Rotate`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return
      }


      if (!extractedString || !side)
        return

      setShowRotate(false)
      setProcessStarted(true)
      const body = {
        key: extractedString,
        side: side
      }

      await axios.post(
        `${API_HOST}/api/v1/media/rotatewithkey`, body
      );
      setSnackbarMessage(`Image successfully Roated ${side} Side, Result Will Be Reflect In Few Seconds`);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

    } catch (error) {
      setSnackbarMessage(`Image Fail To Roated ${side} Side, Please Try After SomeTime`);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setProcessStarted(false)
    }

  }

  function CheckExpireOfPremiumAndFeature(premiumAndFeature) {
    // console.log(premiumAndFeature)
    if (typeof premiumAndFeature !== undefined && (premiumAndFeature == 0 || premiumAndFeature == 1 || premiumAndFeature == 2)&&(isPremium||isBasic||isHighLighter)) {
      const currentDate = new Date(new Date().toISOString())
      let findVendorOrVenue = {}
      if (data?.vendorMetadata) {
        const find = data.vendorMetadata.find((data, index) => data.propertyName == "VENDOR_PREMIUM_DATE")
        if (find) {
          findVendorOrVenue = find
        }
      } else {
        const find = data.venueMetadata.find((data, index) => data.propertyName == "VENUE_PREMIUM_DATE")
        if (find) {
          findVendorOrVenue = find
        }
      }
      if (!findVendorOrVenue?.propertyValue)
        return 4

      const premiumDate = new Date(findVendorOrVenue.propertyValue)
      const differnce = Math.floor((premiumDate - currentDate) / (1000 * 60 * 60 * 24)) + 1
      // console.log(premiumAndFeature, "premium", premiumDate, "current", currentDate, "diff", differnce, "propery", data?.vendorMetadata?.[0]?.propertyValue, data?.venueMetadata?.[0]?.propertyValue)
      setHowMuchDate(differnce)
      // console.log(differnce)
      if (differnce > 60)
        return 1
      if (differnce > 30)
        return 2
      if (differnce < 30 && differnce > 0)
        return 2
      if (differnce <= 0)
        return 3

      return 4
    } else {
      return 4
    }
  }

  useEffect(() => {
    const expTypes = CheckExpireOfPremiumAndFeature(!isVendor ? data.venuePremiumStatus : data.vendorPremiumStatus)
    setExpType(expTypes)
  })
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  function ChnageIndianDateAndTime(timestamp) {
    return moment(timestamp).tz('Asia/Kolkata').format("DD MMMM YYYY hh:mm A");
  }
  // console.log(data.venuePremiumStatus,data.vendorPremiumStatus,"check both")
  // console.log(data)
  return (
    <>
      {
        showUpdateMembershipModel && <NewUpdateMembershipModal data={data} setShowUpdateMembershipModel={setShowUpdateMembershipModel} showUpdateMembershipModel={showUpdateMembershipModel} />
      }
      <CardWrapper status={expType}>
        <Ribbon status={isVendor ? data.vendorListingStatus : data.venueListingStatus}>
          {
            isVendor ? data.vendorListingStatus : data.venueListingStatus
          }
        </Ribbon>
        {
          expType !== 4 && <ExpiryDateRibbon>
            {
              howMuchDate <= 0 ? "Expired" : `${howMuchDate} More Days`
            }

          </ExpiryDateRibbon>
        }

        <Contianer>
          {data.createdAt && <DateAndTime>
            {
              data.createdAt && ChnageIndianDateAndTime(data.createdAt)
            }

          </DateAndTime>}
        </Contianer>

        <ImageWrapper onMouseOver={() => {
          if (!processStarted)
            setShowRotate(true)
        }} onMouseLeave={() => {
          if (!processStarted)
            setShowRotate(false)
        }} >
          {processStarted ? <BackdropBox>
            <CircularProgress />
          </BackdropBox> : null}
          {showRotate ? <RotateBox >

            <RoatteIcon><RotateRightIcon onClick={() => changeRotateFunction(isVendor ? data.vendorCoverImageURL : data.venueCoverImageURL, "right")} style={{ width: "30px", height: "30px", cursor: "pointer" }} /></RoatteIcon>
            <RoatteIcon><RotateLeftIcon onClick={() => changeRotateFunction(isVendor ? data.vendorCoverImageURL : data.venueCoverImageURL, "left")} style={{ width: "30px", height: "30px", cursor: "pointer" }} /></RoatteIcon>
          </RotateBox> : null
          }
          <StyledImage
            src={isVendor ? data.vendorCoverImageURL : data.venueCoverImageURL}
            alt="demo"
          />
        </ImageWrapper>
        <DetailsWrapper>
          <Title>{data.vendorName || data.venueName}</Title>
          <Description>
            <Description>{shortDescription}</Description>
          </Description>
          {isVendor?<Text>
            <Span>Category: </Span>
            {data && data.vendorCategory
              ? categoriesList.find(
                (item) => item.value === data.vendorCategory
              )?.category
              : "Venue"}
          </Text>:null}
          {isVendor?<Text>
            <Span>Sub Category: </Span>
            {data && data.vendorSubcategory
              ? vendorSubcategoryList.find(
                (item) => item.vendorSubcategory === data.vendorSubcategory
              )?.subcategoryText
              : "Venue"}
          </Text>:null}
          <Text>
            <Span>City: </Span> {isVendor ? data?.vendorCity : data.venueCity}
          </Text>
          {isPendingPage && <Text>Status: Pending</Text>}
          {isApproved && <Text>Status: Approved</Text>}
          {isRejected && <Text>Admin Comment: {ticket?.adminComment}</Text>}
          {isListing && (
            <Text>
              <Span> Membership Type:</Span>
              {isVendor
                ? (data.vendorPremiumStatus === 0 && " Premium") ||
                (data.vendorPremiumStatus === 1 && " Featured") ||
                (data.vendorPremiumStatus === 2 && " Basic")
                : (data.venuePremiumStatus === 0 && " Premium") ||
                (data.venuePremiumStatus === 1 && " Featured") ||
                (data.venuePremiumStatus === 2 && " Basic")}
            </Text>
          )}
          {isPremium && (
            <Text>
              <Span> Membership Type:</Span>
              {isVendor
                ? data.vendorPremiumStatus === 0 && " Premium"
                : data.venuePremiumStatus === 0 && " Premium"}
            </Text>
          )}
          {isHighLighter && (
            <Text>
              <Span> Membership Type:</Span>
              {isVendor
                ? data.vendorPremiumStatus === 1 && " Featured"
                : data.venuePremiumStatus === 1 && " Featured"}
            </Text>
          )}
          {isBasic && (
            <Text>
              <Span> Membership Type:</Span>
              {isVendor
                ? data.vendorPremiumStatus === 2 && " Basic"
                : data.venuePremiumStatus === 2 && " Basic"}
            </Text>
          )}
          {(
            <Text>
              <Span>Membership Start Date:</Span>
              {data?.premiumStartingDate ? new Date(data.premiumStartingDate
              )
                .toLocaleDateString("en-GB")
                .split("/")
                .join("/") : "Is Not Approved Once"}
            </Text>

          )}
          
          {(
            <Text>
              <Span>Membership Expiry Date: </Span>
              {
                isVendor
                  ? data?.vendorMetadata?.find(
                    (item) => item.propertyName === "VENDOR_PREMIUM_DATE"
                  )?.propertyValue ? new Date(data?.vendorMetadata?.find(
                    (item) => item.propertyName === "VENDOR_PREMIUM_DATE"
                  )?.propertyValue)
                    .toLocaleDateString("en-GB")
                    .split("/")
                    .join("/") : "Is Not Approved Once"
                  : data?.venueMetadata?.find(
                    (item) => item.propertyName === "VENUE_PREMIUM_DATE"
                  )?.propertyValue ? new Date(data?.venueMetadata?.find(
                    (item) => item.propertyName === "VENUE_PREMIUM_DATE"
                  )?.propertyValue)
                    .toLocaleDateString("en-GB")
                    .split("/")
                    .join("/") : "Is Not Approved Once"
              }
            </Text>

          )}

        </DetailsWrapper>
        <ButtonsWrapper>
          {isPendingPage ? (
            <>
              <Button
                variant="contained"
                className="listingButtons"
                color="success"
                style={buttonStyles}
                onClick={() =>
                  handleApprove(isVendor ? data.vendorSlug : data.venueSlug, data.premiumStartingDate)
                }
              >
                Approve
              </Button>
              <Button
                variant="contained"
                className="listingButtons"
                color="error"
                sx={buttonStyles}
                onClick={() => setIsRejectedClicked(true)}
              >
                Reject
              </Button>
              <Button
                variant="contained"
                className="listingButtons"
                color="primary"
                sx={buttonStyles}
                onClick={() => {
                  setVendorData(data);
                  setIsView(!isView);
                  setIsPending(true);
                }}
              >
                View
              </Button>{" "}
            </>
          ) : isApproved || isRejected ? (
            <>
              <Button
                variant="contained"
                className="listingButtons"
                color="success"
                sx={buttonStyles}
                href={`https://maangalbazaar.com/${isVendor ? "vendor_profile" : "venue_profile"
                  }/${isVendor ? data.vendorSlug : data.venueSlug}`}
                target="_blank"
              >
                View
              </Button>
              <Button
                variant="contained"
                className="listingButtons"
                color="primary"
                sx={buttonStyles}
                onClick={() => {
                  setVendorData(data);
                  setIsView(!isView);
                  setIsPending(false);
                }}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                className="listingButtons"
                color="warning"
                sx={buttonStyles}
                onClick={() =>
                  handleUnlist(isVendor ? data.vendorSlug : data.venueSlug)
                }
              >
                Unlist
              </Button>{" "}
            </>
          ) : isPremium || isBasic || isHighLighter ? (
            <>
              <Button
                variant="contained"
                className="listingButtons"
                color="success"
                sx={buttonStyles}
                href={`https://maangalbazaar.com/${isVendor ? "vendor_profile" : "venue_profile"
                  }/${isVendor ? data.vendorSlug : data.venueSlug}`}
                target="_blank"
              >
                View
              </Button>
              <Button
                variant="contained"
                className="listingButtons"
                color="primary"
                onClick={() => {
                  setShowUpdateMembershipModel(true)
                }}
                sx={buttonStyles}
              >
                Update Membership
              </Button>{" "}
            </>
          ) : isUnlistedPage ? (
            <>
              <Button
                variant="contained"
                className="listingButtons"
                color="success"
                style={buttonStyles}
                onClick={() =>
                  handleApprove(isVendor ? data.vendorSlug : data.venueSlug)
                }
              >
                Approve
              </Button>
              <Button
                variant="contained"
                className="listingButtons"
                color="primary"
                sx={buttonStyles}
                onClick={() => {
                  setVendorData(data);
                  setIsView(!isView);
                  setIsPending(false);
                }}
              >
                View
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                className="listingButtons"
                color="success"
                href={`https://maangalbazaar.com/${isVendor ? "vendor_profile" : "venue_profile"
                  }/${isVendor ? data.vendorSlug : data.venueSlug}`}
                target="_blank"
                sx={buttonStyles}
              >
                View
              </Button>
              <Button
                variant="contained"
                className="listingButtons"
                color="primary"
                sx={buttonStyles}
                onClick={() => {
                  setVendorData(data);
                  setIsView(!isView);
                  setIsPending(false);
                }}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                className="listingButtons"
                color="error"
                sx={buttonStyles}
                onClick={handleClickOpen}
              >
                Delete
              </Button>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to delete this{" "}
                    {isVendor ? "Vendor" : "Venue"}?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handleDeleteConfirm} color="error">
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
              <Button
                variant="contained"
                className="listingButtons"
                color="warning"
                sx={buttonStyles}
                onClick={() =>
                  handleUnlist(isVendor ? data.vendorSlug : data.venueSlug)
                }
              >
                Unlist
              </Button>{" "}
            </>
          )}
        </ButtonsWrapper>
      </CardWrapper>
      <Modal
        open={isRejectedClicked}
        onClose={() => setIsRejectedClicked(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyles}>
          <Typography
            id="modal-modal-title"
            sx={{
              fontFamily: "Poppins",
            }}
          >
            Enter Reason of Rejection for Venue/Vendor
          </Typography>
          <Textarea
            placeholder="enter message here"
            value={rejectedMessage}
            onChange={(e) => setRejectedMessage(e.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            sx={{
              width: "100%",
              marginTop: "16px",
              textTransform: "capitalize",
              fontFamily: "Poppins",
            }}
            onClick={() =>
              handleReject(
                isVendor ? data.vendorSlug : data.venueSlug,
                rejectedMessage
              )
            }
            disabled={rejectedMessage === ""}
          >
            Submit
          </Button>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Card;
