import React, { useEffect, useState } from "react";
import Card from "./Card";
import styled from "styled-components";
import { Alert, Grid, Snackbar } from "@mui/material";
import axios from "axios";
import { API_HOST } from "../../Api/url";
import { makeAPIRequest, manageMedia } from "../../../utility/mediaHandler";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Wrapper = styled.div`
  margin: 20px 0px 20px 0px;
  display: flex;
  flex-wrap: wrap;
  gap:25px;
`;

const StyledNo = styled.p`
  margin: 12px 0px;
  font-size: 18px;
  font-weight: 500;
  font-family: Poppins;
`;

const Heading1 = styled.h1`
font-weight: 500;
font-size: 28px;
width: 100%;
height: fit;
text-align: center;
border-bottom: 2px solid black;
padding: 5px;
`;

const AdContainer = styled.div`
padding: 15px;
display: flex;
gap:25px;
overflow-x: scroll;
margin-bottom: 40px;

`;

const Advertisements = () => {
  const [open, setOpen] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = useState(Array(2).fill(null));
  const [previewImages, setPreviewImages] = useState(Array(2).fill(null));
  const [data, setData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleFileChange = (index) => (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const newSelectedFiles = [...selectedFiles];
      newSelectedFiles[index] = event.target.files[0];
      setSelectedFiles(newSelectedFiles);

      const reader = new FileReader();
      reader.onloadend = () => {
        const newPreviewImages = [...previewImages];
        newPreviewImages[index] = reader.result;
        setPreviewImages(newPreviewImages);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleEditClick = (index) => {
    const fileInput = document.querySelector(`#file-input-${index}`);
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleDeleteClick = (index) => {
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles[index] = null;
    setSelectedFiles(newSelectedFiles);

    const newPreviewImages = [...previewImages];
    newPreviewImages[index] = null;
    setPreviewImages(newPreviewImages);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_HOST}/api/v1/meta/advertisements`
      );
      setData(response.data.advertisementData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  }

  const handleClick = async (selectedFile, id, url) => {
    try {
      handleOpen()
      manageMedia({
        mediaFiles: [selectedFile],
        fileMethod: "PUT",
        objectKeyGenFn: (fileHandle, fileIdx, fileMethod) => {
          return `media/advertisements/${id}`;
        },
      });

      const response = await makeAPIRequest({
        requestEndpoint: "/meta/advertisements",
        requestMethod: "POST",
        bodyParams: {
          adImageObjectKey: `media/advertisements/${id}`,
          adURL: url,
          adIndex: id,
        },
      });


      if (response.responseData.responseStatus == "SUCCESS") {
        fetchData();
        setSnackbarMessage("Advertisements Upload successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else {
        fetchData();
        setSnackbarMessage("Fail To Upload, Try After Sometime");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {

      fetchData();
      setSnackbarMessage("Fail To Upload, Try After Sometime");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);

    }finally{
      handleClose()
    }

  };

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Wrapper>
        <Heading1>Advertisement 1</Heading1>
        <AdContainer>
          {[...Array(5)].map((_, index) => {
            const item = data[index];
            return (
              <Grid item xs={6} key={`${item}-${index}`}>
                <Card
                  id={index}
                  selectedFile={selectedFiles[index]}
                  previewImage={previewImages[index]}
                  onFileChange={handleFileChange(index)}
                  onEditClick={() => handleEditClick(index)}
                  onDeleteClick={() => handleDeleteClick(index)}
                  data={item}
                  handleClick={handleClick}
                />
              </Grid>
            );
          })}
        </AdContainer>
        <Heading1>Advertisement 2</Heading1>
        <AdContainer>
          {[...Array(5)].map((_, index) => {
            const item = data[index + 5];
            return (
              <Grid item xs={6} key={`${item}-${index + 5}`}>
                <Card
                  id={index + 5}
                  selectedFile={selectedFiles[index + 5]}
                  previewImage={previewImages[index + 5]}
                  onFileChange={handleFileChange(index + 5)}
                  onEditClick={() => handleEditClick(index + 5)}
                  onDeleteClick={() => handleDeleteClick(index + 5)}
                  data={item}
                  handleClick={handleClick}
                />
              </Grid>
            );
          })}
        </AdContainer>



        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Wrapper>
    </>
  );
};

export default Advertisements;
