import SocialLink from './SocialLink';
import { Divider, Grid } from '@mui/material';

export default function SocialQuestions() {
  return (
    <>
      {['FB', 'IG', 'WS', 'YT'].map((socialLinkType) => {
        return (
          <>
            <SocialLink linkType={socialLinkType} />
          </>
        );
      })}
    </>
  );
}
