import { Button } from '@mui/material';
import React, { useState } from 'react';
import { styled } from 'styled-components';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import StarIcon from '@mui/icons-material/Star';

const CardWrapper = styled.div`
background-color: white;
position: relative;
  border: 1px solid #0b63f8;
  /* height: 400px; */
  padding:38px 20px 20px 20px;
  display: flex;
  max-width: 490px;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 1px 1px 4px skyblue;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  flex-grow: 1;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-family: Poppins;
  font-size: 13px;
`;

const Input = styled.input`
  padding: 5px;
  border-radius: 5px;
  color: black;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
`;

const Textarea = styled.textarea`
  padding: 5px;
  resize: none;
  border-radius: 5px;
  height: 100px;
  border-radius: 5px;
  color: black;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
`;
const ReviewUpperSection = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
padding: 0px 10px;
position: absolute;
width: 100%;
height: 35px;
top: 0;
left:0;
background-color: skyblue;

`

const ReviewCard = ({ data, id, onUploadSuccess, handleClick, deleteReview, updateMainReviewAndStatusReview }) => {
  const [authorName, setAuthorName] = useState(
    data ? data.reviewAuthorDisplayName : ''
  );
  const [reviewTitle, setReviewTitle] = useState(data ? data.reviewTitle : '');
  const [reviewDescription, setReviewDescription] = useState(
    data ? data.reviewDescription : ''
  );
  const [reviewAuthorPhotoObjectKey, setReviewAuthorPhotoObjectKey] = useState(
    data ? data.reviewAuthorPhotoObjectKey : ''
  );
  const [selectedFile, setSelectedFile] = useState();

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedFile(file);
    }
  };
  // console.log(data)
  return (
    <CardWrapper>
      <InputWrapper>
        <ReviewUpperSection>
          <section style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
            {
              data?.status == "LIST" ? <RemoveRedEyeIcon onClick={() => {
                if (data._id)
                  updateMainReviewAndStatusReview(data._id, { "status": "UNLIST" })
              }} style={{ cursor: "pointer" }} /> : <VisibilityOffIcon onClick={() => {
                if (data._id)
                  updateMainReviewAndStatusReview(data._id, { "status": "LIST" })
              }} style={{ cursor: "pointer" }} />
            }
            {
              data?.mainReview ? <StarIcon onClick={() => {
                if (data._id)
                  updateMainReviewAndStatusReview(data._id, { "mainReview": false })
              }} style={{ color: "yellow", cursor: "pointer" }} /> : <StarIcon onClick={() => {
                if (data._id)
                  updateMainReviewAndStatusReview(data._id, { "mainReview": true })
              }} style={{ cursor: "pointer" }} />
            }

          </section>

          <DeleteIcon onClick={() => {
            if (data._id)
              deleteReview(data._id)
          }} style={{ color: "red", cursor: "pointer" }} />

        </ReviewUpperSection>
        <Label htmlFor='name'>Reviewer Name</Label>
        <Input
          type='text'
          id='name'
          placeholder='Type here...'
          value={authorName}
          onChange={(e) => setAuthorName(e.target.value)}
        />
      </InputWrapper>
      <InputWrapper>
        <Label htmlFor='title'>Review Title</Label>
        <Input
          type='text'
          id='title'
          placeholder='Type here...'
          value={reviewTitle}
          onChange={(e) => setReviewTitle(e.target.value)}
        />
      </InputWrapper>
      <InputWrapper>
        <Label htmlFor='description'>Description</Label>
        <Textarea
          id='description'
          cols='30'
          rows='10'
          placeholder='Type here...'
          value={reviewDescription}
          onChange={(e) => setReviewDescription(e.target.value)}
        ></Textarea>
      </InputWrapper>
      <InputWrapper>
        <Label htmlFor='file'>Add Reviewer Photo</Label>
        <p style={{ color: "red", fontSize: "8px" }}>Allowed file types: JPG, JPEG, PNG </p>
        <Input
          type='file'
          id='file'
          accept=".jpg, .jpeg, .png"
          onChange={handleFileChange}
        />
      </InputWrapper>
      <Button
        variant='contained'
        sx={{
          boxShadow: 'none',
          textTransform: 'capitalize',
          fontFamily: 'Poppins',
        }}
        onClick={() =>
          handleClick(
            selectedFile,
            id,
            reviewTitle,
            reviewDescription,
            authorName,
            data?._id
          )
        }
      >
        Upload
      </Button>
    </CardWrapper>
  );
};

export default ReviewCard;
