import { Button, Divider, Grid, TextField, Typography } from '@mui/material';
import { useValueSelector } from './hook';
import {
  selectMetadata,
  zipMetadata,
} from '../../../../utility/schemaMetadata';
import { Link } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { StatusContext } from '../../../../App';
import SocialQuestions from '../SocialQuestions';

function DateQuestions() {
  const [serviceMetadata, setServiceMetadata] = useValueSelector({
    defaultValue: () => {
      return [];
    },
    venueValue: (venueData) => {
      return [];
    },
    vendorValue: (vendorData) => {
      return vendorData.vendorMetadata;
    },
    updateVendorValue: (prevValue, partialValue) => {
      return {
        ...prevValue,
        vendorMetadata: zipMetadata(prevValue.vendorMetadata, partialValue),
      };
    },
    updateVenueValue: (prevValue, partialValue) => {
      return prevValue;
    },
  });

  const VENDOR_SERVICE_DATE = selectMetadata(
    serviceMetadata,
    'VENDOR_SERVICE_DATE',
    new Date().toISOString()
  );

  const dateValue = (inputVal) => {
    const parsedInputDate = new Date(inputVal);
    const dateElements = [
      parsedInputDate.getFullYear(),
      (parsedInputDate.getMonth() + 1).toString().padStart(2, '0'),
      parsedInputDate.getDate().toString().padStart(2, '0'),
    ];
    return dateElements.join('-');
  };

  return (
    <>
      <Grid item xs={12} md={4}>
        <Typography>
          <b>Since when are you providing services?</b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          fullWidth
          sx={{ fontFamily: 'Poppins' }}
          type={'date'}
          value={dateValue(VENDOR_SERVICE_DATE)}
          onChange={(e) => {
            setServiceMetadata([
              ...serviceMetadata,
              {
                propertyName: 'VENDOR_SERVICE_DATE',
                propertyType: 'STRING',
                propertyValue: new Date(e.target.value).toISOString(),
              },
            ]);
          }}
        />
      </Grid>
    </>
  );
}

export function CommonQuestions({ setSelectedTab }) {
  const { setTab } = useContext(StatusContext);

  return (
    <>
      <SocialQuestions />
      <DateQuestions />
      <Grid item xs={12}>
        <Divider sx={{ my: 2 }} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography>
          <b>Add or update pricing</b>
        </Typography>
      </Grid>

      <Grid item xs={12} md={8}>
        <Button
          onClick={() => {
            setTab('pricing');
            setSelectedTab('pricing');
          }}
          variant={'contained'}
        >
          Go to pricing.
        </Button>
      </Grid>
    </>
  );
}
