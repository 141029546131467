/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from "react";
import { DataContext, ListingContext, PendingContext, TypeContext } from "../../../App";
import { Button, Divider, Grid, Stack, TextField, Typography, Alert, Snackbar, Link, } from "@mui/material";
import axios from "axios";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { API_HOST } from "../../Api/url";
import { useServiceRender, useValueSelector, } from "../features/questionnaire/hook";
import { manageMedia } from "../../../utility/mediaHandler";
import { checkImageFileDimensions } from "../../../utility/mediaHandler";
import { IMAGE_DIMENSIONS } from "../../../utility/mediaHandler";
import LocationSelect from "./LocationSelect";
import AddIcon from "@mui/icons-material/Add";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CropEasy from "../../../utility/cropEasy";

const Profile = () => {
  const [imageLoader, setImageLoader] = useState(false)
  const [imageErrorSuccess, setImageErrorSuccess] = useState({ error: false, sucess: false })
  const { vendorData } = useContext(DataContext);
  const { isVendor } = useContext(TypeContext);
  const { isPending } = useContext(PendingContext);
  const { listingTickets, setListingTickets } = useContext(ListingContext);
  const [vendorDetails, setVendorDetails] = useState();
  const [changeEmailPhone, SetChangeEmailPhone] = useState(true)
  const [backDropComplete, setBackDropComplete] = useState(false)
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("")
  const [name, setName] = useState("")
  const [photoURL, setPhotoURL] = useState(null)
  const [file, setFile] = useState(null)
  const [openCrop, setOpenCrop] = useState(false)
  const photoAlbum = false;
  const [profileData, setBasicProfileData] = useValueSelector({


    defaultValue: () => {
      return {
        serviceSlug: "",
        serviceName: "",
        serviceDescription: "",
        serviceLocality: "",
        serviceImageObjectKey: "",
        serviceCoverImageURL: "",
      };
    },
    venueValue: (venueData) => {
      return {
        serviceSlug: venueData.venueSlug,
        serviceName: venueData.venueName,
        serviceDescription: venueData.venueDescription,
        serviceLocality: venueData.venueLocality,
        serviceImageObjectKey: venueData.venueCoverImageObjectKey,
        serviceCoverImageURL: venueData.venueCoverImageURL,
      };
    },
    vendorValue: (vendorData) => {
      return {
        serviceSlug: vendorData.vendorSlug,
        serviceName: vendorData.vendorName,
        serviceDescription: vendorData.vendorDescription,
        serviceLocality: "",
        serviceImageObjectKey: vendorData.vendorCoverImageObjectKey,
        serviceCoverImageURL: vendorData.vendorCoverImageURL,
      };
    },
    updateVendorValue: (prevValue, partialValue) => {
      const {
        serviceLocality,
        serviceDescription,
        serviceName,
        serviceImageObjectKey,
      } = partialValue;

      return {
        ...prevValue,
        vendorName: serviceName,
        vendorDescription: serviceDescription,
        vendorCoverImageObjectKey: serviceImageObjectKey,
      };
    },
    updateVenueValue: (prevValue, partialValue) => {
      const {
        serviceLocality,
        serviceDescription,
        serviceName,
        serviceImageObjectKey,
      } = partialValue;

      return {
        ...prevValue,
        venueLocality: serviceLocality,
        venueDescription: serviceDescription,
        venueName: serviceName,
        venueCoverImageObjectKey: serviceImageObjectKey,
      };
    },
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const {
    serviceName,
    serviceDescription,
    serviceLocality,
    serviceSlug,
    serviceCoverImageURL,
    serviceImageObjectKey,
  } = profileData;

  const uploadCoverImage = async (fileObj) => {
    setImageLoader(true);
    const [isImageValid, imageDimensions] = await checkImageFileDimensions(
      fileObj,
      IMAGE_DIMENSIONS.COVER_IMAGE
    );

    // if (!isImageValid) {
    //   alert(
    //     `Please upload cover image of size (${IMAGE_DIMENSIONS.COVER_IMAGE.imgWidth}x${IMAGE_DIMENSIONS.COVER_IMAGE.imgHeight}). Current image is (${imageDimensions.imgWidth}x${imageDimensions.imgHeight})`
    //   );
    //   return;
    // }
    const mediaResponses = await manageMedia({
      mediaFiles: [fileObj],
      fileMethod: "PUT",
      objectKeyGenFn: (fileHandle, fileIdx, reqMethod) => {
        const fileChunks = fileHandle.name.split(".");
        const fileExt = fileChunks[fileChunks.length - 1];
        return `media/${isVendor ? "vendors" : "venues"}/${isVendor ? vendorData.vendorSlug : vendorData.venueSlug
          }/cover-image`;
      },
    });

    const coverImageUploadResponse = mediaResponses[0];
    if (coverImageUploadResponse === undefined) {
      console.log("undefinnd")
      setImageLoader(false)
      setImageErrorSuccess({ error: true, sucess: false })
      return;
    }
    const { requestAcknowledged, requestStatus } = coverImageUploadResponse;
    if (requestAcknowledged && requestStatus === "ACK") {
      setBasicProfileData({
        ...profileData,
        serviceImageObjectKey: coverImageUploadResponse.objectKey,
      });
      setImageLoader(false)
      setImageErrorSuccess({ error: false, sucess: true })
    } else {
      console.log("else")
      setImageLoader(false)
      setImageErrorSuccess({ error: true, sucess: false })
    }
  };

  const parseCity = (cityString) => {
    const reMatches = /^(?<cityName>.+)\s\((?<stateCode>\w{2})\)$/i.exec(
      cityString
    );
    if (reMatches === null) {
      return {
        stateCode: null,
        cityName: null,
      };
    }

    const { cityName, stateCode } = reMatches.groups || {};
    if (cityName === undefined || stateCode === undefined) {
      return {
        cityName: null,
        stateCode: null,
      };
    }

    return {
      cityName: cityName,
      stateCode: stateCode,
    };
  };

  const [serviceCities, setServiceCities] = useValueSelector({
    defaultValue: () => {
      return { stateCode: null, cityName: null };
    },
    venueValue: (venueData) => {
      return parseCity(venueData.venueCity);
    },
    vendorValue: (vendorData) => {
      return parseCity(vendorData.vendorCity);
    },
    updateVenueValue: (prevValue, partialValue) => {
      const { stateCode, cityName } = partialValue;
      if (stateCode === null || cityName === null) {
        return prevValue;
      }
      return {
        ...prevValue,
        venueCity: `${cityName} (${stateCode})`,
      };
    },
    updateVendorValue: (prevValue, partialValue) => {
      const { stateCode, cityName } = partialValue;
      if (stateCode === null || cityName === null) {
        return prevValue;
      }
      return {
        ...prevValue,
        vendorCity: `${cityName} (${stateCode})`,
      };
    },
  });

  const cityText = "Which city do you provide services in?";

  const cityInput = useServiceRender({
    fallbackElement: null,
    venueElement: (
      <>
        <Grid xs={12} md={4} item>
          <p>
            <b>{cityText}</b>
          </p>
          {listingTickets &&
            isPending &&
            listingTickets.some((item) => {
              return item.updatedFields.includes("venueCity");
            }) && <p>(Updated)</p>}
        </Grid>
        <Grid item xs={12} md={8}>
          <LocationSelect
            cityName={serviceCities?.cityName || null}
            stateCode={serviceCities?.stateCode || null}
            onSelect={({ stateCode, cityName }) => {
              if (`${stateCode}` === "null" || `${cityName}` === "null") {
                return;
              }
              setServiceCities({
                cityName: cityName,
                stateCode: stateCode,
              });
            }}
          />
        </Grid>
      </>
    ),
    vendorElement: (
      <>
        <Grid xs={12} md={4} item>
          <p>
            <b>{cityText}</b>
          </p>
          {listingTickets &&
            isPending &&
            listingTickets.some((item) => {
              return item.updatedFields.includes("vendorCity");
            }) && <p>(Updated)</p>}
        </Grid>
        <Grid item xs={12} md={8}>
          <LocationSelect
            cityName={serviceCities?.cityName || null}
            stateCode={serviceCities?.stateCode || null}
            onSelect={({ stateCode, cityName }) => {
              if (`${stateCode}` === "null" || `${cityName}` === "null") {
                return;
              }
              setServiceCities({
                cityName: cityName,
                stateCode: stateCode,
              });
            }}
          />
        </Grid>
      </>
    ),
  });

  const getAdminTickets = async () => {
    let url = "";
    if (!isVendor) {
      url = `${API_HOST}/api/v1/venues/${vendorData.venueSlug}/listing-status?listingStatus=PENDING`;
    } else {
      url = `${API_HOST}/api/v1/vendors/${vendorData.vendorSlug}/listing-status?listingStatus=PENDING`;
    }
    try {
      const response = await axios.get(url);
      console.log("getVendorContact flag 1", response.data.listingTickets);
      setListingTickets(response.data.listingTickets);
      console.log("getVendorContact flag 2", listingTickets);
    } catch (error) {
      console.log(error);
    }
  };

  const getVendorContact = async () => {
    let url = `${API_HOST}/api/v1/admin/vendor-contact/${vendorData.userId}`;

    try {
      const response = await axios.get(url);
      const vendor = response.data.vendor;
      setVendorDetails(vendor);
      setEmail(vendor.email)
      setNumber(vendor.contact)
      setName(vendor.name)
    } catch (error) {
      console.log(error);
    }
    setBackDropComplete(false)
  };

  useEffect(() => {
    if (isPending) {
      getAdminTickets();
    }
  }, []);

  useEffect(() => {
    setBackDropComplete(true)
    getVendorContact();
  }, []);

  const uploadData = async () => {
    if (isVendor) {
      try {
        await axios.put(`${API_HOST}/api/v1/vendors/${vendorData.vendorSlug}`, {
          vendorName: serviceName,
          vendorDescription: serviceDescription,
          vendorCity: `${serviceCities.cityName} (${serviceCities.stateCode})`,
          vendorCoverImageObjectKey: serviceImageObjectKey,
        });

        setSnackbarMessage("Vendor profile changed successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage("An error occurred while changing profile");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } else {
      try {
        await axios.put(`${API_HOST}/api/v1/venues/${vendorData.venueSlug}`, {
          venueName: serviceName,
          venueDescription: serviceDescription,
          venueCity: `${serviceCities.cityName} (${serviceCities.stateCode})`,
          venueLocality: serviceLocality,
          venueCoverImageObjectKey: serviceImageObjectKey,
        });
        setSnackbarMessage("Venue profile changed successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage("An error occurred while changing profile");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  // async function ChangeEmailAndPhone() {
  //   if (vendorData.userId) {
  //     setBackDropComplete(true)
  //     if (vendorDetails.contact && vendorDetails.contact == number && vendorDetails.email && email && vendorDetails.email.trim().toLowerCase() == email.toLowerCase()) {
  //       setBackDropComplete(false)
  //       setSnackbarMessage("Same Value No Need To Update");
  //       setSnackbarSeverity("error");
  //       setSnackbarOpen(true);
  //       return
  //     }
  //     if (!number || !email) {
  //       setBackDropComplete(false)
  //       setSnackbarMessage("Invalid Values");
  //       setSnackbarSeverity("error");
  //       setSnackbarOpen(true);
  //       return
  //     }
  //     let body = {}
  //     if (vendorDetails.contact == number) {
  //       body = {
  //         userMail: email
  //       }
  //     } else if (vendorDetails.email && email && vendorDetails.email.trim().toLowerCase() == email.toLowerCase()) {
  //       body = {
  //         userContact: number
  //       }
  //     } else {
  //       body = { userContact: number, userMail: email }
  //     }
  //     try {
  //       let response = await axios.put(`${API_HOST}/api/v1/admin/changeemailandphone/${vendorData.userId}`, body)
  //       if (response.status === 200) {
  //         getVendorContact();
  //         setSnackbarMessage("Successfully Changed");
  //         setSnackbarSeverity("success");
  //         setSnackbarOpen(true);
  //         SetChangeEmailPhone(true);
  //       } else {
  //         setSnackbarMessage(response.data.message);
  //         setSnackbarSeverity("error");
  //         setSnackbarOpen(true);
  //       }
  //     } catch (error) {
  //       setSnackbarMessage("An error occurred while changing");
  //       setSnackbarSeverity("error");
  //       setSnackbarOpen(true);
  //     }
  //     setBackDropComplete(false)
  //   } else {
  //     setSnackbarMessage("Please Wait Try After SomeTime!");
  //     setSnackbarSeverity("error");
  //     setSnackbarOpen(true);
  //   }
  // }




  // async function ChangeEmailAndPhone() {
  //   if (vendorData.userId) {
  //     setBackDropComplete(true)
  //     if (vendorDetails.contact && vendorDetails.contact == number && vendorDetails.email && email && vendorDetails.email.trim().toLowerCase() == email.toLowerCase()) {
  //       setBackDropComplete(false)
  //       setSnackbarMessage("Same Value No Need To Update");
  //       setSnackbarSeverity("error");
  //       setSnackbarOpen(true);
  //       return
  //     }
  //     if (!number || !email) {
  //       setBackDropComplete(false)
  //       setSnackbarMessage("Invalid Values");
  //       setSnackbarSeverity("error");
  //       setSnackbarOpen(true);
  //       return
  //     }
  //     let body = {}
  //     if (vendorDetails.contact == number) {
  //       body = {
  //         userMail: email
  //       }
  //     } else if (vendorDetails.email && email && vendorDetails.email.trim().toLowerCase() == email.toLowerCase()) {
  //       body = {
  //         userContact: number
  //       }
  //     } else {
  //       body = { userContact: number, userMail: email }
  //     }
  //     try {
  //       let response = await axios.put(`${API_HOST}/api/v1/admin/changeemailandphone/${vendorData.userId}`, body)
  //       if (response.status === 200) {
  //         getVendorContact();
  //         setSnackbarMessage("Successfully Changed");
  //         setSnackbarSeverity("success");
  //         setSnackbarOpen(true);
  //         SetChangeEmailPhone(true);
  //       } else {
  //         setSnackbarMessage(response.data.message);
  //         setSnackbarSeverity("error");
  //         setSnackbarOpen(true);
  //       }
  //     } catch (error) {
  //       setSnackbarMessage("An error occurred while changing");
  //       setSnackbarSeverity("error");
  //       setSnackbarOpen(true);
  //     }
  //     setBackDropComplete(false)
  //   } else {
  //     setSnackbarMessage("Please Wait Try After SomeTime!");
  //     setSnackbarSeverity("error");
  //     setSnackbarOpen(true);
  //   }
  // }


  async function ChangeEmailAndPhone() {
    if (vendorData.userId) {
      setBackDropComplete(true)
      if (vendorDetails.contact && vendorDetails.contact == number && vendorDetails.email && email && vendorDetails.email.trim().toLowerCase() == email.toLowerCase() && vendorDetails.name && vendorDetails.name == name) {
        setBackDropComplete(false)
        setSnackbarMessage("Same Value No Need To Update");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return
      }
      if (!number || !email || !name) {
        setBackDropComplete(false)
        setSnackbarMessage("Invalid Values");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return
      }
      let body = {}
      if (vendorDetails.contact == number && vendorDetails.email && email && vendorDetails.email.trim().toLowerCase() == email.toLowerCase()) {
        console.log("ye aya ha")
        body = {
          userFullName: name
        }
      }
     else if (vendorDetails.contact == number) {
        body = {
          userMail: email,
          userFullName: name
        }
      } else if (vendorDetails.email && email && vendorDetails.email.trim().toLowerCase() == email.toLowerCase()) {
        body = {
          userContact: number,
          userFullName: name
        }
      } else {
        body = {
          userContact: number, userMail: email,
          userFullName: name
        }
      }
      try {
        let response = await axios.put(`${API_HOST}/api/v1/admin/changeemailandphone/${vendorData.userId}`, body)
        if (response.status === 200) {
          getVendorContact();
          setSnackbarMessage("Successfully Changed");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          SetChangeEmailPhone(true);
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      } catch (error) {
        setSnackbarMessage("An error occurred while changing");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
      setBackDropComplete(false)
    } else {
      setSnackbarMessage("Please Wait Try After SomeTime!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  }

  console.log(vendorData,isVendor,"check this ")
  return (
    <>
      {openCrop ? <CropEasy {...{ photoURL, setOpenCrop, setFile, file, setPhotoURL, uploadCoverImage, photoAlbum }} /> : null}
      <Stack spacing={2}>

        {/* <CropEasy {...{photoURL,setOpenCrop,setFile,setPhotoURL}}/> */}
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backDropComplete}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Stack spacing={2}>
          <Typography sx={{ fontFamily: "Poppins", marginTop: "12px" }}>
            Fill in your basic service details here. Make sure your information
            matches the services you provide
          </Typography>
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={2} sx={{ alignItems: "center" }}>
          <Grid xs={4} item>
            <p>
              <b>What is the name of your establishment?</b>
              {listingTickets &&
                isPending &&
                listingTickets.some((item) => {
                  return (
                    item.updatedFields.includes("vendorName") ||
                    item.updatedFields.includes("venueName")
                  );
                }) && <p>(Updated)</p>}
            </p>
          </Grid>
          <Grid xs={8} item>
            <TextField
              fullWidth
              defaultValue={serviceName}
              placeholder={"Your Service Name"}
              onChange={(e) => {
                setBasicProfileData({
                  ...profileData,
                  serviceName: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid xs={4} item>
            <p>
              <b>Enter a brief description of your services</b>
              {listingTickets &&
                isPending &&
                listingTickets.some((item) => {
                  return (
                    item.updatedFields.includes("vendorDescription") ||
                    item.updatedFields.includes("venueDescription")
                  );
                }) && <p>(Updated)</p>}
            </p>
          </Grid>
          <Grid xs={8} item>
            <TextField
              fullWidth
              defaultValue={serviceDescription}
              multiline
              minRows={3}
              placeholder={"Your Service Description"}
              onChange={(e) => {
                setBasicProfileData({
                  ...profileData,
                  serviceDescription: e.target.value,
                });
              }}
              maxRows={6}
            />
          </Grid>
          {cityInput}
          <>
            <Grid xs={4} item>
              <p>
                <b>Upload Cover Image</b>
                {listingTickets &&
                  isPending &&
                  listingTickets
                    .flat()
                    .some(
                      (item) =>
                        item === "vendorCoverImageObjectKey" ||
                        item === "venueCoverImageObjectKey"
                    ) && <p>(Updated)</p>}
              </p>
            </Grid>
            <Grid xs={6} item>
              <TextField
                fullWidth
                accept=".jpg, .jpeg, .png"
                type={"file"}
                onChange={(e) => {
                  setImageErrorSuccess({ error: false, sucess: false });

                  if ((e.target.files || []).length > 0) {
                    setFile(e.target.files[0])
                    setPhotoURL(URL.createObjectURL(e.target.files[0]))
                    setOpenCrop(true)
                  }
                }}
              />
              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "15px" }}><p style={{ color: "red", fontSize: "13px" }}>Allowed file types: JPG, JPEG, PNG </p>{imageLoader ? <CircularProgress style={{ width: "20px", height: "20px" }} /> : null}{imageErrorSuccess.error ? <ErrorIcon style={{ width: "25px", height: "25px", color: "red" }} /> : null}{imageErrorSuccess.sucess ? <CheckCircleIcon style={{ width: "25px", height: "25px", color: "green" }} /> : null}</div>
            </Grid>
            <Grid item xs={12} md={2}>
              <Link href={serviceCoverImageURL} target={"_blank"}>
                View Current Image
              </Link>
            </Grid>
            <Grid xs={4} item>
              <p>
                <b>Vendor Name </b>
              </p>
            </Grid>
            <Grid xs={8} item>
              <TextField onChange={(e) => {
                if (vendorDetails) {
                  if (changeEmailPhone && e.target.value.trim().length > 4)
                    SetChangeEmailPhone(false)
                  if (!changeEmailPhone && e.target.value.trim().length < 4)
                    SetChangeEmailPhone(true)
                  setName(e.target.value)
                }
              }} fullWidth value={vendorDetails && name} />
            </Grid>
            <Grid xs={4} item>
              <p>
                <b>Vendor Mail </b>
              </p>
            </Grid>
            <Grid xs={8} item>
              <TextField fullWidth
                onChange={(e) => {
                  if (vendorDetails) {
                    if (changeEmailPhone && e.target.value.trim().length > 10)
                      SetChangeEmailPhone(false)
                    setEmail(e.target.value.trim())
                  }
                }}
                value={vendorDetails && email} />
            </Grid>
            <Grid xs={4} item>
              <p>
                <b>Vendor Contact </b>
              </p>
            </Grid>
            <Grid xs={8} item>
              <TextField
                onChange={(e) => {
                  if (vendorDetails) {
                    if (!changeEmailPhone && e.target.value.trim().length < 10)
                      SetChangeEmailPhone(true)
                    let regex = /^[0-9]*$/;
                    if (changeEmailPhone && e.target.value.trim().length >= 10)
                      SetChangeEmailPhone(false)

                    if (regex.test(e.target.value.trim()))
                      setNumber(e.target.value.trim())
                  }
                }}
                fullWidth
                value={vendorDetails && number}
              />
            </Grid>
          </>
        </Grid>
        <div className="w-full p-3 flex gap-3 sm:flex-row flex-col">

          <Button
            variant="contained"
            sx={{
              width: "100%",
              fontFamily: "Poppins",
              textTransform: "capitalize",
            }}
            onClick={uploadData}
          >
            {isPending &&vendorData?.premiumStartingDate?"Approve & Save " : "Save"}
          </Button>

          <Button
            disabled={changeEmailPhone}
            onClick={() => ChangeEmailAndPhone()}
            variant="contained"
            sx={{
              width: "100%",
              fontFamily: "Poppins",
              textTransform: "capitalize",
              background: "green",
              '&:hover': {
                background: "#228B22",
              }
            }}
          >
            Update Name ,Email And Contact
          </Button>

        </div>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={1500}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default Profile;
