import {
  selectMetadata,
  zipMetadata,
} from '../../../../utility/schemaMetadata';
import { Grid, TextField, Typography } from '@mui/material';
import { useValueSelector } from './hook';

export function NumericMultipleQuestion(props) {
  const {
    questionText,
    vendorMetadataType,
    vendorMetadataDependencies,
    vendorMetadataDefaultValue,
    vendorMetadataKey,
  } = props;

  const [vendorMetadata, setVendorMetadata] = useValueSelector({
    defaultValue: () => {
      return [];
    },
    vendorValue: (vendorData) => {
      return vendorData.vendorMetadata;
    },
    venueValue: () => {
      return [];
    },
    updateVendorValue: (prevValue, partialValue) => {
      return {
        ...prevValue,
        vendorMetadata: zipMetadata(prevValue.vendorMetadata, partialValue),
      };
    },
    updateVenueValue: (prevValue, partialValue) => {
      return prevValue;
    },
  });

  const currentMetadataValue = selectMetadata(
    vendorMetadata,
    vendorMetadataKey,
    vendorMetadataDefaultValue
  );

  return (
    <>
      <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography>
          <b>{questionText} (Separate with commas)</b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          fullWidth
          sx={{ fontFamily: 'Poppins' }}
          {...(currentMetadataValue.length === 0
            ? { placeholder: 'Type Here' }
            : { defaultValue: currentMetadataValue.join(', ') })}
          onChange={(e) => {
            setVendorMetadata([
              // @ts-ignore
              ...vendorMetadata,
              {
                propertyName: vendorMetadataKey,
                propertyType: 'ARRAY_NUMBER',
                // @ts-ignore
                propertyValue: e.target.value
                  .split(',')
                  .map((eachString) => {
                    const parsedValue = Number.parseInt(eachString);
                    if (Number.isNaN(parsedValue)) {
                      return undefined;
                    }
                    return parsedValue;
                  })
                  .filter((eachVal) => {
                    return eachVal !== undefined;
                  }),
              },
            ]);
          }}
        />
      </Grid>
    </>
  );
}
