import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useContext, useState } from "react";
import {
  useServiceRender,
  useValueSelector,
} from "../features/questionnaire/hook";
import {
  DataContext,
  ListingContext,
  PendingContext,
  TypeContext,
} from "../../../App";
import axios from "axios";
import { API_HOST } from "../../Api/url";

function PackageCard(props) {
  const { packageIdx, onDeleteClick, onSaveClick } = props;
  const [packageName, setPackageName] = useState(props.packageName);
  const [packagePrice, setPackagePrice] = useState(props.packagePrice);

  const [packageSaved, setPackageSaved] = useState(props.packageSavedStatus);

  return (
    <Grid item xs={12} md={4}>
      <Box
        sx={{
          border: props.isError ? "1px solid red" : "1px solid darkgrey",
          borderRadius: 2,
          padding: 2,
        }}
      >
        <Stack spacing={1}>
          <Typography variant={"h6"}>
            <b>{`Package #${packageIdx + 1}`}</b>
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography>
            <b>Package Name</b>
          </Typography>
          <TextField
            fullWidth
            defaultValue={props.packageName}
            placeholder={"Package Name"}
            onChange={(e) => {
              setPackageSaved(false);
              setPackageName(e.target.value);
            }}
          />
          <Typography>
            <b>Package Price</b>
          </Typography>
          <TextField
            fullWidth
            defaultValue={props.packagePrice}
            placeholder={"Package Price"}
            onChange={(e) => {
              setPackageSaved(false);
              const parsedValue = Number.parseFloat(e.target.value);
              if (Number.isNaN(parsedValue) || !Number.isFinite(parsedValue)) {
                return;
              }
              setPackagePrice(parsedValue);
            }}
          />
          <Stack direction={"row"} spacing={2}>
            <Button
              fullWidth
              disabled={packageSaved}
              onClick={() => {
                setPackageSaved(true);
                onSaveClick({
                  packagePrice: packagePrice,
                  packageName: packageName,
                });
              }}
              variant={"outlined"}
              color={"primary"}
            >
              Save Package
            </Button>
            <Button
              fullWidth
              onClick={onDeleteClick}
              variant={"outlined"}
              color={"error"}
            >
              Delete Package
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Grid>
  );
}

export default function PricingPage() {
  const [servesNonVeg, setServesNonVeg] = useState(false);
  const [servesJain, setServesJain] = useState(false);
  const [platePrice, setPlatePrice] = useState({
    veg: "0",
    nonveg: "0",
    jain: "0"
  })
  const { isVendor } = useContext(TypeContext);
  const { vendorData } = useContext(DataContext);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const { isPending } = useContext(PendingContext);
  const { listingTickets, setListingTickets } = useContext(ListingContext);
  const [isServiceOnRequest, setIsServiceOnRequest] = useState(
    vendorData.vendorServiceRate > 0 ? false : true
  );
  const [pricingData, setPricingData] = useValueSelector({
    venueValue: (venueData) => {
      const packageRates = venueData.venuePricingRate;

      const packageNames = ["FOOD_VEG", "FOOD_NONVEG", "FOOD_JAIN"];

      packageRates.push(...Array(3 - packageRates.length).fill(0));

      const venueFoodPackages = packageRates.map((packageRate, packageIdx) => {
        return {
          packageName: packageNames[packageIdx],
          packagePrice: packageRate,
        };
      });

      return {
        serviceFlatRate: venueData.venueBaseRate,
        servicePackages: venueFoodPackages,
      };
    },
    vendorValue: (vendorData) => {
      const vendorPackageNames = vendorData.vendorPackages.map((packageObj) => {
        return packageObj.packageName;
      });

      const vendorPackagePrices = vendorData.vendorPackages.map(
        (packageObj) => {
          return packageObj.packagePrice;
        }
      );

      return {
        serviceFlatRate: vendorData.vendorServiceRate,
        servicePackages: vendorData.vendorPackages,
      };
    },
    defaultValue: () => {
      return {
        serviceFlatRate: 0,
        servicePackages: [],
      };
    },
    updateVenueValue: (prevValue, partialValue) => {
      const { serviceFlatRate, servicePackages } = partialValue;
      return {
        ...prevValue,
        venueBaseRate: serviceFlatRate,
        venuePricingRate: servicePackages.map((packageObj) => {
          return packageObj.packagePrice;
        }),
      };
    },
    updateVendorValue: (prevValue, partialValue) => {
      const { serviceFlatRate, servicePackages } = partialValue;

      return {
        ...prevValue,
        vendorServiceRate: serviceFlatRate,
        vendorPackages: servicePackages,
      };
    },
  });

  const { serviceFlatRate, servicePackages } = pricingData;
  useEffect(() => {
    if (pricingData && servicePackages.length == 3) {
      if (servicePackages[1]?.packagePrice > 0) {
        setPlatePrice({
          veg: servicePackages[0].packagePrice ? servicePackages[0].packagePrice.toString() : "0",
          nonveg: servicePackages[1].packagePrice ? servicePackages[1].packagePrice.toString() : "0",
          jain: servicePackages[2].packagePrice ? servicePackages[2].packagePrice.toString() : "0"
        })
        setServesNonVeg(true)
      }
      if (servicePackages[2]?.packagePrice > 0) {
        setServesJain(true)
      }
    }
  }, [])
  const deletePackage = (packageIdx) => {
    return function () {
      const prevPackages = servicePackages.slice(0, packageIdx);
      const nextPackages = servicePackages.slice(
        packageIdx + 1,
        servicePackages.length
      );

      setPricingData({
        serviceFlatRate: serviceFlatRate,
        servicePackages: [...prevPackages, ...nextPackages],
      });
    };
  };

  const updatePackagePrice = (packageIdx) => {
    return function (packagePriceString) {
      if (packageIdx == 0) {
        setPlatePrice({ ...platePrice, veg: packagePriceString.trim() })
      } else if (packageIdx == 1) {
        setPlatePrice({ ...platePrice, nonveg: packagePriceString.trim() })
      } else {
        setPlatePrice({ ...platePrice, jain: packagePriceString.trim() })
      }
      let parsedValue = Number.parseFloat(packagePriceString);
      if (Number.isNaN(parsedValue) || !Number.isFinite(parsedValue)) {
        parsedValue = 0
      };

      const currPackage = servicePackages[packageIdx];

      const prevPackages = servicePackages.slice(0, packageIdx);
      const nextPackages = servicePackages.slice(
        packageIdx + 1,
        servicePackages.length
      );

      setPricingData({
        serviceFlatRate: serviceFlatRate,
        servicePackages: [
          ...prevPackages,
          {
            packageName: currPackage.packageName,
            packagePrice: parsedValue,
          },
          ...nextPackages,
        ],
      });
    };
  };

  const uploadData = async () => {
    if (isVendor) {
      try {
        console.log(serviceFlatRate, servicePackages, "check this what is this")
        await axios.put(`${API_HOST}/api/v1/vendors/${vendorData.vendorSlug}`, {
          vendorServiceRate: serviceFlatRate,
          vendorPackages: servicePackages,
        });
        setSnackbarMessage("Pricings updated successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage("An error occurred while updating pricings");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } else {
      try {
        await axios.put(`${API_HOST}/api/v1/venues/${vendorData.venueSlug}`, {
          venueBaseRate: serviceFlatRate,
          venuePricingRate: servicePackages.map((item) => {
            return item.packagePrice;
          }),
        });
        setSnackbarMessage("Pricings updated successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage("An error occurred while updating pricings");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    }
  };

  const servicePricingDescription = useServiceRender({
    fallbackElement: (
      <ul>
        <li>Input the service offerings based on your services</li>
        <li>Accordingly, enter the approximate cost per unit.</li>
        <li>This approximate pricing will be shown to the user</li>
      </ul>
    ),
    venueElement: (
      <>
        {listingTickets &&
          isPending &&
          listingTickets.some((item) => {
            return (
              item.updatedFields.includes("venuePricingRate") ||
              item.updatedFields.includes("venueBaseRate")
            );
          }) && <p>(Updated)</p>}
        <ul>
          <li>Input the base price for your venue</li>
          <li>
            Check or uncheck the plate-pricing values depending upon your
            services
          </li>
          <li>Accordingly, enter the approximate cost per unit.</li>
          <li>If you do not serve any food, list the prices as 0</li>
          <li>This approximate pricing will be shown to the user</li>
        </ul>
      </>
    ),
    vendorElement: (
      <>
        {listingTickets &&
          isPending &&
          listingTickets.some((item) => {
            return (
              item.updatedFields.includes("vendorServiceRate") ||
              item.updatedFields.includes("vendorPackages")
            );
          }) && <p>(Updated)</p>}
        <ul>
          <li>Enter the base rate for your most basic / flat service</li>
          <li>
            You can add additional packages that enhance the client experience
          </li>
          <li>Enter the approximate pricing for each package below it</li>
          <li>Your packages will be displayed to the user</li>
        </ul>
      </>
    ),
  });

  const servicePricingInputs = useServiceRender({
    venueElement: (
      <Grid container sx={{ alignItems: "center" }} spacing={1}>
        <Grid item xs={3}>
          <Typography variant={"h6"}>Base Rate</Typography>
          {/* {listingTickets &&
            isPending &&
            listingTickets.flat().some((item) => item === `venueBaseRate`) && (
              <p>(Updated)</p>
            )} */}
        </Grid>
        <Grid item xs={9}>
          <TextField
            type={"number"}
            fullWidth
            value={serviceFlatRate === 0 ? "" : serviceFlatRate.toString()}
            onChange={(e) => {
              if(Number(e.target.value)<0)
							return
              const parsedValue = Number.parseInt(e.target.value);
              if (Number.isNaN(parsedValue)) {
                setPricingData({
                  ...pricingData,
                  serviceFlatRate: -1
                });
              } else {
                setPricingData({
                  ...pricingData,
                  serviceFlatRate: parsedValue,
                });
              }

            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid item lg={4} xs={12}>
          <Stack spacing={2}>
            {/* {listingTickets &&
              isPending &&
              listingTickets
                .flat()
                .some((item) => item === `venuePricingRate`) && (
                <p>(Updated)</p>
              )} */}
            <FormControlLabel
              control={<Checkbox checked disabled />}
              label={<Typography variant={"h6"}>Vegetarian Food</Typography>}
            />
            <Divider sx={{ my: 2 }} />
            <Typography>Price per plate</Typography>
            <TextField
              type={"number"}
              value={platePrice.veg}
              onChange={(e) => {
                const updateFn = updatePackagePrice(0);
								if (Number(e.target.value) < 0)
									updateFn("0");
								else {
									updateFn(e.target.value);
								}
              }}
            />
          </Stack>
        </Grid>
        <Grid item lg={4} xs={12}>
          <Stack spacing={2}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={servicePackages[1]?.packagePrice > 0 ? true : false}
                  onChange={(e) => {
                    setServesNonVeg((prevVal) => {
                      return !prevVal;
                    });
                    updatePackagePrice(1)(
                      e.target.checked
                        ? servicePackages[1]?.packagePrice.toString() || "0"
                        : "0"
                    );
                  }}
                />
              }
              label={
                <Typography variant={"h6"}>Non-Vegetarian Food</Typography>
              }
            />
            <Divider sx={{ my: 2 }} />
            <Typography>Price per plate</Typography>
            <TextField
              type={"number"}
              value={platePrice.nonveg}
							disabled={!servesNonVeg}
							onChange={(e) => {
								const updateFn = updatePackagePrice(1);
								if (Number(e.target.value) < 0)
									updateFn("0");
								else {
									updateFn(e.target.value);
								}
							}}
            />
          </Stack>
        </Grid>
        <Grid item lg={4} xs={12}>
          <Stack spacing={2}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={servicePackages[2]?.packagePrice > 0 ? true : false}
                  onChange={(e) => {
                    setServesJain((prevVal) => {
                      return !prevVal;
                    });
                    updatePackagePrice(2)(
                      e.target.checked
                        ? servicePackages[2]?.packagePrice.toString() || "0"
                        : "0"
                    );
                  }}
                />
              }
              label={<Typography variant={"h6"}>Jain Food</Typography>}
            />
            <Divider sx={{ my: 2 }} />
            <Typography>Price per plate</Typography>
            <TextField
              type={"number"}
              value={platePrice.jain}
							disabled={!servesJain}
							onChange={(e) => {
								const updateFn = updatePackagePrice(2);
								if (Number(e.target.value) < 0)
									updateFn("0");
								else {
									updateFn(e.target.value);
								}
							}}
            />
          </Stack>
        </Grid>
      </Grid>
    ),
    vendorElement: (
      <Grid container sx={{ alignItems: "center" }} spacing={1}>
        <Grid item xs={3}>
          <Typography variant={"h6"}>Service Rate on request</Typography>
        </Grid>
        <Grid item xs={9}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isServiceOnRequest}
                onChange={() => {
                  // Toggle the "On Request" checkbox state
                  setIsServiceOnRequest(!isServiceOnRequest);

                  // Update the serviceFlatRate based on the checkbox state
                  if (isServiceOnRequest) {
                    const newServiceFlatRate = isServiceOnRequest
                      ? -1
                      : vendorData.vendorServiceRate;

                    setPricingData({
                      serviceFlatRate: newServiceFlatRate,
                      servicePackages: servicePackages,
                    });
                  }
                }}
              />
            }
            label={"On Request"}
          />
        </Grid>
        {!isServiceOnRequest && (
          <>
            <Grid item xs={3}>
              <Typography variant={"h6"}>Base Service Rate</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                type={"number"}
                fullWidth
                value={serviceFlatRate}
                onChange={(e) => {
                  const parsedFlat = Number.parseInt(e.target.value);
                  if (e.target.value > 0 || Number.isNaN(parsedFlat)) {
                    setPricingData({
                      serviceFlatRate: parsedFlat,
                      servicePackages: servicePackages,
                    });
                  }
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid item xs={9}>
          <Typography variant={"h5"}>Packages</Typography>
          {/* {listingTickets &&
            isPending &&
            listingTickets.flat().some((item) => item === 'vendorPackages') && (
              <p>(Updated)</p>
            )} */}
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <Button
            variant={"contained"}
            sx={{
              boxShadow: 0,
            }}
            onClick={() => {
              setPricingData({
                serviceFlatRate: serviceFlatRate,
                servicePackages: [
                  ...servicePackages,
                  {
                    packageName: "",
                    packagePrice: 0,
                  },
                ],
              });
            }}
          >
            Add Package
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
        {servicePackages.map((packageObj, packageIdx) => {
          return (
            <PackageCard
              key={`${packageIdx}.${packageObj.packageName}`}
              packageIdx={packageIdx}
              {...packageObj}
              // isError={invalidPackages}
              packageSavedStatus={true}
              onDeleteClick={deletePackage(packageIdx)}
              onSaveClick={(packageData) => {
                const prevPackages = servicePackages.slice(0, packageIdx);
                const nextPackages = servicePackages.slice(
                  packageIdx + 1,
                  servicePackages.length
                );

                setPricingData({
                  ...pricingData,
                  servicePackages: [
                    ...prevPackages,
                    packageData,
                    ...nextPackages,
                  ],
                });
              }}
            />
          );
        })}
      </Grid>
    ),
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <Stack spacing={2}>
        <Stack spacing={2}>
          {/* <Typography variant={'h4'}>Pricing Information</Typography> */}
          <Typography sx={{ pl: 2 }}>{servicePricingDescription}</Typography>
        </Stack>
        <Divider sx={{ my: 2 }} />
        {servicePricingInputs}
        <Button
          variant="contained"
          sx={{
            fontFamily: "Poppins",
            textTransform: "capitalize",
            marginTop: "20px",
          }}
          onClick={uploadData}
        >
           {isPending &&vendorData?.premiumStartingDate?"Approve & Save " : "Save"}
        </Button>{" "}
      </Stack>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
