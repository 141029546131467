export const categoriesList = [
  {
    category: "Show All",
    value: "SHOWALL",
  },
  {
    category: "Photography",
    value: "PHOTOGRAPHY",
  },
  {
    category: "Makeup",
    value: "MAKEUP",
  },
  {
    category: "Planning & Decor",
    value: "PLANNING_AND_DECOR",
  },

  {
    category: "Bridal Wear",
    value: "BRIDAL_WEAR",
  },
  {
    category: "Groom Wear",
    value: "GROOM_WEAR",
  },
  {
    category: "Mehendi",
    value: "MEHENDI",
  },

  {
    category: "Jewellery & Accessories",
    value: "JEWELLERY_AND_ACCESSORIES",
  },
  {
    category: "Invites & Gifts",
    value: "INVITES_AND_GIFTS",
  },
  {
    category: "Furniture",
    value: "FURNITURE",
  },

  {
    category: "Music & Dance",
    value: "MUSIC_AND_DANCE",
  },
  {
    category: "Food",
    value: "FOOD",
  },

  {
    category: "Clinics",
    value: "CLINICS",
  },
  {
    category: "Boutiques",
    value: "BOUTIQUES",
  },
  {
    category: "Honeymoon",
    value: "HONEYMOON",
  },
  {
    category: "Astrology",
    value: "ASTROLOGY",
  },
  {
    category: "Fitness Center",
    value: "FITNESS_CENTER",
  },
  {
    category: "Bridal Grooming",
    value: "BRIDAL_GROOMING",
  }
];

export const vendorSubcategoryList = [
  {
    subcategoryText: "Wedding Photographers",
    vendorSubcategory: "WEDDING_PHOTOGRAPHERS",
  },
  {
    subcategoryText: "Pre Wedding Shoot",
    vendorSubcategory: "PRE_WEDDING_SHOOT",
  },
  {
    subcategoryText: "Freelance Makeup Artist",
    vendorSubcategory: "FREELANCE_MAKEUP_ARTIST",
  },
  {
    subcategoryText: "Beauty Salon",
    vendorSubcategory: "BEAUTY_SALON",
  },
  {
    subcategoryText: "Wedding Planners",
    vendorSubcategory: "WEDDING_PLANNERS",
  },
  {
    subcategoryText: "Wedding Decor",
    vendorSubcategory: "WEDDING_DECOR",
  },
  {
    subcategoryText: "Florists",
    vendorSubcategory: "FLORISTS",
  },
  {
    subcategoryText: "Mehendi Artist",
    vendorSubcategory: "MEHENDI_ARTIST",
  },
  {
    subcategoryText: "Jeweller",
    vendorSubcategory: "JEWELLER",
  },
  {
    subcategoryText: "Artificial Jewellery",
    vendorSubcategory: "ARTIFICIAL_JEWELLERY",
  },
  {
    subcategoryText: "Accessories",
    vendorSubcategory: "ACCESSORIES",
  },
  {
    subcategoryText: "Invitations",
    vendorSubcategory: "INVITATIONS",
  },
  {
    subcategoryText: "Trousseau Packers",
    vendorSubcategory: "TROUSSEAU_PACKERS",
  },
  {
    subcategoryText: "Invitation Gifts",
    vendorSubcategory: "INVITATION_GIFTS",
  },
  {
    subcategoryText: "DJs",
    vendorSubcategory: "DJS",
  },
  {
    subcategoryText: "Band",
    vendorSubcategory: "BAND",
  },
  {
    subcategoryText: "Sangeet Choreographer",
    vendorSubcategory: "SANGEET_CHOREOGRAPHER",
  },
  {
    subcategoryText: "Wedding Entertainment",
    vendorSubcategory: "WEDDING_ENTERTAINMENT",
  },
  {
    subcategoryText: "Dhol & Damau",
    vendorSubcategory: "DHOL_AND_DAMAU",
  },
  {
    subcategoryText: "Maangal Geet",
    vendorSubcategory: "MAANGAL_GEET",
  },
  {
    subcategoryText: "Anchor/Emcee",
    vendorSubcategory: "ANCHOR_EMCEE",
  },
  {
    subcategoryText: "Catering Services",
    vendorSubcategory: "CATERING_SERVICES",
  },
  {
    subcategoryText: "Cake",
    vendorSubcategory: "CAKE",
  },
  {
    subcategoryText: "Bartenders",
    vendorSubcategory: "BARTENDERS",
  },
  {
    subcategoryText: "Home Catering",
    vendorSubcategory: "HOME_CATERING",
  },
  {
    subcategoryText: "Mithai",
    vendorSubcategory: "MITHAI",
  },
  {
    subcategoryText: "Furniture",
    vendorSubcategory: "FURNITURE",
  },
  {
    subcategoryText: "Modular Kitchen",
    vendorSubcategory: "MODULAR_KITCHEN",
  },
  {
    subcategoryText: "Interiors",
    vendorSubcategory: "INTERIORS",
  },
  {
    subcategoryText: "Dermatology Clinics",
    vendorSubcategory: "DERMATOLOGY_CLINICS",
  },
  {
    subcategoryText: "Ayurvedic Clinics",
    vendorSubcategory: "AYURVEDIC_CLINICS",
  },
  {
    subcategoryText: "Honeymoon Packages",
    vendorSubcategory: "HONEYMOON_PACKAGES",
  },
  {
    subcategoryText: "Travel Agents",
    vendorSubcategory: "TRAVEL_AGENTS",
  },
  {
    subcategoryText: "Boutiques",
    vendorSubcategory: "BOUTIQUES",
  },
  {
    subcategoryText: "Groom Wear",
    vendorSubcategory: "GROOM_WEAR",
  },
  {
    subcategoryText: "Bridal Wear",
    vendorSubcategory: "BRIDAL_WEAR",
  },
  {
    subcategoryText: "Beauty & Wellness",
    vendorSubcategory: "BEAUTY_AND_WELLNESS",
  },
  {
    subcategoryText: "Astrology",
    vendorSubcategory: "ASTROLOGY",
  },
  {
    subcategoryText: "Fitness Center",
    vendorSubcategory: "FITNESS_CENTER",
  }
]
