import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  ListItemText,
  Stack,
  TextField,
  Typography,
  useTheme,
  Alert,
  IconButton,
  Snackbar,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { usePreparedRequest } from "../../../utility/mediaHandler";
import { styled } from "styled-components";
import { DataContext, TypeContext } from "../../../App";
import axios from "axios";
import { API_HOST } from "../../Api/url";
import DeleteIcon from "@mui/icons-material/Delete";

const Wrapper = styled.div`
  margin: 20px 0px 20px 0px;
`;

function VideoItem({
  videoName,
  videoId,
  isActive,
  setActive,
  fetchData,
  vendorData,
  isVendor,
}) {
  const theme = useTheme();

  const handleDeleteVideo = async (videoId) => {
    if (isVendor) {
      try {
        await axios.delete(
          `${API_HOST}/api/v1/admin/vendors/${vendorData.vendorSlug}/portfolio/videos/${videoId}`
        );
        fetchData();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await axios.delete(
          `${API_HOST}/api/v1/admin/venues/${vendorData.venueSlug}/portfolio/videos/${videoId}`
        );
        fetchData();
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Box
      className={isActive && "active"}
      onClick={() => {
        setActive(videoId);
      }}
      sx={{
        color: theme.palette.text.primary,
        border: "1px dotted darkgrey",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        "&.active": {
          color: "text.primary",
          bgcolor: "action.selected",
          fontWeight: "fontWeightBold",
        },
        pl: 2,
      }}
    >
      <ListItemText disableTypography primary={videoName} />
      <IconButton
        aria-label="delete"
        color="error"
        onClick={(event) => {
          event.stopPropagation();
          handleDeleteVideo(videoId);
        }}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
}

function VideoView({ videoName, videoId, videoURL }) {
  try {
    const videoURLObject = new URL(videoURL);
    const videoSearchParams = videoURLObject.searchParams;
    const videoURLId = videoSearchParams.get("v");
    if (videoURLId === null) {
      return null;
    }

    return (
      <Stack
        spacing={1}
        sx={{
          flexGrow: 1,
        }}
      >
        <Typography variant={"h6"}>{videoName}</Typography>
        <Divider sx={{ py: 0 }} />
        <Box
          component={"iframe"}
          src={`https://youtube.com/embed/${videoURLId}`}
          title={videoName}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          sx={{
            minHeight: "50vh",
          }}
        />
      </Stack>
    );
  } catch (e) {
    return null;
  }
}

function VideoForm({
  isOpen,
  onClose,
  onVideoSuccess,
  isVendor,
  data,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarSeverity,
}) {
  const [videoName, setVideoName] = useState("");
  const [videoURL, setVideoURL] = useState("");

  const addVideo = usePreparedRequest({
    requestEndpoint: `/admin/${isVendor ? "vendors" : "venues"}/${
      isVendor ? data.vendorSlug : data.venueSlug
    }/portfolio/videos`,
    requestMethod: "POST",
    bodyParams: {
      videoURL: videoURL,
      videoName: videoName,
    },
  });

  const addVideoToPortfolio = async () => {
    const { isSuccess, isError, requestError, responseCode, responseData } =
      await addVideo();
    if (isSuccess) {
      const { responseStatus } = responseData;
      if (responseStatus === "SUCCESS") {
        const { videoId } = responseData;
        onVideoSuccess({
          videoId: videoId,
          videoURL: videoURL,
          videoName: videoName,
        });
      }
    }
    setSnackbarMessage("Videos updated successfully");
    setSnackbarSeverity("success");
    setSnackbarOpen(true);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Upload New Video</DialogTitle>
      <Divider sx={{ py: 0 }} />
      <DialogContent>
        <Grid container sx={{ alignItems: "center" }} spacing={1}>
          <Grid item xs={12} md={3}>
            <Typography variant={"h6"}>Video Name</Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              fullWidth
              placeholder={"New Video"}
              onChange={(e) => {
                setVideoName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ py: 1 }} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant={"h6"}>Video Link</Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              fullWidth
              placeholder={"youtube.com/watch?v=your-youtube-video-id-here"}
              onChange={(e) => {
                setVideoURL(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "space-between",
          px: 4,
        }}
      >
        <Button
          variant={"contained"}
          onClick={() => {
            onClose();
            setVideoName("");
            setVideoURL("");
          }}
          color={"error"}
        >
          Close Form
        </Button>
        <Button
          variant={"contained"}
          sx={{
            my: 2,
          }}
          onClick={addVideoToPortfolio}
          disabled={videoName === "" || videoURL === ""}
        >
          Add Video
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const Videos = () => {
  const [showForm, setShowForm] = useState(false);
  const [activeVideoId, setActiveVideoId] = useState(null);
  const [videoList, setVideoList] = useState([]);
  const { isVendor } = useContext(TypeContext);
  const { vendorData } = useContext(DataContext);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const selectedVideo =
    (videoList.length > 0 &&
      videoList.filter((videoObj) => {
        return videoObj.videoId === activeVideoId;
      })[0]) ||
    null;

  const fetchData = async () => {
    let response;
    if (isVendor) {
      try {
        response = await axios.get(
          `${API_HOST}/api/v1/vendors/${vendorData.vendorSlug}/portfolio/videos`
        );
        setVideoList(response.data.portfolioVideos);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        response = await axios.get(
          `${API_HOST}/api/v1/venues/${vendorData.venueSlug}/portfolio/videos`
        );
        setVideoList(response.data.portfolioVideos);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Wrapper>
      <Stack spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant={"h5"} sx={{ fontFamily: "Poppins" }}>
              Portfolio - Videos
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <Button
              variant={"contained"}
              onClick={() => {
                setShowForm(true);
              }}
              sx={{
                boxShadow: 0,
                fontFamily: "Poppins",
                textTransform: "capitalize",
              }}
            >
              Add a Video
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontFamily: "Poppins" }}>
              Videos help immerse the user in the exact environment provided by
              you. You can add videos to your profile by adding them as youtube
              links
            </Typography>
          </Grid>
          <VideoForm
            isOpen={showForm}
            onClose={() => {
              setShowForm(false);
            }}
            onVideoSuccess={(videoData) => {
              setVideoList((prevList) => {
                return [videoData, ...prevList];
              });
              setActiveVideoId(videoData.videoId);
            }}
            isVendor={isVendor}
            data={vendorData}
            fetchData={fetchData}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        </Grid>
        <Divider />
        <Grid container spacing={2}>
          {videoList.length > 0 ? (
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                {videoList.map((videoData) => {
                  console.log("video data", videoData);
                  return (
                    <VideoItem
                      key={videoData.videoId}
                      videoName={videoData.videoName}
                      videoId={videoData.videoId}
                      isActive={activeVideoId === videoData.videoId}
                      fetchData={fetchData}
                      vendorData={vendorData}
                      isVendor={isVendor}
                      setActive={(videoId) => {
                        setActiveVideoId(videoId);
                      }}
                    />
                  );
                })}
              </Stack>
            </Grid>
          ) : null}
          <Grid
            item
            xs={12}
            md={videoList.length > 0 ? 8 : 12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {selectedVideo === null ? (
              <Box>
                <Typography variant={"h6"}>
                  {videoList.length > 0 ? (
                    <i>Select a video to get started</i>
                  ) : (
                    <i>Add a video to get started</i>
                  )}
                </Typography>
              </Box>
            ) : (
              <VideoView {...selectedVideo} />
            )}
          </Grid>
        </Grid>
      </Stack>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

export default Videos;
