import { selectMetadata, zipMetadata } from '../../../utility/schemaMetadata';
import { useValueSelector } from './questionnaire/hook';
import { Grid, TextField } from '@mui/material';

const socialStringMap = {
  YT: 'YouTube Channel Link',
  WS: 'Website Link',
  IG: 'Instagram Profile Link',
  FB: 'Facebook Profile Link',
};

const socialStringPlaceholders = {
  YT: 'YouTube Channel Link',
  WS: 'Website Link',
  IG: 'Instagram Profile Link',
  FB: 'Facebook Profile Link',
};

export default function SocialLink(props) {
  const { linkType } = props;
  const [socialLinkValue, setSocialLinkValue] = useValueSelector({
    defaultValue: () => {
      return '';
    },
    venueValue: (venueData) => {
      const selectedValue = selectMetadata(
        venueData.venueMetadata,
        `VENUE_SOCIAL_${linkType}_LINK`,
        ''
      );
      return selectedValue;
    },
    vendorValue: (vendorData) => {
      const selectedValue = selectMetadata(
        vendorData.vendorMetadata,
        `VENDOR_SOCIAL_${linkType}_LINK`,
        ''
      );
      return selectedValue;
    },
    updateVenueValue: (prevValue, partialValue) => {
      return {
        ...prevValue,
        venueMetadata: zipMetadata(prevValue.venueMetadata, [
          {
            propertyName: `VENUE_SOCIAL_${linkType}_LINK`,
            propertyType: 'STRING',
            propertyValue: partialValue,
          },
        ]),
      };
    },
    updateVendorValue: (prevValue, partialValue) => {
      return {
        ...prevValue,
        vendorMetadata: zipMetadata(prevValue.vendorMetadata, [
          {
            propertyName: `VENDOR_SOCIAL_${linkType}_LINK`,
            propertyType: 'STRING',
            propertyValue: partialValue,
          },
        ]),
      };
    },
  });

  return (
    <>
      <Grid item xs={12} md={4}>
        <b>{socialStringMap[linkType]}</b>
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          fullWidth
          value={socialLinkValue}
          onChange={(e) => {
            setSocialLinkValue(e.target.value);
          }}
          placeholder={socialStringPlaceholders[linkType]}
        />
      </Grid>
    </>
  );
}
